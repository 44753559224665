/* LIBRARIES IMPORT */
import React from 'react';
import { withRouter } from 'react-router-dom';

/* SERVICES IMPORT */
import { endpoint } from '../../../Constants';
import { fetchApi } from '../../../Services/Network';

/* COMPONENTS IMPORT */

import StartPage from '../../Ui/Tunnel/General/StartPage';
import DeliveryAddressSelection from '../../Ui/Tunnel/General/DeliveryAddressSelection';
import { PackageSelectionPage } from '../../Ui/Tunnel/Cafe/PackageSelectionPage';
import { PackageOptionsSelectionPage } from '../../Ui/Tunnel/Cafe/PackageOptionsSelectionPage';
import { OrderReviewPage } from '../../Ui/Tunnel/Cafe/OrderReviewPage';
import addNotification from '../../Ui/Notifications';
import axios from "axios";

class CafeTunnel extends React.Component {
    constructor() {
        super();

        this.state = {
            delivery_location: null,
            packages: [],
            options: [],
            estimateLink: null,
            loading: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handlePlaceOrder = this.handlePlaceOrder.bind(this);
        this.handleSelectOption = this.handleSelectOption.bind(this);
        this.handleCreateEstimate = this.handleCreateEstimate.bind(this);
    }

    handleCreateEstimate() {
        const selectedPackage = this.props.service.packages.find(
            ({ id }) => id === this.state.packages[0]
        );

        const packages = [];
        for (const element of this.state.packages) {
            packages.push({
                type: 'package',
                id: element
            });
        }

        for (const element of this.state.options) {
            packages.push({
                type: 'option',
                id: element,
                quantity: selectedPackage.quantity
            });
        }

        const headers = {
            'Content-Type': 'application/json'
        };

        this.setState({ loading: true });
        axios.post(`${endpoint}/api/delivery/estimate_api/${this.props.organization_id}/`, {
                serviceId: this.props.service.id,
                packages,
                deliveryId: this.state.delivery_location,
            }, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
            .then(res => {
                this.setState({ loading: false, estimateLink: res.data });
            })
            .catch(err => console.error(err));
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSelectOption(o) {
        this.setState({ options: [o] }, () => {
            if (this.state.options.length > 0) {
                this.props.setNextDisabled(false);
            } else {
                this.props.setNextDisabled(true);
            }
        });
    }

    handlePlaceOrder() {
        const packageToSave = this.state.packages[0];
        let dataPackages = [];

        if (packageToSave !== 1) {
            // Real package / Not already have
            dataPackages = [{ package: packageToSave, quantity: 1 }];
        }

        const selectedPackage = this.props.service.packages.find(
            ({ id }) => id === packageToSave
        );

       axios.post(`${endpoint}/api/delivery/place_order_cafe/${this.props.organization_id}/${localStorage.getItem('token')}`, {
                delivery_location: this.state.delivery_location,
                service: this.props.service.id,
                packages: dataPackages,
                options: [
                    {
                        option: this.state.options[0],
                        quantity: selectedPackage.quantity
                    }
                ]
            }, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}}).then(res => {
            if (this.props.adminMode) {
                addNotification('Votre commande a bien été créé !');
                return this.props.history.push(
                    `/orders/${res.data.organization}/${res.data.id}`
                );
            } else {
                addNotification('Votre commande a bien été créé !');
                return this.props.history.push('/');
            }
        });
    }

    render() {
        console.log(this.state)
        const cafePackage = this.props.service.packages.find(
            ({ id }) => id === this.state.packages[0]
        );

        switch (this.props.currentPage) {
            case 0:
                return (
                    <StartPage
                        title={this.props.service.homepageTitle}
                        description={this.props.service.homepageDescription}
                        handleNextPage={this.props.handleNextPage}
                    />
                );
            case 1:
                return (
                    <DeliveryAddressSelection
                        handleNextPage={this.props.handleNextPage}
                        delivery_location={this.state.delivery_location}
                        setDeliveryLocation={id => {
                            this.setState({ delivery_location: id });
                            this.props.setNextDisabled(false);
                        }}
                        organization_id={this.props.organization_id}
                    />
                );
            case 2:
                return (
                    <PackageSelectionPage
                        packs={[
                            ...this.props.service.packages,
                            { id: 1, quantity: 6 }
                        ]}
                        selected={this.state.packages[0]}
                        selectPackage={id => {
                            this.setState({
                                packages: [id],
                                estimateLink: null
                            });
                            this.props.setNextDisabled(false);
                        }}
                        handleNextPage={this.props.handleNextPage}
                    />
                );
            case 3:
                return (
                    <PackageOptionsSelectionPage
                        packs={this.props.service.packages[0].package_options}
                        buttonDisabled={this.state.options.length === 0}
                        handleSelectOption={this.handleSelectOption}
                        options={this.state.options}
                        handleNextPage={this.props.handleNextPage}
                    />
                );
            case 4:
                return (
                    <OrderReviewPage
                        organization_id={this.props.organization_id}
                        free_packages={this.props.service.free_packages}
                        delivery_location={this.state.delivery_location}
                        packages={[
                            cafePackage || {
                                id: 1,
                                description: 'Nous avons déjà une machine',
                                image: 'https://via.placeholder.com/196x128'
                            }
                        ]}
                        options={this.props.service.packages[0].package_options}
                        chosenOptions={this.state.options}
                        handleSubmit={this.handlePlaceOrder}
                        handleCreateEstimate={this.handleCreateEstimate}
                        loading={this.state.loading}
                        estimateLink={this.state.estimateLink}
                    />
                );
            default:
                return null;
        }
    }
}

export default withRouter(CafeTunnel);
