import React from 'react';

import '../../Assets/css/Ui/QuantitySelector.css';

const QuantitySelector = props => {
    return (
        <span className="quantity_selector">
            <span
                className={
                    props.quantity === 0 ? 'actionminus hidden' : 'actionminus'
                }
                onClick={() => props.handleQuantityChange(props.quantity - 1)}
            >
                -
            </span>
            <span className="quantity">{props.quantity}</span>
            <span
                className="actionplus"
                onClick={() => props.handleQuantityChange(props.quantity + 1)}
            >
                +
            </span>
        </span>
    );
};

export default QuantitySelector;
