import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import TunnelPosition from '../../../Ui/TunnelPosition';
import Welcome from './Welcome';

import AuthWrapper from '../../../../Services/AuthWrapper';

/* REDUX IMPORT */

import { connect } from 'react-redux';
import { feedUserMeta } from '../../../../Redux/Actions';
import GeneralInformations from './GeneralInformations';

/* CSS IMPORT */

import '../../../../Assets/css/Pages/Tunnel/AccountTunnel.css';
import DeliveryInformations from './DeliveryInformations';
import BillingInformations from './BillingInformations';
import End from './End';

import addNotification from '../../../Ui/Notifications';
import { show } from '../../../Ui/Modals';

class AccountTunnel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: 0,
            adminMode: false,
            newUser: null
        };

        this.handleNextPage = this.handleNextPage.bind(this);
    }

    handleNextPage() {
        if (this.state.currentPage !== 4) {
            this.setState({ currentPage: this.state.currentPage + 1 });
        }
    }

    componentDidMount() {
        if (this.props.user.role === 'Admin') {
            this.setState({ adminMode: true, currentPage: 1 });
        }
    }

    render() {
        return (
            <div className="TunnelLayout">
                <button
                    className="closeBtn"
                    onClick={_ =>
                        show(
                            'Êtes-vous sûrs de vouloir fermer ? Toute information sera perdue.',
                            'info',
                            _ => this.props.history.push('/')
                        )
                    }
                >
                    <i className="fas fa-times" />
                </button>
                <TunnelPosition
                    actualState={this.state.currentPage}
                    nbStates={5}
                />
                <Link
                    to="/help"
                    className="infoBtn"
                    style={{ display: 'none' }}
                >
                    <span>?</span>
                </Link>
                <div className="container content">
                    {(() => {
                        if (this.state.currentPage === 0) {
                            return (
                                <Welcome
                                    handleNextPage={this.handleNextPage}
                                    user={this.props.user}
                                />
                            );
                        }
                        if (this.state.currentPage === 1) {
                            return (
                                <GeneralInformations
                                    handleNextPage={this.handleNextPage}
                                    feedUserMeta={this.props.feedUserMeta}
                                    user={
                                        this.state.adminMode
                                            ? this.state.newUser
                                            : this.props.user
                                    }
                                    setNewUser={newUser =>
                                        this.setState({ newUser: newUser })
                                    }
                                    adminMode={this.state.adminMode}
                                />
                            );
                        }
                        if (this.state.currentPage === 2) {
                            return (
                                <DeliveryInformations
                                    handleNextPage={this.handleNextPage}
                                    feedUserMeta={this.props.feedUserMeta}
                                    user={
                                        this.state.adminMode
                                            ? this.state.newUser
                                            : this.props.user
                                    }
                                    setNewUser={newUser =>
                                        this.setState({ newUser: newUser })
                                    }
                                    adminMode={this.state.adminMode}
                                />
                            );
                        }
                        if (this.state.currentPage === 3) {
                            return (
                                <BillingInformations
                                    handleNextPage={() => {
                                        if (this.state.adminMode) {
                                            this.handleNextPage();
                                        } else {
                                            addNotification(
                                                'Vos informations ont bien été modifées ! 👍'
                                            );
                                            this.props.history.replace('/');
                                        }
                                    }}
                                    feedUserMeta={this.props.feedUserMeta}
                                    user={
                                        this.state.adminMode
                                            ? this.state.newUser
                                            : this.props.user
                                    }
                                    setNewUser={newUser =>
                                        this.setState({ newUser: newUser })
                                    }
                                    adminMode={this.state.adminMode}
                                />
                            );
                        }
                        if (this.state.currentPage === 4) {
                            return (
                                <End
                                    adminMode={this.state.adminMode}
                                    user={
                                        this.state.adminMode
                                            ? this.state.newUser
                                            : this.props.user
                                    }
                                />
                            );
                        }
                    })()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user
});

const mapDispatchToProps = dispatch => ({
    feedUserMeta: user => dispatch(feedUserMeta(user))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthWrapper(AccountTunnel, AccountTunnel));
