import React, { Component } from 'react';

import { fetchApi } from '../../../../Services/Network';

import Input from '../../../Ui/Input';
import Button from '../../../Ui/Button';
import axios from "axios";
import { endpoint } from '../../../../Constants';
export default class GeneralInformations extends Component {
    constructor() {
        super();

        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            organization: '',
            password1: '',
            password2: '',
            edited: false,
            loading: false
        };

        this.errors = {};

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
    }

    componentDidMount() {
        if (!this.props.adminMode) {
            const {
                first_name,
                last_name,
                email,
                phone,
                organization
            } = this.props.user;
            this.setState({
                first_name: first_name || '',
                last_name: last_name || '',
                email: email || '',
                phone: phone || '',
                organization: organization.name || ''
            });
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        /* Handler */
        const {
            first_name,
            last_name,
            email,
            phone,
            organization,
            password1,
            password2
        } = this.state;

        this.setState({ loading: true });

        /* User Case */
        if (!this.props.adminMode) {
            /**
             * We need to fetch the id given in props if we are updating data for
             * another user. If not, the id from the meta will be used. It means
             * that we are updating `ourselves`.

             <div className="col-md-6">
                            <Input
                                type="password"
                                placeholder="Mot de passe"
                                label="Mot de passe"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.password1}
                                name="password1"
                                errors={this.errors}
                            />
                        </div>
                        <div className="col-md-6">
                            <Input
                                type="password"
                                placeholder="Confirmation Mot de passe"
                                label="Confirmation Mot de passe"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.password2}
                                name="password2"
                                errors={this.errors}
                            />
                        </div>
                    </div>
             */
             let data = {
                first_name: first_name,
                last_name: last_name,
                email: email,
                phone: phone,
                organization: organization
             }
             axios.put(`${endpoint}/api/v1/users/edit/${localStorage.getItem('token')}`, data, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
                .then(() => {
                    this.setState({ loading: false, edited: false });
                    const newUser = {
                        ...this.props.user,
                        first_name,
                        last_name,
                        email,
                        phone
                    };

                    newUser.organization.name = organization;

                    /*addNotification(
                    'Vos informations ont bien été mises à jour ! 😎'
                );*/

                    this.props.feedUserMeta(newUser);

                    this.props.handleNextPage();
                })
                .catch(err => {
                    if (err.code === 400) {
                        Object.keys(err.body).forEach(k => {
                            this.errors[k] = err.body[k];
                        });
                    }

                    this.setState({ loading: false });
                });
                return
        }
        /* Admin Case */
        fetch(`${endpoint}/api/v1/users/auth/register_admin/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
                first_name,
                last_name,
                email,
                organization,
                phone
            })
        })
            .then(res => {
                this.setState({ loading: false, edited: false });
                axios.post(`${endpoint}/api/v1/users/clients/`, {email}, {
                    headers: {
                        'Authorization': `Token ${localStorage.getItem('apikey')}`
                    }
                })
                        .then(user =>{
                            const newUser = user.data
                            this.setState({ newUser })
                            newUser.organization.name = organization;
                            this.props.setNewUser(newUser);
                            this.props.handleNextPage();
                })

            })
            .catch(err => {
                if (err.code === 400) {
                    Object.keys(err.body).forEach(k => {
                        this.errors[k] = err.body[k];
                    });
                }

                this.setState({ loading: false });
            });
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value, edited: true });
    }

    isFormValid() {
        if(this.props.adminMode){
            const toCheck = {
                first_name : this.state.first_name,
                last_name : this.state.last_name,
                email : this.state.email,
                organization : this.state.organization,
                phone : this.state.phone,
            };
            return this.state.edited || Object.keys(toCheck).every(k => this.state[k].trim().length > 0);
        }else{
            const toCheck = {
                first_name : this.state.first_name,
                last_name : this.state.last_name,
                email : this.state.email,
                phone : this.state.phone,
                organization : this.state.organization
            };
            return this.state.edited || Object.keys(toCheck).every(k => this.state[k].trim().length > 0);
        }
    }

    render() {
        const buttonDisabled = !this.isFormValid();

        return (
            <div className="accountTunnel">
                <p className="catchSentence">C'est parti !</p>
                <h2>Informations Générales</h2>
                <p>
                    Pour profiter de Les Plaisirs Fruites sans accrocs, merci de compléter vos
                    informations. Nous vous les demanderons qu'une seule fois !
                </p>
                <form onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <Input
                                type="text"
                                placeholder="Prénom"
                                label="Prénom"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.first_name}
                                name="first_name"
                                errors={this.errors}
                            />
                        </div>
                        <div className="col-md-6">
                            <Input
                                type="text"
                                placeholder="NOM"
                                label="NOM"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.last_name}
                                name="last_name"
                                errors={this.errors}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Input
                                type="email"
                                placeholder="mail@societe.com"
                                label="Mail pro"
                                className="form-control disabled"
                                value={this.state.email}
                                name="email"
                                onChange={this.handleChange}
                                errors={this.errors}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Input
                                type="text"
                                label="Ligne directe"
                                placeholder="0X XX XX XX XX"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.phone}
                                name="phone"
                                errors={this.errors}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Input
                                type="text"
                                label="Societe"
                                placeholder="Société"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.organization}
                                name="organization"
                                errors={this.errors}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Button
                                disabled={buttonDisabled}
                                loading={this.state.loading}
                            >
                                Suivant
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
