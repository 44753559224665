import React from 'react';
import FormLayout from '../Layout/FormLayout';
import Button from '../Ui/Button';
import { fetchApi } from '../../Services/Network';
import { Link } from 'react-router-dom';
import { Base64 } from 'js-base64';
import Input from '../Ui/Input';
import { getErrorFromCode } from '../../Constants';
import { feedUserMeta } from '../../Redux/Actions';
import { connect } from 'react-redux';
import axios from "axios";
import { endpoint } from '../../Constants';
class Login extends React.Component {
    constructor() {
        super();

        this.state = {
            email: '',
            password: '',
            loading: false,
            error: ''
        };

        this.errors = {};

        this.handleChange = this.handleChange.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();

        if (this.state.loading) {
            return;
        }

        this.setState({ loading: true });
        const { email, password } = this.state;
        const user = {
          email: email,
          password: password
        };
        fetch(`${endpoint}/api/v1/users/auth/login/`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(user)
            })
              .then(res => res.json())
              .then(data => {
                  if(!data.key){
                    this.setState({ error: 'INVALID_PASSWORD' });
                    this.setState({ loading: false });
                }else{
                  localStorage.clear();
                  localStorage.setItem('token', data.key);
                  axios.post(`${endpoint}/api-auth/`, {username: user.email, password: user.password})
                  .then(res => {
                    localStorage.setItem('apikey', res.data.token);
                    axios.post(`${endpoint}/api/v1/users/user_data/`, {token: localStorage.getItem('token')},
                        {headers:{
                            'Authorization': `Token ${localStorage.getItem('apikey')}`
                            }
                        }
                    )
                    .then(res => {
                        this.setState({ loading: false });
                        this.props.feedUserMeta(res.data);
                        this.props.history.push('/');
                    })
                  })
                }
        })
        .catch(err => {
                if (err.code === 400) {
                    Object.keys(err.body).forEach(k => {
                        this.errors[k] = err.body[k];
                    });
                } else if (err.code === 404) {
                    this.setState({ error: 'NO_SUCH_EMAIL' });
                } else if (err.code === 403) {
                    this.setState({ error: 'INVALID_PASSWORD' });
                } else if (err.code === 401) {
                    this.setState({ error: 'USER_NOT_ACTIVATED' });
                } else {
                    this.setState({ error: 'Veuillez valider votre email.' });
                    axios.post(`${endpoint}/api/v1/users/request_verif_email/`, {email: email })
                }
                this.setState({ loading: false });
            });


    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    isFormValid() {
        // Checks that each field is not blank
        return ['email', 'password'].every(
            k => this.state[k].trim().length > 0
        );

        // You're free to add any test
    }

    render() {
        let error = null;
        if (this.state.error) {
            error = (
                <div className="errorToast">
                    {getErrorFromCode(this.state.error)
                        .split('\n')
                        .map((b, i) => (
                            <span key={i} style={{ display: 'block' }}>
                                {b}
                            </span>
                        ))}
                </div>
            );
        }

        return (
            <FormLayout>
                <div className="Form login">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Connectez-vous</h2>
                                <h3>Attention, la simplicité est addictive…</h3>
                                {error}
                            </div>
                        </div>
                    </div>
                    <form onSubmit={this.handleSubmit}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <Input
                                        name="email"
                                        type="email"
                                        label="Mail"
                                        value={this.state.email}
                                        onChange={this.handleChange}
                                        placeholder="email@mail.com"
                                        errors={this.errors}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <Input
                                        label="Mot de passe"
                                        name="password"
                                        type="password"
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                        placeholder="password"
                                        errors={this.errors}
                                        labelStyle={{ display: 'block' }}
                                    >
                                        <Link
                                            tabIndex={9999}
                                            to={`/forgot-password/`}
                                            style={{
                                                float: 'right',
                                                textTransform: 'initial'
                                            }}
                                        >
                                            mot de passe oublié ?
                                        </Link>
                                    </Input>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Button
                                        disabled={!this.isFormValid()}
                                        loading={this.state.loading}
                                    >
                                        Se connecter
                                    </Button>
                                </div>
                                <div
                                    className="col-md-6"
                                    style={{ textAlign: 'right' }}
                                >
                                    <Link
                                        to="/register"
                                        style={{
                                            lineHeight: '48px'
                                        }}
                                    >
                                        Je n'ai pas encore de compte
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </FormLayout>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    feedUserMeta: user => dispatch(feedUserMeta(user))
});

export default connect(
    null,
    mapDispatchToProps
)(Login);
