import React from 'react';

import { FullFruit } from '../../../Ui/Fruits';
import Button from '../../../Ui/Button';
import SpinnerLoading from '../../../Ui/SpinnerLoading';

import { fetchApi } from '../../../../Services/Network';

import '../../../../Assets/css/Pages/Admin/services.css';
import axios from "axios"
import { endpoint } from '../../../../Constants';
export default class Services extends React.Component {
    constructor() {
        super();

        this.state = {
            services: [],
            loading: true
        };
    }

    componentDidMount() {
        axios.get(`${endpoint}/api/service/`).then(services =>
            this.setState({ services: services.data, loading: false })
        );
    }

    render() {
        
        if (this.state.loading) {
            return <SpinnerLoading />;
        }

        if (this.state.services.length === 0) {
            return (
                <div className="admin-services">
                    <div className="row">
                        <div className="col-md-9">
                            <h1 className="Header">
                                Nos Services &amp; Produits
                            </h1>
                        </div>
                        <div
                            className="col-md-3"
                            style={{ textAlign: 'right' }}
                        >
                            <Button
                                disabled={false}
                                className="newServices"
                                onClick={() =>
                                    this.props.history.push('/services/new')
                                }
                            >
                                Nouveau Services
                            </Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <span className="noSubs">
                                Vous n’avez aucun Service…{' '}
                                <span role="img" aria-label="sad">
                                    😭
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="admin-services">
                <div className="row" style={{ paddingBottom: '12px' }}>
                    <div className="col-md-9 col-sm-8 col-xs-8">
                        <h1 className="Header">Nos Services &amp; Produits</h1>
                    </div>
                    <div
                        className="col-md-3 col-sm-4 col-xs-4"
                        style={{ textAlign: 'right' }}
                    >
                        <Button
                            disabled={false}
                            className="newServices"
                            onClick={() =>
                                this.props.history.push('/services/new')
                            }
                        >
                            Nouveau Services
                        </Button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="services-showcase">
                            {this.state.services.map(s => (
                                <FullFruit
                                    key={s.id}
                                    title={s.catch_phrase}
                                    product={s.name}
                                    src={s.image}
                                    onClick={() =>
                                        this.props.history.push(
                                            '/services/' + s.id
                                        )
                                    }
                                />
                            ))}

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
