import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import '../../Assets/css/Ui/Fruits.css';

const getFormatedDate = date => {
    const now = new Date();

    date = new Date(date);
    const endOfDay = new Date(new Date(now).setHours(23, 59, 59, 999));
    const tmr = new Date(endOfDay).setDate(endOfDay.getDate() + 1);

    if (date < endOfDay) {
        return "aujourd'hui";
    } else if (date < tmr) {
        return 'demain';
    }

    return moment(date).format('DD/MM/YYYY');
};

export const FullFruit = props => (
    <div
        className={props.soon ? 'FullFruit soon' : 'FullFruit'}
        onClick={props.onClick}
        style={{
            backgroundImage: `${
                props.srcPreview
                    ? `url('${props.srcPreview}')`
                    : `url('${props.src}')`
            }`
        }}
    >
        {' '}
        {(() => {
            if (props.soon) {
                return <span className="soon-text">Bientot disponible !</span>;
            }
        })()}
        <div className="overlay">
            <div className="text">
                <p className="title">{props.title}</p>
                <h3 className="product m-0">{props.product}</h3>
            </div>
        </div>
    </div>
);

export const SubsFruit = props => (
    <div className="SubsFruit">
        <Link to={`/orders/${props.oid}/${props.id}`}>
            <div className="bottom">
                <i className="fas fa-pen" />
            </div>
        </Link>
        <div className="main">
            <img
                width="256"
                height="160"
                src={`${
                    props.srcPreview
                        ? `${props.srcPreview}`
                        : `${props.service.image}`
                }`}
                alt="Product"
            />
            <div className="text">
                <h4 className="mt-24">{props.service.name}</h4>
                <p>{props.service.description}</p>
                <span>Prochaine Livraison</span>
                {props.status === 'ACTIVE' ? (
                    <h4
                        className="blue"
                        style={{ textTransform: 'capitalize' }}
                    >
                        {getFormatedDate(props.next_delivery)}
                    </h4>
                ) : (
                    <h4>
                        {props.status === 'WAITING'
                            ? 'En attente de validation'
                            : 'Commande suspendue'}
                    </h4>
                )}
            </div>
        </div>
    </div>
);

export const LongFruit = props => (
    <div className="LongFruit">
        <div
            className="banner"
            style={{
                backgroundImage: `${
                    props.srcPreview
                        ? `url('${props.srcPreview}')`
                        : `url('/assets/img/${props.src}')`
                }`
            }}
        />
        <div className="main">
            <h4 className="mt-24">{props.product}</h4>
            <p>{props.text}</p>
            <h4 className="blue">{props.nextDelivery}</h4>
        </div>
    </div>
);

export const FruitsSubsContainer = props => (
    <div className="FruitsSubsContainer">{props.children}</div>
);
