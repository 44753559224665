import React, { Component } from 'react';

/* COMPONENTS IMPORT */

import Button from '../../Button';
import Switch from '../../Switch';
import Calendar from './Components/Calendar';
import { show } from '../../Modals';

/* CSS IMPORT */

import '../../../../Assets/css/Ui/Tunnel/Product/FrequenceCalendar.css';

export default class FrequenceCalendar extends Component {
    constructor() {
        super();

        this.state = {
            biological: false,
            panier_test: true
        };

        this.handleChange = this.handleChange.bind(this);
    }

    getPrice = () => {
        let price = 0;
        for (let index = 0; index < this.props.packages.length; index++) {
            for (
                let index2 = 0;
                index2 < this.props.packages[index].length;
                index2++
            ) {
                price +=
                    this.props.packages[index][index2].package.price *
                    this.props.packages[index][index2].quantity;
            }
        }
        return price;
    };

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value, edited: true });
    }

    render() {
        return (
            <div className="FrequenceCalendar">
                <div>
                    <p className="catchSentence">C'est parti !</p>
                    <h2>
                        Qu'est ce qui vous ferait plaisir ?
                        {this.props.servicePackages.find(e => { return e.bio === true }) && (
                            <React.Fragment>
                                <span className="bio-title">Agriculture Biologique</span>
                                <Switch
                                    handleChange={e => {
                                        this.handleChange(e);
                                        this.props.handleReset();
                                    }}
                                    name="biological"
                                    value={this.state.biological}
                                    green={true}
                                />
                            </React.Fragment>
                        )}
                    </h2>
                </div>

                <div className="calendar-container">
                    <Calendar
                        servicePackages={this.props.servicePackages} // Packages du service
                        activePackages={this.props.packages} // Packages sélectionné par l'utilisateur
                        handleQuantityChange={this.props.handleQuantityChange} // Fonction pour changer la quantité
                        handleDelete={this.props.handleDelete} // Fonction pour supprimer un package
                        addPackages={this.props.addPackages} // Fonction pour ajouter un package
                        biological={this.state.biological} // Boolean agriculure biologique
                    />
                </div>

                <div className="row" style={{ marginTop: '40px' }}>
                    <div className="col-md-2">
                        <Button
                            onClick={this.props.handleNextPage}
                            disabled={this.props.packages.every(
                                element => element.length === 0
                            )}
                        >
                            Suivant !
                        </Button>
                    </div>
                    <div className="col-md-10">
                        <p className="price">
                            Total — {this.getPrice()}€ HT / semaine <br />
                            <span className="price-month">
                                Environ {this.getPrice() * 4} € HT / mois{' '}
                                <button
                                    onClick={() =>
                                        show(
                                            'Le montant de votre abonnement est calculé sur 4 semaines mais peut légèrement évoluer. Sur Les Plaisirs Fruites, la facturation se fait du 1er de chaque mois au dernier jour du mois. En fonction des mois il peut y avoir 4 ou 5 Lundis, Mardi etc. Le montant de votre facture peut alors un peu évoluer en fonction de ces paramètres.',
                                            'info',
                                            () => {
                                                console.log('close');
                                            },
                                            () => {
                                                console.log('close');
                                            }
                                        )
                                    }
                                >
                                    Pourquoi ?
                                </button>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
