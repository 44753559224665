import React, { Component } from 'react';

/* CSS IMPORT */

import '../../../../../Assets/css/Ui/Tunnel/Product/Components/ProductSelection.css';

export default class ProductSelection extends Component {
    render() {
        return (
            <div
                className={
                    this.props.selected
                        ? 'ProductSelection selected'
                        : 'ProductSelection'
                }
            >
                <div className="main" onClick={this.props.onClick}>
                    <div
                        className="img-container"
                        style={{
                            backgroundImage: `url(${this.props.package.image})`
                        }}
                    />
                    <div className="text">
                        <h4 className="mt-24">{this.props.package.name}</h4>
                        <p>{this.props.package.description}</p>
                    </div>
                </div>
                <div className="bottom">
                    <span className="price">{this.props.package.price} €</span>
                    <span className="taxe">HT</span>{' '}
                    <span className="unit_name">
                        / {this.props.package.unit_name}
                    </span>
                    {(() => {
                        if (this.props.selected) {
                            return (
                                <select
                                    name="quantity"
                                    value={this.props.quantity}
                                    onChange={e => {
                                        this.props.handleQuantityChange(
                                            parseInt(e.target.value, 10)
                                        );
                                    }}
                                >
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                </select>
                            );
                        }
                    })()}
                </div>
            </div>
        );
    }
}
