import React, { Component } from 'react';

import QuantitySelector from './QuantitySelector';

import '../../Assets/css/Ui/ServiceBox.css';

export default class ServiceBox extends Component {
    render() {
        return (
            <div
                className={
                    this.props.getQuantityLocationById(
                        this.props.servicePackage.id
                    ) > -1
                        ? 'service-box active'
                        : 'service-box'
                }
            >
                <img src={this.props.servicePackage.image} alt="Super" />
                <div className="main-content">
                    <p className="name">{this.props.servicePackage.name}</p>
                    <p className="description">{this.props.servicePackage.description}</p>
                </div>
                <div className="footer-content">
                    <span className="price">
                        {this.props.servicePackage.price} €
                    </span>
                    <span className="taxe">HT</span>
                    <span className="unit_name">/ {this.props.servicePackage.unit_name || 'mois'}</span>
                    <QuantitySelector
                        handleQuantityChange={quantity => {
                            this.props.handleQuantityChange(
                                this.props.servicePackage,
                                quantity
                            );
                        }}
                        quantity={
                            this.props.getQuantityLocationById(
                                this.props.servicePackage.id
                            ) > -1
                                ? this.props.packages[
                                      this.props.getQuantityLocationById(
                                          this.props.servicePackage.id
                                      )
                                  ].quantity_package
                                : 0
                        }
                    />
                </div>
            </div>
        );
    }
}
