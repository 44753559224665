import React from 'react';

import '../../Assets/css/Layout/FormLayout.css';

export default class FormLayout extends React.Component {
    render() {
        return (
            <div className="FormLayout">
                <img
                    src="/assets/img/logo.svg"
                    className="hidden-md hidden-lg logo"
                    alt="Logo Peppr"
                />
                <div className="left">
                    <div className="loginform-image" />
                </div>
                <div className="main">{this.props.children}</div>
            </div>
        );
    }
}
