import React, { Component } from 'react';
import { connect } from 'react-redux';
/* CSS IMPORT */

import '../../../../../Assets/css/Ui/Tunnel/Product/Components/CalendarBox.css';

class CalendarBox extends Component {
    render() {
        return (
            <div className="CalendarBox">
                {this.props.noDelete || (
                    <img
                        onClick={() => {
                            this.props.handleDelete();
                        }}
                        src="/assets/img/delete.svg"
                        alt="Delete Icon"
                        className="delete-button"
                    />
                )}
                <div className="main">
                    <h4 className="mt-24">{this.props.name}</h4>
                    <p>{this.props.description}</p>
                </div>
                <div className="bottom">
                    {(() => {
                        if (this.props.day) {
                            return (
                                <span className="price">
                                    {this.props.day}
                                </span>
                            );
                        } else {
                            if (this.props.user.role === 'Admin' && !!this.props.handlePriceChange) {
                                return (
                                    <span>
                                        <input
                                            className="form-control price"
                                            type="text"
                                            value={this.props.price}
                                            onChange={
                                                this.props.handlePriceChange
                                            }
                                        />
                                        <span className="taxe">€ HT</span>
                                    </span>
                                );
                            }

                            return (
                                <span>
                                    <span className="price">
                                        {this.props.price} €
                                    </span>
                                    <span className="taxe">HT</span>
                                </span>
                            );
                        }
                    })()}
                    <select
                        name="quantity"
                        value={this.props.quantity}
                        onChange={e => {
                            this.props.handleQuantityChange(
                                parseInt(e.target.value, 10)
                            );
                        }}
                    >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                    </select>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user
});

export default connect(mapStateToProps)(CalendarBox);
