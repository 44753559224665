import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import dateFormat from 'dateformat';

import 'moment/locale/fr';
import '../../Assets/css/Ui/NextDeliveryItem.css';

moment.locale('fr');

const getFormatedDate = date => {
    const now = new Date();
    date = new Date(date);
    const endOfDay = new Date(new Date(now).setHours(23, 59, 59, 999));
    const tmr = new Date(endOfDay).setDate(endOfDay.getDate() + 1);

    if (date < endOfDay) {
        return "aujourd'hui";
    } else if (date < tmr) {
        return 'demain';
    }

    return moment(date).format('DD/MM/YYYY');
};

const NextDeliveryItem = props => (
    <div
        className={
            props.status === 'WAITING'
                ? 'NextDeliveryItem waiting'
                : 'NextDeliveryItem'
        }
    >
        <img src={props.service.image} alt="Service" />
        <div className="deliveryContent">
            <h2>{props.service.name}</h2>
            <p>{props.service.description}</p>
            {(() => {
                if (props.status === 'WAITING')
                    return (
                        <h2 className="grey0">
                            En attente de validation de notre fournisseur
                        </h2>
                    );

                if (props.status === 'DISABLED') {
                    return <h2 className="grey0">Abonnement suspendu du {dateFormat(props.disabled_date_start, 'dd/mm/yyyy')} au {dateFormat(props.disabled_date_end, 'dd/mm/yyyy')}</h2>;
                }

                return (
                    <h2
                        className="blue"
                        style={{ textTransform: 'capitalize' }}
                    >
                        {getFormatedDate(props.next_delivery)}
                        {(!props.disabled_date_start  || !props.disabled_date_end) || (
                            <span className="suspended-title">Demande de suspension du {dateFormat(props.disabled_date_start, 'dd/mm/yyyy')} au {dateFormat(props.disabled_date_end, 'dd/mm/yyyy')}</span>
                        )}
                    </h2>
                );
            })()}
            <Link
                className="blue"
                to={`/orders/${props.organisation}/${props.id}`}
            >
                Gérer mon abonnement
            </Link>
        </div>
    </div>
);

export default NextDeliveryItem;
