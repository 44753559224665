import React, { Component } from 'react';

/* COMPONENTS IMPORT */

import CalendarBox from './CalendarBox';
import ProductSelectionModal from './ProductSelectionModal';
import { show } from '../../../Modals';

/* CSS IMPORT */

import '../../../../../Assets/css/Ui/Tunnel/Product/Components/Calendar.css';

export default class Calendar extends Component {
    constructor(props) {
        super(props);

        this.mondayLock = props.mondayLock;

        this.state = {
            modalShow: false,
            selectedDay: 0
        };
    }

    render() {
        return (
            <div className="row Calendar">
                {(() => {
                    if (this.state.modalShow) {
                        return (
                            <ProductSelectionModal
                                packages={this.props.activePackages}
                                show={this.state.modalShow}
                                servicePackages={this.props.servicePackages.filter(
                                    obj => obj.bio === this.props.biological
                                )}
                                addPackages={this.props.addPackages}
                                selectedDay={this.state.selectedDay}
                                handleClose={() => this.setState({ modalShow: false })}
                            />
                        );
                    }
                })()}
                <div className="col-md-2 day">
                    <p className="dayname">Lundi</p>
                    <p className="sub-dayname">
                        Non garantie{' '}
                        <button
                            onClick={() =>
                                show(
                                    'De nombreuses commandes sont livrées le lundi, afin de proposer une qualité de service optimale ainsi que des heures de livraison convenables nous devons nous assurer de pouvoir vous livrer ce jour. Une fois votre commande en validation, notre fournisseur nous confirmera le jour de livraison retenu et vous en serez informé',
                                    'info',
                                    () => {
                                        console.log('close');
                                    },
                                    () => {
                                        console.log('close');
                                    }
                                )
                            }
                        >
                            Pourquoi ?
                        </button>
                    </p>

                    {this.props.activePackages[0].map((myPackage, index) => {
                        let price = myPackage.amount == null ? myPackage.package.price : myPackage.amount;

                        return (
                            <CalendarBox
                                key={index}
                                price={price}
                                name={myPackage.package.name}
                                description={myPackage.package.description}
                                quantity={myPackage.quantity | myPackage.quantity_package}
                                handleQuantityChange={quantity => {
                                    this.props.handleQuantityChange(0, index, quantity);
                                }}
                                handlePriceChange={
                                    this.props.handlePriceChange
                                        ? price => this.props.handlePriceChange(0, index, price.target.value)
                                        : null
                                }
                                handleDelete={() => {
                                    this.props.handleDelete(0, index);
                                }}
                            />
                        );
                    })}

                    <div
                        className={`addProduct${this.mondayLock ? ' locked' : ''}`}
                        onClick={() => {
                            if (this.mondayLock) {
                                return show(
                                    "Impossible d'ajouter un produit le lundi. Merci de prendre contact sur contact@peppr.fr",
                                    'info',
                                    () => {},
                                    () => {}
                                );
                            }

                            this.setState({
                                selectedDay: 0,
                                modalShow: true
                            });
                        }}
                    >
                        <i className="fas fa-plus blue" />
                    </div>
                </div>
                <div className="col-md-2 day">
                    <p className="dayname">Mardi</p>
                    <p className="sub-dayname" />
                    {(() => {
                        return this.props.activePackages[1].map((myPackage, index) => {
                            let price = myPackage.amount == null ? myPackage.package.price : myPackage.amount;

                            return (
                                <CalendarBox
                                    key={index}
                                    price={price}
                                    name={myPackage.package.name}
                                    description={myPackage.package.description}
                                    quantity={myPackage.quantity | myPackage.quantity_package}
                                    handleQuantityChange={quantity => {
                                        this.props.handleQuantityChange(1, index, quantity);
                                    }}
                                    handlePriceChange={
                                        this.props.handlePriceChange
                                            ? price => this.props.handlePriceChange(1, index, price.target.value)
                                            : null
                                    }
                                    handleDelete={() => {
                                        this.props.handleDelete(1, index);
                                    }}
                                />
                            );
                        });
                    })()}
                    <div
                        className="addProduct"
                        onClick={() => {
                            this.setState({
                                selectedDay: 1,
                                modalShow: true
                            });
                        }}
                    >
                        <i className="fas fa-plus blue" />
                    </div>
                </div>
                <div className="col-md-2 day">
                    <p className="dayname">Mercredi</p>
                    <p className="sub-dayname" />
                    {(() => {
                        return this.props.activePackages[2].map((myPackage, index) => {
                            let price = myPackage.amount == null ? myPackage.package.price : myPackage.amount;

                            return (
                                <CalendarBox
                                    key={index}
                                    price={price}
                                    name={myPackage.package.name}
                                    description={myPackage.package.description}
                                    quantity={myPackage.quantity | myPackage.quantity_package}
                                    handleQuantityChange={quantity => {
                                        this.props.handleQuantityChange(2, index, quantity);
                                    }}
                                    handlePriceChange={
                                        this.props.handlePriceChange
                                            ? price => this.props.handlePriceChange(2, index, price.target.value)
                                            : null
                                    }
                                    handleDelete={() => {
                                        this.props.handleDelete(2, index);
                                    }}
                                />
                            );
                        });
                    })()}
                    <div
                        className="addProduct"
                        onClick={() => {
                            this.setState({
                                selectedDay: 2,
                                modalShow: true
                            });
                        }}
                    >
                        <i className="fas fa-plus blue" />
                    </div>
                </div>
                <div className="col-md-2 day">
                    <p className="dayname">Jeudi</p>
                    <p className="sub-dayname" />
                    {(() => {
                        return this.props.activePackages[3].map((myPackage, index) => {
                            let price = myPackage.amount == null ? myPackage.package.price : myPackage.amount;

                            return (
                                <CalendarBox
                                    key={index}
                                    price={price}
                                    name={myPackage.package.name}
                                    description={myPackage.package.description}
                                    quantity={myPackage.quantity | myPackage.quantity_package}
                                    handleQuantityChange={quantity => {
                                        this.props.handleQuantityChange(3, index, quantity);
                                    }}
                                    handlePriceChange={
                                        this.props.handlePriceChange
                                            ? price => this.props.handlePriceChange(3, index, price.target.value)
                                            : null
                                    }
                                    handleDelete={() => {
                                        this.props.handleDelete(3, index);
                                    }}
                                />
                            );
                        });
                    })()}
                    <div
                        className="addProduct"
                        onClick={() => {
                            this.setState({
                                selectedDay: 3,
                                modalShow: true
                            });
                        }}
                    >
                        <i className="fas fa-plus blue" />
                    </div>
                </div>
                <div className="col-md-2 day">
                    <p className="dayname">Vendredi</p>
                    <p className="sub-dayname" />
                    {(() => {
                        return this.props.activePackages[4].map((myPackage, index) => {
                            let price = myPackage.amount == null ? myPackage.package.price : myPackage.amount;

                            return (
                                <CalendarBox
                                    key={index}
                                    price={price}
                                    name={myPackage.package.name}
                                    description={myPackage.package.description}
                                    quantity={myPackage.quantity | myPackage.quantity_package}
                                    handleQuantityChange={quantity => {
                                        this.props.handleQuantityChange(4, index, quantity);
                                    }}
                                    handlePriceChange={
                                        this.props.handlePriceChange
                                            ? price => this.props.handlePriceChange(4, index, price.target.value)
                                            : null
                                    }
                                    handleDelete={() => {
                                        this.props.handleDelete(4, index);
                                    }}
                                />
                            );
                        });
                    })()}
                    <div
                        className="addProduct"
                        onClick={() => {
                            this.setState({
                                selectedDay: 4,
                                modalShow: true
                            });
                        }}
                    >
                        <i className="fas fa-plus blue" />
                    </div>
                </div>
            </div>
        );
    }
}
