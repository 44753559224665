import React from 'react';

import '../../Assets/css/Pages/NotFound.css';

const NotFound = () => (
    <div className="NotFound">
        <div className="main">
            <h3>Erreur</h3>
            <h2>404</h2>
            <h4>Oups, une erreur est survenue</h4>
        </div>
    </div>
);

export default NotFound;
