import React, { Component } from 'react'
import Button from '../../../Ui/Button';
import addNotification from '../../../Ui/Notifications';
import { fetchApi } from '../../../../Services/Network';

export default class Password extends Component {

    constructor(props) {
        super(props)
        
        this.sendPassword = this.sendPassword.bind(this);
    }

    sendPassword() {
        fetchApi('/admin/new-password', {
            method: 'POST',
            body: JSON.stringify({clientId: this.props.user.id})
        }).then(() => {
            addNotification('Un mot de passe vient d\'etre envoyé !')
        }).catch((err) => {
            addNotification('Echec de la génération du mot de passe', 'danger')
        })
    }
    

    render() {
        return (
            <div>
                <Button onClick={this.sendPassword}>Reinitialiser le mot de passe</Button>
            </div>
        )
    }
}
