import React, { Component } from 'react';

import { fetchApi } from '../../../../Services/Network';

import Input from '../../../Ui/Input';
import Button from '../../../Ui/Button';
import SpinnerLoading from '../../../Ui/SpinnerLoading';
import { show } from '../../../Ui/Modals';
import axios from "axios";
import { endpoint } from '../../../../Constants';
export default class BillingInformations extends Component {
    constructor() {
        super();

        this.state = {
            entity_name: '',
            address: '',
            zip_code: '',
            city: '',
            phone: '',
            siret_number: '',
            vat_number: '',
            contact_email_address: '',
            loading: true
        };

        this.errors = {};

        this.isFormValid = this.isFormValid.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    isFormValid() {
        // Checks that each field is not blank
        var toCheck;
        if (this.props.adminMode) {
            toCheck = {
                entity_name: this.state.entity_name,
                address: this.state.address,
                zip_code: this.state.zip_code,
                phone: this.state.phone,
                city: this.state.city,
                contact_email_address: this.state.contact_email_address
            };
        } else {
            toCheck = {
                entity_name: this.state.entity_name,
                address: this.state.address,
                zip_code: this.state.zip_code,
                city: this.state.city,
                siret_number: this.state.siret_number,
                vat_number: this.state.vat_number,
                contact_email_address: this.state.contact_email_address
            };
        }
        return Object.keys(toCheck).every(k => typeof this.state[k] !== 'undefined' ? this.state[k].trim().length > 0 : false);


        // You're free to add any test
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();
        let org = this.props.user.organization
        const {
            entity_name,
            address,
            zip_code,
            city,
            phone,
            siret_number,
            vat_number,
            contact_email_address
        } = this.state;

        const body = {
            organisation: org.id,
            entity_name,
            address,
            zip_code,
            city,
            phone,
            siret_number,
            vat_number,
            contact_email_address
        };

        this.setState({ loading: true });

        axios.post(`${endpoint}/api/delivery/edit_org/${localStorage.getItem('token')}`, body, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
            .then(() => {
                this.setState({ loading: false });

                const newUser = {
                    ...this.props.user
                };

                newUser.organization = {
                    ...newUser.organization,
                    entity_name,
                    address,
                    zip_code,
                    city,
                    phone,
                    siret_number,
                    vat_number,
                    contact_email_address
                };

                if (this.props.adminMode) {
                    this.props.setNewUser(newUser);
                } else {
                    this.props.feedUserMeta(newUser);
                }

                this.props.handleNextPage();
            })
            .catch(err => {
                if (err.code === 400) {
                    Object.keys(err.body).forEach(k => {
                        this.errors[k] = err.body[k];
                    });
                }

                this.setState({ loading: false });
            });
    }

    componentDidMount() {
        const {
            entity_name,
            address,
            zip_code,
            city,
            phone,
            siret_number,
            vat_number,
            contact_email_address
        } = this.props.user.organization;

        this.setState({
            entity_name: entity_name || '',
            address: address || '',
            zip_code: zip_code || '',
            city: city || '',
            phone: phone || '',
            siret_number: siret_number || '',
            vat_number: vat_number || '',
            contact_email_address: contact_email_address || ''
        });

        setTimeout(() =>
            show(
                'Vos informations de facturation sont-elles identiques à votre adresse de livraison ?',
                'yes/no',
                () => {
                    let location_infos = "address" in this.props.user.delivery_locations[0] ? this.props.user.delivery_locations[0] : this.props.user.delivery_locations[0].data
                    const {
                        address,
                        zip_code,
                        city
                    } = location_infos ;

                    this.setState({
                        address,
                        zip_code,
                        city,
                        entity_name: this.props.user.organization.name,
                        loading: false
                    });
                },
                () => {
                    this.setState({
                        loading: false
                    });
                }
            )
        );
    }

    render() {
        if (this.state.loading) {
            return <SpinnerLoading />;
        }

        const buttonDisabled = !this.isFormValid();

        return (
            <div className="accountTunnel">
                <p className="catchSentence">Top !</p>
                <h2>La facturation maintenant !</h2>
                <p>
                    Vos informations de facturation sont essentielles pour le
                    bon fonctionnement de la plateforme. <br />
                    Merci de renseigner l'entité de facturation et les
                    coordonnées de votre service comptable. Les factures seront
                    envoyées tous les mois sur cette adresse
                </p>
                <form onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <Input
                                    type="text"
                                    placeholder="Saisissez le nom de votre société"
                                    label="SOCIETE DE FACTURATION"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.entity_name}
                                    name="entity_name"
                                    errors={this.errors}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <div>
                                    <Input
                                        type="text"
                                        placeholder="adresse de facturation"
                                        label="Adresse de facturation"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.address}
                                        name="address"
                                        errors={this.errors}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <Input
                                    type="text"
                                    placeholder="00000"
                                    label="CODE POSTAL"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.zip_code}
                                    name="zip_code"
                                    errors={this.errors}
                                />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="form-group">
                                <Input
                                    type="text"
                                    placeholder="Ville"
                                    label="VILLE"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.city}
                                    name="city"
                                    errors={this.errors}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Input
                                type="text"
                                label="Ligne directe"
                                placeholder="0X XX XX XX XX"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.phone}
                                name="phone"
                                errors={this.errors}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <Input
                                    type="text"
                                    placeholder="XXX XXX XXX XXXXX"
                                    label="N° SIRET"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.siret_number}
                                    name="siret_number"
                                    errors={this.errors}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <Input
                                    type="text"
                                    placeholder="XX XXX XXX XXX XX"
                                    label="N° TVA"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.vat_number}
                                    name="vat_number"
                                    errors={this.errors}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <Input
                                    type="email"
                                    placeholder="Addresse EMail"
                                    label="CONTACT POUR FACTURATION (COMPTABILITÉ)"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.contact_email_address}
                                    name="contact_email_address"
                                    errors={this.errors}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Button
                                disabled={buttonDisabled}
                                loading={this.state.loading}
                            >
                                Suivant
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
