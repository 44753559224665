import React from 'react';

/* CSS IMPORT */

import '../../../../../Assets/css/Ui/Tunnel/Cafe/Components/CafePack.css';

const CafePack = props => (
    <div
        onClick={props.onClick}
        className={`CafePack${props.selected === props.id ? ' selected' : ''}`}
    >
        <img
            src={props.image}
            style={{ maxWidth: '100%' }}
            alt="Pack preview"
        />
        <div className="text">
            <h3>{props.name}</h3>
            <ul>
                {props.description.split('\n').map(d => (
                    <li key={d}>{d}</li>
                ))}
            </ul>
        </div>
        <div className="price">
            <p>
                <mark>
                    <strong>{props.price} €</strong>/{props.unit_name}
                </mark>
                soit <strong style={{marginLeft: '4px'}}>{props.price_month} €</strong>/mois
            </p>
        </div>
    </div>
);

export default CafePack;