import React from 'react';

import '../../Assets/css/Ui/DeliveryLocation.css';

export class SimpleMarkerOnMap extends React.Component {
    constructor() {
        super();
        this.mapElement = null;
        this.map = null;
        this.marker = null;
    }

    componentDidMount() {
        this.map = new window.google.maps.Map(this.mapElement, {
            zoom: 14.5,
            center: this.props.position,
            disableDefaultUI: true
        });


        this.marker = new window.google.maps.Marker({
            position: this.props.position,
            icon: {
                url:
                    'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2_hdpi.png',
                size: { width: 15, height: 25 },
                anchor: { x: 5, y: 18 },
                scaledSize: { width: 15, height: 25 }
            }
        });

        this.marker.setMap(this.map);
    }

    render() {
        if (this.marker) {
            this.marker.setPosition(this.props.position);
            this.map.setCenter(this.props.position);
        }

        return (
            <div
                style={{
                    height: '100%',
                    width: '192px',
                    display: 'inline-block',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '8px'
                }}
                ref={r => (this.mapElement = r)}
            />
        );
    }
}

const DeliveryLocation = props => (

    <div
        className={`DeliveryLocation${
            props.selected === props.id ? ' selected' : ''
        }`}
        onClick={() => {
            if (!props.selectable) {
                return;
            }
            props.onSelect(props.id);
        }}
    >
        <SimpleMarkerOnMap position={{lat: props.latitude, lng: props.longitude}} />
        <div className="text">
            <h2>{props.name}</h2>
            <p>{props.address}</p>
            <p>
                {props.zip_code} {props.city}
            </p>
        </div>
        {props.editable === false || (
            <button
                className={props.edit === props.id ? 'red' : 'blue'}
                onClick={() => props.handleEdit(props.id)}
            >
                {props.edit === props.id ? 'Fermer' : 'Editer'}
            </button>
        )}
    </div>
);

export default DeliveryLocation;
