import React from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
} from 'react-places-autocomplete';

import '../../Assets/css/Ui/StandaloneMapsSearchBox.css';

class StandaloneMapsSearchBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = { address: props.initialValue || '' };
    }

    handleChange = address => {
        this.setState({ address });
    };

    handleSelect = address => {
        geocodeByAddress(address)
            .then(async results => {
                const result = results[0];
                const pos = await getLatLng(results[0]);
                this.setState({ address });

                const zip_code = result.address_components.find((e) => {
                    return e.types.indexOf("postal_code") !== -1
                }).long_name;

                const city = result.address_components.find(
                    ({ types }) => types.indexOf('locality') !== -1
                ).long_name;

                const latitude = pos.lat;
                const longitude = pos.lng;

                this.props.onPlacesChanged({
                    address,
                    city,
                    zip_code,
                    latitude,
                    longitude
                });
            })
            .catch(error => console.error('Error', error));
    };

    render() {
        return (
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                searchOptions={{
                    types: ['address'],
                    componentRestrictions: { country: ['fr'] }
                }}
            >
                {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading
                }) => (
                    <div>
                        <input
                            {...getInputProps({
                                placeholder: 'Rechercher...',
                                className: 'location-search-input form-control'
                            })}
                        />
                        <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                // inline style for demonstration purpose

                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        );
    }
}

export default StandaloneMapsSearchBox;
