import React from 'react';
import { HashLoader } from 'react-spinners';

import '../../Assets/css/Ui/SpinnerLoading.css';

class SpinnerLoading extends React.Component {
    render() {
        return (
            <div className="SpinnerLoading">
                <HashLoader size={60} color={'#FFC200'} loading={true} />
            </div>
        );
    }
}

export default SpinnerLoading;
