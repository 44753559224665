import React from 'react';

import SpinnerLoading from '../Ui/SpinnerLoading';

import { fetchApi } from '../../Services/Network';
import { show } from '../Ui/Modals';
import axios from "axios";
import { endpoint } from '../../Constants';

export default class Logout extends React.Component {
    componentDidMount() {
        show(
            'Vous êtes sur le point de vous déconnecter',
            'info',
            _ => {
                fetch(`${endpoint}/api/v1/users/auth/logout/`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('token')}`
                  }
                })
                .then(res => {
                    if (res.status === 200) {
                        localStorage.clear();
                        this.props.history.push('/login');
                    }
                });
            },
            _ => {
                this.props.history.goBack();
            }
        );
    }

    render() {
        return <SpinnerLoading />;
    }
}
