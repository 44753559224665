import React from 'react';

import Button from '../../Button';
import CafePack from './Components/CafePack';

import '../../../../Assets/css/Ui/Tunnel/Cafe/PackageSelectionPage.css';

export const PackageSelectionPage = props => (
    <div className="PackageSelectionPage">
        <div>
            <p className="catchSentence">C'est parti pour le café !</p>
            <h2>Qu'est ce qui vous ferait plaisir ?</h2>
        </div>

        <div className="row">
            <div className="col-md-12">
                {/*<div className="blue-box mt-0 small" style={{ height: 'auto' }}>
                    <div className="row">
                        <div className="col-md-8">
                            <p className="title">
                                D’après toutes vos informations nous vous avons
                                créé la commande idéale.{' '}
                                <span role="img" aria-label="ok">
                                    👌
                                </span>
                            </p>
                            <p className="sub-title">
                                Vous pouvez tout de même la personnaliser —
                                Faites-vous plaisir !
                            </p>
                        </div>
                        <div
                            className="col-md-4"
                            style={{ textAlign: 'right' }}
                        >
                            <Button white={true}>C’est noté !</Button>
                        </div>
                    </div>
                </div>*/}
                <div className="row">
                    {props.packs
                        .filter(({ id }) => id !== 1)
                        .map(pack => (
                            <div
                                className="pack-container col-sm-3 col-xs-3 "
                                key={pack.id}
                            >
                                <CafePack
                                    {...pack}
                                    selected={props.selected}
                                    onClick={() =>
                                        props.selectPackage(pack.id)
                                    }
                                />
                            </div>
                        ))}
                    {/**
                        <div
                            className={`already-have${
                                props.selected === 1 ? ' active' : ''
                            }`}
                            onClick={() => props.selectPackage(1)}
                        >
                            <p>Nous avons déjà une machine </p>
                        </div>
                    */}
                </div>
            </div>
        </div>
        <div className="row mt-32">
            <div className="col-md-12">
                <Button
                    onClick={props.handleNextPage}
                    disabled={!!!props.selected}
                >
                    Etape suivante !
                </Button>
            </div>
        </div>
    </div>
);
