import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { endpoint } from '../../Constants';

import { InvoiceItem } from '../Ui/InvoiceItem';
import SpinnerLoading from '../Ui/SpinnerLoading';

import { fetchApi } from '../../Services/Network';
import axios from "axios"

const getFormatedDate = (date, id) => {
    return (
        `Facture #${id} de ` +
        moment(date).format('MMMM') +
        ' ' +
        moment(date).format('YYYY')
    );
};

class Invoices extends React.Component {
    constructor() {
        super();

        this.state = {
            invoices: [],
            loading: true
        };
    }

    /*componentDidMount() {
        fetchApi(
            `/organizations/${this.props.id ||
                this.props.user.organization._id}/invoices`
        ).then(res => {
            this.setState({ invoices: res, loading: false });
            console.log(res);
        });
    }*/
    componentDidMount() {
        axios.get(`${endpoint}/api/delivery/invoices_client/${this.props.id ||
                this.props.user.organization.id}/`, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
        .then(res => {
            this.setState({ invoices: res.data, loading: false });
        })};

    render() {
        if (this.state.loading) {
            return <SpinnerLoading />;
        }

        if (
            this.state.invoices.peppr.length === 0 &&
            this.state.invoices.lpf.length === 0
        ) {
            return (
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="Header">Mes Factures</h1>
                        <span className="noSubs">
                            Vous n’avez pas encore de facture. Une fois abonné à
                            un service, vous les retrouverez ici{' '}
                            <span role="img" aria-label="happy">
                                😉
                            </span>
                        </span>
                    </div>
                </div>
            );
        }

        return (
            <div className="row">
                <div className="col-md-12">
                    <h1 className="Header">
                        Mes factures fruits, fruits secs et boissons
                    </h1>
                    <div className={`Invoices`}>
                        {this.state.invoices.lpf
                            .filter(e =>
                                e.CustomerMemo
                                    ? e.CustomerMemo.value.search('annulée') ===
                                      -1
                                    : true
                            )
                            .map(i => (
                                <InvoiceItem
                                    key={i.Id}
                                    product={getFormatedDate(
                                        i.date,
                                        i.id
                                    )}
                                    price={i.amount}
                                    href={`${endpoint}/organizations/${this
                                        .props.id ||
                                        this.props.user.organization
                                            .id}/invoices-download/${i.Id}`}
                                    status={i.status}
                                    small={this.props.small}
                                />
                            ))}
                    </div>
                </div>
                <div className="col-md-12">
                    <div className={`Invoices`}>
                        {this.state.invoices.peppr
                            .filter(e =>
                                e.CustomerMemo
                                    ? e.CustomerMemo.value.search('annulée') ===
                                      -1
                                    : true
                            )
                            .map(i => (
                                <InvoiceItem
                                    key={i.Id}
                                    product={getFormatedDate(
                                        i.date,
                                        i.id
                                    )}
                                    price={i.amount}
                                    href={i.file_url}
                                    status={i.status}
                                    small={this.props.small}
                                />
                            ))}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user
});

export default connect(mapStateToProps)(Invoices);
