import React from 'react';

import '../../Assets/css/Ui/FreeServiceBox.css';

export const FreeServiceBox = props => (
    <div
        className={props.active ? 'freeservice-box active' : 'freeservice-box'}
        onClick={props.handleClick ? props.handleClick : () => {}}
        style={{
            cursor: `${props.handleClick ? 'pointer' : 'inherit'}`
        }}
    >
        <div className="img-content">
            <img
                src={`/assets/img/${props.freeService.img.filename}`}
                height={props.freeService.img.height}
                width={props.freeService.img.width}
                alt={props.freeService.img.alt}
                style={{
                    marginTop: `-${props.freeService.img.height / 2}px`,
                    marginLeft: `-${props.freeService.img.width / 2}px`
                }}
            />
        </div>
        <div className="main-content">
            <p className="name">{props.freeService.name}</p>
            <p className="description">{props.freeService.description}</p>
        </div>
        <div className="footer-content">
            <p>{props.freeService.price}</p>
        </div>
    </div>
);
export const FreeServiceBoxSlider = props => (
    <div className="FreeServiceBoxSlider">{props.children}</div>
);
