import React, { Component } from 'react';

import { fetchApi } from '../../../../Services/Network';
import Button from '../../../Ui/Button';
import addNotification from '../../../Ui/Notifications';
import axios from "axios";
import { endpoint } from '../../../../Constants';
export default class Quickbooks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };

        this.handleInvoicesGeneration = this.handleInvoicesGeneration.bind(
            this
        );
    }

    handleInvoicesGeneration() {
        this.setState({ loading: true });
        axios.post(`${endpoint}/api/delivery/invoices_api/`, {}, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}}).then(res=> {
            this.setState({ loading: false });
            addNotification('Génération des factures terminé !');
        })
        //axios.get("${endpoint}/quickbooks/oauth/");
        /*fetchApi('/admin/generate-invoices').then(() => {
            this.setState({ loading: false });
            addNotification('Génération des factures terminé !');
        });*/
    }

    render() {
        return (
            <div className="sidecontent">
                <div className="row">
                    <div className="col-md-12">
                        <Button
                            onClick={this.handleInvoicesGeneration}
                            disabled={false}
                            loading={this.state.loading}
                        >
                            Génération des factures
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
