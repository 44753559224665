import React from 'react';

import {connect} from 'react-redux';

import Button from './Button';
import Calendar from 'react-calendar';

import '../../Assets/css/Ui/Modals.css';

let instance = null;

const noop = _ => null;

export const show = (message, type, confirmHandler, cancelHandler) => {
    if (!instance) {
        return console.error('Internal Error (Modal)');
    }

    // Show
    instance.show(message, type, confirmHandler, cancelHandler);
};

class ModalNoConnected extends React.Component {
    constructor() {
        super();

        instance = this;

        this.state = {
            open: false,
            message: '',
            type: 'info',
            dateInterval: []
        };

        this.cancelHandler = null;
        this.confirmHandler = null;
    }

    show(message, type, confirmHandler, cancelHandler) {
        this.cancelHandler = () => {
            this.setState({ open: false });

            cancelHandler = cancelHandler || noop;

            cancelHandler();
        };

        this.confirmHandler = (data = null) => {
            this.setState({ open: false });
            confirmHandler(data);
        };

        this.setState({
            open: true,
            message: message,
            type: type
        });
    }

    render() {
        if (!this.state.open) {
            return null;
        }

        return (
            <div className="Modal">
                <div className="window">
                    <p>{this.state.message}</p>
                    {(() => {
                        const threeDays = new Date();
                        threeDays.setDate(threeDays.getDate() + 3);
                        const noDays = new Date();
                        switch (this.state.type) {
                            case 'info':
                                return (
                                    <div className="footer info">
                                        <button
                                            className="link"
                                            onClick={this.cancelHandler}
                                        >
                                            Retour
                                        </button>
                                        <Button
                                            style={{
                                                display: 'inline-block',
                                                marginLeft: '2em'
                                            }}
                                            onClick={this.confirmHandler}
                                        >
                                            Confirmer
                                        </Button>
                                    </div>
                                );
                            case 'yes/no':
                                return (
                                    <div className="footer info">
                                        <button
                                            className="link"
                                            onClick={this.cancelHandler}
                                        >
                                            Non
                                        </button>
                                        <Button
                                            style={{
                                                display: 'inline-block',
                                                marginLeft: '2em'
                                            }}
                                            onClick={this.confirmHandler}
                                        >
                                            Oui
                                        </Button>
                                    </div>
                                );
                            case 'quit':
                                return (
                                    <div className="footer quit">
                                        <button
                                            className="link"
                                            onClick={this.cancelHandler}
                                        >
                                            Rester !
                                        </button>
                                        <Button
                                            style={{
                                                display: 'inline-block',
                                                marginLeft: '2em'
                                            }}
                                            onClick={this.confirmHandler}
                                        >
                                            Quitter cette page
                                        </Button>
                                    </div>
                                );
                            case 'delete':
                                return (
                                    <div className="footer delete">
                                        <button
                                            className="link"
                                            onClick={this.cancelHandler}
                                        >
                                            Annuler
                                        </button>
                                        <Button
                                            style={{
                                                display: 'inline-block',
                                                marginLeft: '2em'
                                            }}
                                            onClick={this.confirmHandler}
                                        >
                                            Supprimer
                                        </Button>
                                    </div>
                                );
                            case 'date-interval':
                                return (
                                    <div>
                                        <Calendar
                                            selectRange={true}
                                            onChange={date =>
                                                this.setState({
                                                    dateInterval: date
                                                })
                                            }
                                            minDate={this.props.user.role !== 'Admin' ? threeDays : noDays}
                                        />
                                        <div className="footer info">
                                            <button
                                                className="link"
                                                onClick={this.cancelHandler}
                                            >
                                                Retour
                                            </button>
                                            <Button
                                                style={{
                                                    display: 'inline-block',
                                                    marginLeft: '2em'
                                                }}
                                                onClick={() =>
                                                    this.confirmHandler(
                                                        this.state.dateInterval
                                                    )
                                                }
                                            >
                                                Confirmer
                                            </Button>
                                        </div>
                                    </div>
                                );
                            default:
                                break;
                        }
                    })()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user
});

export const Modal = connect(mapStateToProps, {})(ModalNoConnected);