import React from 'react';
import { Link } from 'react-router-dom';

import '../../Assets/css/Ui/CompleteAccount.css';
import Button from './Button';

const CompleteAccount = props => {
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="blue-box">
                    <div className="row">
                        <div className="col-md-8">
                            <p className="title">Plus qu’une chose à faire…</p>
                            <p className="sub-title">
                                Nous sommes heureux de vous compter parmi nos
                                utilisateurs !<br />
                                Afin de profiter à 100% de Pepp’r, vous devez
                                completer votre profil.
                            </p>
                        </div>
                        <div
                            className="col-md-4"
                            style={{ textAlign: 'right' }}
                        >
                            <Link to="/accountTunnel">
                                <Button
                                    style={{ marginTop: '14px' }}
                                    white={true}
                                >
                                    Compléter mon compte
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompleteAccount;
