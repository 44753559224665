import React from 'react';
import Button from '../../Ui/Button';
import { connect } from 'react-redux';
import { feedUserMeta } from '../../../Redux/Actions';
import { fetchApi } from '../../../Services/Network';
import Input from '../../Ui/Input';
import addNotification from '../../Ui/Notifications';
import axios from "axios"
import { endpoint } from '../../../Constants';
class General extends React.Component {
    constructor() {
        super();

        this.state = {
            id: null,
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            organization: '',
            edited: false,
            loading: false,
            additional_information: '',
            sales_manager: ''
        };

        this.errors = {};

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();

        /* Handler */
        const {
            id,
            first_name,
            last_name,
            email,
            phone,
            organization,
            additional_information,
            sales_manager
        } = this.state;

        this.setState({ loading: true });

        this.errors = {};

        /**
         * We need to fetch the id given in props if we are updating data for
         * another user. If not, the id from the meta will be used. It means
         * that we are updating `ourselves`.
         */
         axios.put(`${endpoint}/api/v1/users/edit/${localStorage.getItem('token')}/${this.state.id || this.props.user.id}/`, {
                id : this.state.id || this.props.user.id,
                first_name,
                last_name,
                email,
                phone,
                organization,
                additional_information,
                sales_manager
            }, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
            .then(() => {
                this.setState({ loading: false, edited: false });
                const newUser = {
                    ...this.props.user,
                    first_name,
                    last_name,
                    email,
                    phone,
                    organization
                };
                newUser.organization = organization;

                addNotification(
                    'Vos informations ont bien été mises à jour ! 😎'
                );

                /**
                 * We'll need to refresh the current user if WE are the current user.
                 * If we passed an id, that means thar we are editinf the #id user.
                 * Hence, no meta needs to be updated.
                 */
                if (!this.props.id) {
                    this.props.feedUserMeta(newUser);
                }
            })
            .catch(err => {
                if (err.code === 400) {
                    Object.keys(err.body).forEach(k => {
                        this.errors[k] = err.body[k];
                    });
                }

                if (err.code === 409) {
                    this.errors.email = 'EMAIL_IN_USE';
                }
                addNotification(
                    'Problème avec les informations client ...',
                    'danger'
                );
                this.setState({ loading: false });
            });
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value, edited: true });
    }

    isFormValid() {
        // Checks that each field is not blank
        return this.state.edited;

        // You're free to add any test
    }

    componentDidMount() {
        if (this.props.id) {
            /**
             * If an ID is passed, that means we are using admin account
             * to manage user #id. We'll need to fetch its profile before
             * being able to edit it ; hence the following fetch .
             */
             axios.get(`${endpoint}/api/v1/users/user_data/`+ this.props.id + '/', {headers:{
                            'Authorization': `Token ${localStorage.getItem('apikey')}`
                            }
                        })
                .then(res => {
                    const {
                        id,
                        first_name,
                        last_name,
                        email,
                        phone,
                        organization
                    } = res.data;
                    this.setState({
                        id: id || null,
                        first_name: first_name || '',
                        last_name: last_name || '',
                        email: email || '',
                        phone: phone || '',
                        organization: organization.name || '',
                        additional_information:
                            organization.additional_information || '',
                        sales_manager: organization.sales_manager || ''
                    });
                })
                .catch(err => {
                    if (err.code === 404) {
                        // User with such id does not exist
                        console.error('No such user', this.props.id);
                    } else if (err.code === 403) {
                        // Unauthorized access to resource
                        console.error(
                            'Forbidden, how the fuck did you got there?'
                        );
                    }
                });
        } else {
            /**
             * To keep a fast service, the user metadata will be retreived
             * from the global redux state.
             */
            const {
                first_name,
                last_name,
                email,
                phone,
                organization
            } = this.props.user;

            this.setState({
                first_name: first_name || '',
                last_name: last_name || '',
                email: email || '',
                phone: phone || '',
                organization: organization.name || '',
                sales_manager: organization.sales_manager || ''
            });
        }
    }

    render() {
        const buttonDisabled = !this.isFormValid();

        return (
            <div className="sidecontent">
                <div className="sideLayoutInput">
                    <form onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <Input
                                    type="text"
                                    placeholder="Prénom"
                                    label="Prénom"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.first_name}
                                    name="first_name"
                                    errors={this.errors}
                                />
                            </div>
                            <div className="col-md-6">
                                <Input
                                    type="text"
                                    placeholder="NOM"
                                    label="NOM"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.last_name}
                                    name="last_name"
                                    errors={this.errors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Input
                                    type="email"
                                    placeholder="mail@societe.com"
                                    label="Mail pro"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.email}
                                    name="email"
                                    errors={this.errors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Input
                                    type="text"
                                    label="Ligne directe"
                                    placeholder="0X XX XX XX XX"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.phone}
                                    name="phone"
                                    errors={this.errors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Input
                                    type="text"
                                    label="Societe"
                                    placeholder="Société"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.organization}
                                    name="organization"
                                    errors={this.errors}
                                />
                            </div>
                        </div>
                        {!this.props.admin || (
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="additional_information">
                                            Informations livreur
                                        </label>
                                        <textarea
                                            name="additional_information"
                                            cols="30"
                                            rows="10"
                                            className="form-control"
                                            value={
                                                this.state.additional_information
                                            }
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {this.props.user.email == "maxime@lesplaisirsfruites.com" ? (
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Sales manager</label>
                                        <select
                                            name="sales_manager"
                                            value={this.state.sales_manager}
                                            onChange={this.handleChange}
                                            className={'form-control'}
                                        >
                                            <option key="lpf" value="lpf">
                                                LPF
                                            </option>
                                            <option key="sales1" value="sales1">
                                                Sales 1
                                            </option>
                                            <option key="sales2" value="sales2">
                                                Sales 2
                                            </option>
                                            <option key="sales3" value="sales3">
                                                Sales 3
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        ) : console.log('no sales manager role')}
                        <div className="row">
                            <div className="col-md-12">
                                <Button
                                    disabled={buttonDisabled}
                                    loading={this.state.loading}
                                >
                                    Enregistrer
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user
});

const mapDispatchToProps = dispatch => ({
    feedUserMeta: user => dispatch(feedUserMeta(user))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(General);
