import React from 'react';
import { connect } from 'react-redux';

/* SERVICES IMPORT */

import { fetchApi } from '../../../Services/Network';
import axios from "axios";

/* COMPONENTS IMPORT */

import ProductOrderReview from './ProductOrderReview';
import ServiceOrderReview from './ServiceOrderReview';
import CafeOrderReview from './CafeOrderReview';
import { show } from '../../Ui/Modals';
import addNotification from '../../Ui/Notifications';
import SpinnerLoading from '../../Ui/SpinnerLoading';
import { endpoint } from '../../../Constants';
class OrderReview extends React.Component {
    constructor() {
        super();

        this.state = {
            order: null,
            edited: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSelectOption = this.handleSelectOption.bind(this);
        this.handleSuspendOrder = this.handleSuspendOrder.bind(this);
        this.handleUnSuspendOrder = this.handleUnSuspendOrder.bind(this);
    }

    componentDidMount() {
        axios.get(`${endpoint}/api/delivery/order_edit/${this.props.match.params.id}/${
                this.props.match.params.oid
            }/${localStorage.getItem('token')}`, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
        .then(res => {
        let order = res.data
        this.setState({ order })})
        .catch(err => {
            addNotification('Erreur lors de la modification', 'danger');
        });
    }

    handleChange(e) {
        const order = this.state.order;

        order[e.target.name] = e.target.value;
        console.log(order)
        this.setState({
            order,
            edited: true
        });
    }

    handleSelectOption(o) {
        const order = this.state.order;
        order.option = {id: o};
        this.setState({ order, edited: true });
    }

    handleSuspendOrder(interval) {
        show(
            'Êtes-vous sûrs de vouloir suspendre cet abonnement ?',
            'info',
            () => {
                axios.put(`${endpoint}/api/delivery/order_suspend/` +
                this.state.order.organisation + "/" + this.state.order.id + `/${localStorage.getItem('token')}`, {
                            status: 'DISABLED',
                            disabled_date_start: interval[0],
                            disabled_date_end: interval[1]
                        }, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
                .then(_ => {
                    addNotification('Abonnement suspendu !', 'success');
                    if(this.props.user.role !== 'Admin'){
                        this.props.history.replace(`/`);
                    } else {
                        this.props.history.replace(`/customer/${this.state.order.organisation}`);
                    }
                });
            }
        );
    }

    handleUnSuspendOrder() {
        axios.put(`${endpoint}/api/delivery/order_unsuspend/` +
                this.state.order.organisation + "/" + this.state.order.id + `/${localStorage.getItem('token')}`, {
                            status: 'ACTIVE',
                        }, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}}).then(_ => {
            addNotification('La suspension a bien été annulée !', 'success');
            if(this.props.user.role !== 'Admin'){
                this.props.history.replace(`/`);
            } else {
                this.props.history.replace(`/customer/${this.state.order.organisation}`);
            }
        });
    }

    render() {
        if (this.state.order === null) {
            return <SpinnerLoading />;
        }

        switch (this.state.order.service.type) {
            case 'product':
                return (
                    <ProductOrderReview
                        order={this.state.order}
                        edited={this.state.edited}
                        setFrequence={freq => {
                            const items = this.state.order;
                            if (
                                this.state.order.delivery_frequency ===
                                    'promptly' &&
                                freq !== 'promplty'
                            ) {
                                items.planning = [[], [], [], [], []];
                            }
                            if (
                                this.state.order.delivery_frequency !==
                                    'promptly' &&
                                freq === 'promplty'
                            ) {
                                items.planning = [[]];
                            }
                            items.delivery_frequency = freq;
                            this.setState({ order: items, edited: true });
                        }}
                        addPackages={(day, newPackages) => {
                            const items = this.state.order;
                            items.packages[day] = newPackages;
                            this.setState({ order: items, edited: true });

                        }}
                        handleQuantityChange={(day, index, quantity) => {
                            const items = this.state.order;
                            items.packages[day][index].package.quantity = quantity;
                            items.packages[day][index].quantity_package = quantity;
                            this.setState({
                                order: items,
                                edited: true
                            });
                        }}
                        handlePriceChange={(day, index, price) => {
                            const items = this.state.order;
                            items.packages[day][index].amount = price.replace(
                                /,/g,
                                '.'
                            );
                            this.setState({
                                order: items,
                                edited: true
                            });
                        }}
                        handleDelete={(day, index) => {
                            const items = this.state.order;
                            items.packages[day].splice(index, 1);
                            this.setState({
                                order: items,
                                edited: true
                            });
                        }}
                        handleReset={() => {
                            const items = this.state.order;
                            items.planning = [[], [], [], [], []];
                            this.setState({
                                order: items,
                                edited: true
                            });
                        }}
                        handleResetPromptly={() => {
                            const items = this.state.order;
                            items.planning = [[]];
                            this.setState({
                                order: items,
                                edited: true
                            });
                        }}
                        handleChange={this.handleChange}
                        history={this.props.history}
                        user={this.props.user}
                        handleSuspendOrder={this.handleSuspendOrder}
                        handleUnSuspendOrder={this.handleUnSuspendOrder}
                    />
                );

            case 'service':
                return (
                    <ServiceOrderReview
                        order={this.state.order}
                        edited={this.state.edited}
                        handleQuantityChange={(element, quantity) => {
                            const items = this.state.order;

                            const isInArray = items.packages[0].findIndex(
                                obj => obj.package_id === element.id
                            );
                            if (isInArray > -1 && quantity > 0) {
                                items.packages[0][isInArray].quantity_package = quantity;
                                items.packages[0][isInArray].quantity = quantity;
                                this.setState({ order: items, edited: true });
                            }
                            if (isInArray === -1 && quantity > 0) {
                                items.packages[0].push({
                                    package_id: element.id,
                                    quantity_package: quantity,
                                    quantity: quantity
                                });
                                this.setState({ order: items, edited: true });
                            }
                            if (isInArray > -1 && quantity === 0) {
                                items.packages[0].splice(isInArray, 1);
                                this.setState({ order: items, edited: true });
                            }
                        }}
                        getQuantityLocationById={id => {
                            const res = this.state.order.packages[0].findIndex(
                                obj => obj.package_id === id
                            );
                            return res
                        }}
                        history={this.props.history}
                        user={this.props.user}
                        handleChange={this.handleChange}
                        handleSuspendOrder={this.handleSuspendOrder}
                    />
                );

            case 'cafe':
                return (
                    <CafeOrderReview
                        order={this.state.order}
                        edited={this.state.edited}
                        selectPackage={id => {
                            if (id === 1) {
                                const items = this.state.order;
                                items.packages = [];
                                return this.setState({
                                    order: items,
                                    edited: true
                                });
                            }
                            const items = this.state.order;
                            items.packages = [[{ package: id, quantity: 1 }]];
                            this.setState({ order: items, edited: true });
                        }}
                        handleSelectOption={this.handleSelectOption}
                        history={this.props.history}
                        user={this.props.user}
                        handleSuspendOrder={this.handleSuspendOrder}
                        handleChange={this.handleChange}
                    />
                );

            default:
                return null;
        }
    }
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user
});

export default connect(mapStateToProps)(OrderReview);
