import React, { Component } from 'react';
import Calendar from 'react-calendar';

import Button from '../../../Ui/Button';
import { fetchApi } from '../../../../Services/Network';

export default class OrdersExport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            start_date: new Date(),
            end_date: new Date()
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange = (date, type) => this.setState({ [type]: date });

    onSubmit = _ => {
        fetchApi(
            `/orders/deliveries`,
            {
                method: 'POST',
                body: JSON.stringify({
                    startDate: this.state.start_date,
                    endDate: this.state.end_date
                })
            },
            true
        )
            .then(res => res.blob())
            .then(blob => window.download(blob, 'export.csv'));
    };

    render() {
        return (
            <div className="OrderExport">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="Header">Exporter les commandes</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <p>Date de début / fin</p>
                        <Calendar
                            selectRange={true}
                            value={[this.state.start_date, this.state.end_date]}
                            onChange={e => {this.onChange(e[1], 'end_date'); this.onChange(e[0], 'start_date')}}
                        />
                    </div>
                </div>
                <div className="row" style={{ marginTop: '30px' }}>
                    <div className="col-md-12">
                        <Button onClick={this.onSubmit}>Exportation</Button>
                    </div>
                </div>
            </div>
        );
    }
}
