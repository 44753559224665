import React, { Component } from 'react';
import dateFormat from 'dateformat';

import { fetchApi } from '../../../../Services/Network';
import Pagination from 'react-paginate';

import Checkbox from '../../../Ui/Checkbox';
import SpinnerLoading from '../../../Ui/SpinnerLoading';

import '../../../../Assets/css/Pages/Admin/orders.css';
import Button from '../../../Ui/Button';
import Calendar from 'react-calendar';
import axios from "axios"
import { endpoint } from '../../../../Constants';

export default class Orders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchInput: '',
            orders: [],
            loading: true,
            sort: true,
            filtervalidate: false,
            filtertovalidate: false,
            modalOpenOrderId: null,
            newDeliveryDate: null,
            currentPage: 1,
            totalPages: 0
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onSort = this.onSort.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePostponeDelivery = this.handlePostponeDelivery.bind(this);
        this.getData = this.getData.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        fetch(`${endpoint}/api/delivery/orders_org/${localStorage.getItem('token')}?page=1`, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
            .then(response => response.json())
            .then(data => {
                this.setState({
                    orders: data.data.filter(e => e.status !== 'ABORTED'),
                    totalPages: data.total_pages,
                    loading: false
                })
            })
    }

    handlePageClick(data) {
        const selectedPage = data.selected + 1;
        fetch(`${endpoint}/api/delivery/orders_org/${localStorage.getItem('token')}?page=${selectedPage}`, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
            .then(response => response.json())
            .then(data => {
                this.setState({
                    orders: data.data.filter(e => e.status !== 'ABORTED'),
                    totalPages: data.total_pages,
                    loading: false
                })
            });
    }

    fetchClients = (page, searchInput) => {
        this.setState({ loading: true });
        let url = `${endpoint}/api/delivery/orders_org/${localStorage.getItem('token')}?page=${page}`
        if(this.state.filtertovalidate){
            url = `${endpoint}/api/delivery/orders_org/${localStorage.getItem('token')}?page=${page}&tovalidate=1`
        }
        if(this.state.filtervalidate){
            url = `${endpoint}/api/delivery/orders_org/${localStorage.getItem('token')}?page=${page}&tovalidate=2`
        }
        if(this.state.searchInput){
            url = url + `&q=${searchInput}`
        }

        fetch(url, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
            .then(response => response.json())
            .then(data => {
                this.setState({
                    orders: data.data.filter(e => e.status !== 'ABORTED'),
                    totalPages: data.total_pages,
                    loading: false
                })
            })
            .catch(error => {
                console.log(error);
                this.setState({ loading: false });
            });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.fetchClients(1, this.state.searchInput);
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value, edited: true });
    }

    onSort(sortKey) {
        const data = this.state.orders;
        if (this.state.sort) {
            data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
            this.setState({ sort: false });
        } else {
            data.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
            this.setState({ sort: true });
        }
        this.setState({ doctors: data });
    }

    handlePostponeDelivery() {
        if (this.state.newDeliveryDate === null) {
            return this.setState({
                modalOpenOrderId: null
            });
        }
        this.setState({ loading: true });
        const res = axios.put(`${endpoint}/api/delivery/orders_date/` + this.state.modalOpenOrderId + "/", {
                    next_delivery: this.state.newDeliveryDate
                },{headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}});

        if (res.status !== 200) {
            console.error('Error:', res.status);
        }

        this.setState({
            newDeliveryDate: null,
            modalOpenOrderId: null
        });
        if (this.state.searchInput){
            this.fetchClients(1, this.state.searchInput)
        }else{
            this.getData();
        }
    }

    render() {
        if (this.state.loading) {
            return <SpinnerLoading />;
        }

        const getStatusString = status => {
            switch (status) {
                case 'WAITING':
                    return 'En attente de validation';
                case 'ACTIVE':
                    return 'Active';
                case 'DISABLED':
                    return 'Suspendue';
                case 'ABORTED':
                    return 'Annulée';
                default:
                    return '';
            }
        };

        const getDateString = date => {
            if (!!date) {
                if (new Date(date).toDateString() !== new Date().toDateString()) {
                    return dateFormat(date, 'dd/mm/yyyy');
                } else {
                    return "Aujourd'hui";
                }
            } else {
                return 'Non défini';
            }
        };

        return (
            <div className="admin-orders">
                <div
                    className="modal-container"
                    style={{
                        display: this.state.modalOpenOrderId !== null ? 'block' : 'none'
                    }}
                >
                    <div className="modal">
                        <div className="modal-header">Edition de la prochaine livraison</div>
                        <div className="modal-body">
                            {(() => {
                                if (this.state.modalOpenOrderId === null) {
                                    return null;
                                }

                                const order = this.state.orders.find(({ id }) => id === this.state.modalOpenOrderId);
                                const next_delivery = order.next_delivery ? order.next_delivery : order.delivery_date;
                                const minDate = new Date(next_delivery);
                                minDate.setHours(0, 0, 0, 0);
                                minDate.setDate(minDate.getDate());

                                return (
                                    <Calendar
                                        value={this.state.newDeliveryDate || minDate}
                                        minDate={minDate}
                                        onChange={d =>
                                            this.setState({
                                                newDeliveryDate: d
                                            })
                                        }
                                    />
                                );
                            })()}
                        </div>
                        <div className="modal-footer">
                            <Button
                                style={{
                                    display: 'inline-block',
                                    marginRight: '1em'
                                }}
                                onClick={this.handlePostponeDelivery}
                            >
                                Enregistrer
                            </Button>
                            <button
                                className="link"
                                style={{ display: 'inline-block' }}
                                onClick={_ => this.setState({ modalOpenOrderId: null })}
                            >
                                Annuler
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <h1 className="Header">Nos Commandes</h1>
                    </div>
                    <div className="col-md-8">
                        <form onSubmit={this.handleSubmit} className="searchForm">
                            <input
                                type="text"
                                name="searchInput"
                                className="searchInput"
                                placeholder="Nom, société, …"
                                onChange={this.handleChange}
                                value={this.state.searchInput}
                            />
                            <Button type="submit" disabled={false} className="searchOrders">
                                    Chercher
                            </Button>
                        </form>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="col-md-6 col-sm-6 col-xs-6">
                            <div className="checkbox-line">
                                <Checkbox
                                    onChange={() => {
                                        this.setState({
                                            filtervalidate: !this.state.filtervalidate,
                                            filtercanceled: false,
                                            filtertovalidate: false
                                        });
                                    }}
                                    checked={this.state.filtervalidate}
                                />
                                <label>Commande validée</label>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-6">
                            <div className="checkbox-line">
                                <Checkbox
                                    onChange={() => {
                                        this.setState({
                                            filtertovalidate: !this.state.filtertovalidate,
                                            filtercanceled: false,
                                            filtervalidate: false
                                        });
                                    }}
                                    checked={this.state.filtertovalidate}
                                />
                                <label>Commande a valider</label>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-md-12">
                        <table className="orders-table">
                            <thead>
                                <tr className="header">
                                    <th>Client</th>
                                    <th>Site de Livraison</th>
                                    <th>Service</th>
                                    <th className="sortable" onClick={() => this.onSort('status')}>
                                        Status{' '}
                                        <i className={this.state.sort ? 'fas fa-angle-up' : 'fas fa-angle-down'} />
                                    </th>
                                    <th className="sortable" onClick={() => this.onSort('next_delivery')}>
                                        Prochaine Livraison{' '}
                                        <i className={this.state.sort ? 'fas fa-angle-up' : 'fas fa-angle-down'} />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.orders
                                    .filter(c => c.status !== 'ABORTED')
                                    .filter(
                                        order =>
                                            order.delivery_frequency !== 'promptly' ||
                                            new Date(order.next_delivery ? order.next_delivery : order.delivery_date) >= new Date()
                                    )
                                    .map(order => (
                                        <tr
                                            onClick={e => {
                                                if (e.target.tagName === 'BUTTON') {
                                                    return;
                                                }

                                                this.props.history.push(
                                                    `/orders/${order.organization.id}/${order.id}`
                                                );
                                            }}
                                            key={order.id}
                                        >
                                            <td>{order.organization.id} - {order.organization.name}</td>
                                            <td className="address">
                                                {order.delivery_location.name}
                                                <br />
                                                {order.delivery_location.address}
                                            </td>
                                            <td>{order.service.name}</td>
                                            <td>
                                                {order.delivery_frequency === 'promptly' &&
                                                new Date(order.next_delivery ? order.next_delivery : order.delivery_date) < new Date()
                                                    ? 'Livrée'
                                                    : getStatusString(order.status)}
                                            </td>
                                            <td>
                                                {getDateString(order.next_delivery)}
                                                <Button
                                                    onClick={_ =>{
                                                        this.setState({
                                                            modalOpenOrderId: order.id
                                                        })
                                                    }}
                                                    style={{
                                                        display: 'inline-block',
                                                        marginLeft: '1em'
                                                    }}
                                                >
                                                    📅
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <Pagination
                    pageCount={this.state.totalPages}
                    onPageChange={this.handlePageClick}
                    forcePage={this.state.currentPage-1}
                />
            </div>
        );
    }
}
