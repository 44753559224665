import React, { Component } from 'react';

/* COMPONENTS IMPORT */

import Button from '../../Button';
import { show } from '../../Modals';
import CalendarBox from './Components/CalendarBox';
import DeliveryLocation from '../../DeliveryLocation';

/* SERVICES IMPORT */

import { fetchApi } from '../../../../Services/Network';
import axios from "axios";
/* CSS IMPORT */
import { endpoint } from '../../../../Constants';
import '../../../../Assets/css/Ui/Tunnel/Product/ProductOrderReviewPage.css';
import Switch from '../../Switch';
import Calendar from 'react-calendar';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default class ProductOrderReviewPage extends Component {
    constructor() {
        super();

        this.state = {
            location: null,
        };
    }


    getPrice = () => {
        let price = 0;
        for (let index = 0; index < this.props.packages.length; index++) {
            for (
                let index2 = 0;
                index2 < this.props.packages[index].length;
                index2++
            ) {
                price +=
                    this.props.packages[index][index2].package.price *
                    this.props.packages[index][index2].quantity;
            }
        }
        return price;
    };

    componentDidMount() {
        axios.get(`${endpoint}/api/delivery/locations_org/${this.props.organization_id}/${localStorage.getItem('token')}`, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
            .then(res => {
                let location_to_set = res.data.find(obj => obj.id === this.props.delivery_location)
                this.setState({ location: location_to_set });
            })
            .catch(err => console.log(err));
    }

    render() {
        return (
            <div className="ProductOrderReviewPage">
                <p className="catchSentence">Parfait !</p>
                <div className="row" style={{ marginTop: '30px' }}>
                    <div className="col-md-10">
                        <h2>C'est tout bon ?</h2>
                    </div>
                    <div className="col-md-2">
                        {this.props.adminMode && this.props.serviceId == 2 && (
                                    <React.Fragment>
                                        <span className="bio-title">Panier de test</span>
                                        <Switch
                                            handleChange={e => {
                                                this.props.handleChange(e);
                                            }}
                                            name="panier_test"
                                            value={this.state.panier_test}
                                            green={true}
                                        />
                                    </React.Fragment>
                                )}
                    </div>

                </div>
                <div className="row" style={{ marginTop: '30px' }}>
                    <div className="col-md-8">
                        {!this.state.location || (
                            <DeliveryLocation
                                selected={this.props.delivery_location}
                                editable={false}
                                position={{lat: this.state.location.latitude, lng: this.state.location.longitude}}
                                {...this.state.location}
                            />
                        )}
                    </div>
                    <div className="col-md-4">
                        {(() => {
                            if (this.props.frequence === 'weekly') {
                                return (
                                    <div className="frequence-box">
                                        <img
                                            src="/assets/img/rec-hebdo.svg"
                                            alt="Selection Hebdo"
                                        />
                                        <p>Toutes les semaines</p>
                                    </div>
                                );
                            }
                            if (this.props.frequence === 'fortnightly') {
                                return (
                                    <div className="frequence-box">
                                        <img
                                            src="/assets/img/rec-bimens.svg"
                                            alt="Selection bimens"
                                        />
                                        <p>Tous les 15 jours</p>
                                    </div>
                                );
                            }
                            if (this.props.frequence === 'monthly') {
                                return (
                                    <div className="frequence-box">
                                        <img
                                            src="/assets/img/rec-mens.svg"
                                            alt="Selection mens"
                                        />
                                        <p>Tous les mois</p>
                                    </div>
                                );
                            }
                            if (this.props.frequence === 'promptly') {
                                return (
                                    <div className="frequence-box">
                                        <img
                                            src="/assets/img/rec-pnct.svg"
                                            alt="Selection pnct"
                                        />
                                        <p>Une seule fois</p>
                                    </div>
                                );
                            }
                        })()}
                    </div>
                </div>
                <div className="row" style={{ marginTop: '10px' }}>
                    <div className="col-md-12 calendarbox-slider">
                        {(() => {
                            const result = [];
                            const daytime = [
                                'Lundi',
                                'Mardi',
                                'Mercredi',
                                'Jeudi',
                                'Vendredi'
                            ];
                            for (
                                let index = 0;
                                index < this.props.packages.length;
                                index++
                            ) {
                                for (
                                    let index2 = 0;
                                    index2 < this.props.packages[index].length;
                                    index2++
                                ) {
                                    result.push(
                                        <CalendarBox
                                            key={
                                                index +
                                                this.props.packages[index][
                                                    index2
                                                ].package.id +
                                                index2
                                            }
                                            price={
                                                this.props.packages[index][
                                                    index2
                                                ].package.price
                                            }
                                            day={
                                                this.props.frequence ===
                                                'promptly'
                                                    ? null
                                                    : daytime[index]
                                            }
                                            name={
                                                this.props.packages[index][
                                                    index2
                                                ].package.name
                                            }
                                            description={
                                                this.props.packages[index][
                                                    index2
                                                ].package.description
                                            }
                                            quantity={
                                                this.props.packages[index][
                                                    index2
                                                ].quantity
                                            }
                                            handleQuantityChange={quantity => {
                                                this.props.handleQuantityChange(
                                                    index,
                                                    index2,
                                                    quantity
                                                );
                                            }}
                                            noDelete={true}
                                        />
                                    );
                                }
                            }
                            return result;
                        })()}
                    </div>
                    {this.props.adminMode && this.props.frequence !== "promptly" &&  (
                    <div className="row" style={{ marginTop: '24px', marginLeft: '2px' }}>
                        <div className="col-md-5">
                            <h4 className="black">Choisissez la date de fin d'abonnement</h4>
                            <DatePicker
                              selected={this.props.subscription_end_date ? new Date(this.props.subscription_end_date) : null}
                              onChange={e =>
                                    this.props.handleChange({
                                        target: {
                                            name: 'subscription_end_date',
                                            value: e
                                        }
                                    })
                                }
                              dateFormat="dd/MM/yyyy"
                              minDate={new Date()}
                              placeholderText="Select a date"
                            />
                        </div>
                    </div>
                    )}
                </div>
                <div className="row" style={{ marginTop: '30px' }}>
                    <div
                        className="col-md-6"
                        style={{ paddingLeft: '8px', paddingRight: '8px' }}
                    >
                        <Button
                            onClick={this.props.handleSubmit}
                            disabled={this.props.packages.every(
                                element => element.length === 0
                            )}
                            style={{ display: 'inline-block' }}
                        >
                            Valider ma commande
                        </Button>
                        {this.props.estimateLink ? (
                            <a
                                href={this.props.estimateLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                download="devis.pdf"
                            >
                                <Button onClick={() => {}} className="bordered">
                                    Télécharger le devis
                                </Button>
                            </a>
                        ) : (
                            <Button
                                onClick={this.props.handleCreateEstimate}
                                loading={this.props.loading}
                                disabled={this.props.packages.every(
                                    element => element.length === 0
                                )}
                                className="bordered"
                            >
                                Recevoir mon devis par mail
                            </Button>
                        )}
                    </div>
                    <div
                        className="col-md-6"
                        style={{ paddingLeft: '8px', paddingRight: '8px' }}
                    >
                        <p className="price">
                            Total — {this.getPrice()}€ HT / semaine <br />
                            <span className="price-month">
                                Environ {this.getPrice() * 4} € HT / mois{' '}
                                <button
                                    href="#"
                                    onClick={() =>
                                        show(
                                            'Le montant de votre abonnement est calculé sur 4 semaines mais peut légèrement évoluer. Sur Les Plaisirs Fruites, la facturation se fait du 1er de chaque mois au dernier jour du mois. En fonction des mois il peut y avoir 4 ou 5 Lundis, Mardi etc. Le montant de votre facture peut alors un peu évoluer en fonction de ces paramètres.',
                                            'info',
                                            () => {
                                                console.log('close');
                                            },
                                            () => {
                                                console.log('close');
                                            }
                                        )
                                    }
                                >
                                    Pourquoi ?
                                </button>
                            </span>
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <p className="billing-detail">
                            Service facturé tous les mois et sans engagement
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
