import React from 'react';
import Button from '../../Ui/Button';
import { fetchApi } from '../../../Services/Network';
import { show } from '../../Ui/Modals';
import addNotification from '../../Ui/Notifications';
import axios from "axios"
import { endpoint } from '../../../Constants';

export default class ValidationButtons extends React.Component {
    constructor() {
        super();

        this.handleAbortOrder = this.handleAbortOrder.bind(this);
        this.handleReactivateOrder = this.handleReactivateOrder.bind(this);
    }

    checkLen(packages) {
        let empty = true
        Object.values(packages).map(e =>{
            if(e.length > 0){
                empty = false
            }
        })
        return empty
    }
    handleAbortOrder(organization, order, isAdmin = false) {
        if (this.props.order.status === 'ABORTED') {
            return window.alert('Abonnement déjà annulé');
        }

        show('Êtes-vous sûrs de vouloir annuler cet abonnement ?', 'delete', _ => {
            axios.put(`${endpoint}/api/delivery/order_suspend/` +
                this.props.order.organisation + "/" + this.props.order.id + `/${localStorage.getItem('token')}`, {
                            status: 'ABORTED',
                        }, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
            .then(_ => {
                addNotification(
                    isAdmin
                        ? 'Abonnement annulé'
                        : 'Notre équipe va prendre contact avec vous rapidement pour traiter votre demande',
                    'success'
                );
                this.props.history.replace(isAdmin ? `/customer/${this.props.order.organisation}` : '/');
            });
        });
    }

    handleReactivateOrder(isAdmin) {
        show(
            "Voulez-vous vraiment réactiver cet abonnement ? Votre abonnement sera soumis à une validation de la part de l'équipe Les Plaisirs Fruites.",
            'info',
            _ => {
                axios.put(`${endpoint}/api/delivery/order_statut_edit/` + this.props.order.id + "/", { statut: 'ACTIVE' }, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
                .then(_ => {
                    addNotification('Abonnement repris', 'success');

                    this.props.history.replace(isAdmin ? `/customer/${this.props.order.organisation}` : '/');
                });
            }
        );
    }

    render() {
        if (this.props.user.role === 'Client') {
            return (
                <div>
                    <Button
                        onClick={() => {
                            if (this.props.order.status === 'ABORTED' && this.props.edited) {
                                return window.alert('Impossible de modifier un abonnement annulé');
                            }

                            if (this.props.order.status === 'WAITING') {
                                // Not validated yet
                                if (!this.props.edited) {
                                    this.props.history.goBack();
                                }
                                return;
                            } else if (this.props.order.status === 'DISABLED') {
                                // Reactivate
                                return this.handleReactivateOrder(false);
                            } else if (this.props.edited) {
                                if(this.props.order.service.type == "cafe"){
                                    axios.put(`${endpoint}/api/delivery/order_edit/` + this.props.order.organisation + "/" + this.props.order.id + `/${localStorage.getItem('token')}`,
                                    {
                                            packages: this.props.order.packages,
                                            options: this.props.order.option,
                                            delivery_frequency: this.props.order.delivery_frequency,
                                            delivery_date: this.props.order.delivery_date,
                                            start_date: this.props.order.start_date
                                    }, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}}).then(() => this.props.history.goBack())
                                    .catch(err => {
                                      addNotification('Erreur lors de la modification', 'danger');
                                    });
                                }else{
                                    let delivery_date = this.props.order.delivery_date;
                                    if(this.props.order.delivery_frequency === "promptly" && typeof delivery_date !== typeof ""){
                                        const day = String(delivery_date.getDate()).padStart(2, "0");
                                        const month = String(delivery_date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1.
                                        const year = delivery_date.getFullYear();
                                        delivery_date = `${year}-${month}-${day}`;
                                    }
                                    axios.put(`${endpoint}/api/delivery/order_edit/` + this.props.order.organisation + "/" + this.props.order.id + `/${localStorage.getItem('token')}`,
                                    {
                                            packages: this.props.order.packages,
                                            //options: this.props.order.options,
                                            planning: Object.values(this.props.order.packages).map(day =>
                                                day.map(p => ({
                                                    package: p.package_id,
                                                    quantity: p.quantity
                                                }))
                                            ),
                                            delivery_frequency: this.props.order.delivery_frequency,
                                            delivery_date: delivery_date,
                                            start_date: this.props.order.start_date
                                    }, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}}).then(() => this.props.history.goBack())
                                    .catch(err => {
                                      addNotification('Erreur lors de la modification', 'danger');
                                    });
                                }


                            } else {
                                this.props.history.goBack();
                            }
                        }}
                        disabled={
                            this.checkLen(this.props.order.packages) === true ||
                            (this.props.edited && this.props.order.status === 'WAITING')
                        }
                    >
                        {(_ => {
                            if (this.props.edited) {
                                if (this.props.order.status === 'WAITING') {
                                    return 'En attente de validation';
                                } else if (this.props.order.status === 'DISABLED') {
                                    return "Réactiver l'abonnement";
                                } else {
                                    return 'Modifier';
                                }
                            } else {
                                if (this.props.order.status === 'DISABLED') {
                                    return "Réactiver l'abonnement";
                                } else {
                                    return 'Retour';
                                }
                            }
                        })()}
                    </Button>
                </div>
            );
        } else {
            return (
                <div>
                    <Button
                        onClick={() => {
                            if (this.props.order.status === 'ABORTED' && this.props.edited) {
                                return window.alert('Impossible de modifier un abonnement annule');
                            }

                            if (!this.props.edited && this.props.order.status === 'WAITING') {
                                // Validation
                                axios.put(`${endpoint}/api/delivery/order_statut_edit/` + this.props.order.id + "/", {statut: 'ACTIVE', new_order: true}, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
                                .then(() => {
                                    addNotification('Abonnement validé', 'success');
                                    this.props.history.replace(`/customer/${this.props.order.organisation}`);
                                });
                            } else if (this.props.order.status === 'DISABLED') {
                                // Reactivate
                                return this.handleReactivateOrder(true);
                            } else if (this.props.edited) {
                                if(this.props.order.service.type == "cafe"){
                                    axios.put(`${endpoint}/api/delivery/order_edit/` + this.props.order.organisation + "/" + this.props.order.id + `/${localStorage.getItem('token')}`,
                                    {
                                            packages: this.props.order.packages,
                                            options: this.props.order.option,
                                            delivery_frequency: this.props.order.delivery_frequency,
                                            delivery_date: this.props.order.delivery_date,
                                            start_date: this.props.order.start_date
                                    }, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}}).then(() => {
                                    addNotification('Abonnement modifié', 'success');
                                    this.props.history.replace(`/customer/${this.props.order.organisation}`);
                                })
                                .catch(err => {
                                      addNotification('Erreur lors de la modification', 'danger');
                                    });
                                }else{
                                    let delivery_date = this.props.order.delivery_date;
                                    if(this.props.order.delivery_frequency === "promptly" && typeof delivery_date !== typeof ""){
                                        const day = String(delivery_date.getDate()).padStart(2, "0");
                                        const month = String(delivery_date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1.
                                        const year = delivery_date.getFullYear();
                                        delivery_date = `${year}-${month}-${day}`;
                                    }
                                    axios.put(`${endpoint}/api/delivery/order_edit/` + this.props.order.organisation + "/" + this.props.order.id + `/${localStorage.getItem('token')}`, {
                                            packages: this.props.order.packages,
                                            //options: this.props.order.options,
                                            planning: Object.values(this.props.order.packages).map(day =>
                                                day.map(p => ({
                                                    package: p.package_id,
                                                    quantity: p.quantity
                                                }))
                                            ),
                                            subscription_end_date : this.props.order.subscription_end_date,
                                            delivery_frequency: this.props.order.delivery_frequency,
                                            delivery_date: delivery_date,
                                            start_date: this.props.order.start_date
                                    }, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}}).then(() => {
                                        addNotification('Abonnement modifié', 'success');
                                        this.props.history.replace(`/customer/${this.props.order.organisation}`)
                                })
                                .catch(err => {
                                      addNotification('Erreur lors de la modification', 'danger');
                                    });
                                }

                            } else {
                                this.props.history.goBack();
                            }
                        }}
                    >
                        {(_ => {
                            if (this.props.edited && this.props.order.status === 'WAITING') {
                                return 'Modifier & Valider';
                            }
                            if (this.props.edited) {
                                return 'Modifier';
                            }
                            if (this.props.order.status === 'WAITING') {
                                return 'Valider';
                            } else if (this.props.order.status === 'DISABLED') {
                                return "Réactiver l'abonnement";
                            }

                            return 'Retour';
                        })()}

                    </Button>
                    <span
                        className="unsuscribe"
                        onClick={_ => this.handleAbortOrder(this.props.order.organization, this.props.order.id, true)}
                    >
                        {this.props.order.status === 'WAITING' ? 'Annuler l’abonnement' : 'Resilier l’abonnement'}
                    </span>
                </div>
            );
        }
    }
}
