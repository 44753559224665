import React, { Component } from 'react';
import { connect } from 'react-redux';

import Input from './Input';
import Button from './Button';
import addNotification from './Notifications';

import { feedUserMeta } from '../../Redux/Actions';
import { fetchApi } from '../../Services/Network';
import { getErrorFromCode } from '../../Constants';

import '../../Assets/css/Ui/FirstConnectionModal.css';

const passwdValidator = new RegExp(
    /(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}/
);

class FirstConnectionModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            newPassword: '',
            newPasswordC: '',
            loading: false
        };

        this.errors = {};

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();

        /* Handler */
        const { password, newPassword } = this.state;

        this.setState({ loading: true });
        fetchApi(`/auth/${this.props.user.id}/password`, {
            method: 'PUT',
            body: JSON.stringify({
                password,
                newPassword
            })
        })
            .then(() => {
                this.setState({
                    loading: false
                });

                const newUser = {
                    ...this.props.user,
                    last_connection: new Date().toDateString()
                };

                this.props.feedUserMeta(newUser);

                addNotification('Votre mot de passe a bien été modifié ! 👍');
            })
            .catch(err => {
                if (err.code === 400) {
                    Object.keys(err.body).forEach(k => {
                        this.errors[k] = err.body[k];
                    });
                }

                if (err.code === 403) {
                    this.setState({ error: 'PASSWORD_NOT_MATCHING' });
                }

                this.setState({ loading: false });
            });
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    isFormValid() {
        // Checks that each field is not blank
        const toCheck = {
            password: this.state.password,
            newPassword: this.state.newPassword
        };

        return (
            Object.keys(toCheck).every(k => this.state[k].trim().length > 0) &&
            this.state.newPassword === this.state.newPasswordC &&
            passwdValidator.test(this.state.newPassword)
        );
    }

    render() {
        const buttonDisabled = !this.isFormValid();

        let error = null;

        if (this.state.error) {
            error = (
                <div className="errorToast" style={{ marginBottom: '12px' }}>
                    {getErrorFromCode(this.state.error)
                        .split('\n')
                        .map((b, i) => (
                            <span key={i} style={{ display: 'block' }}>
                                {b}
                            </span>
                        ))}
                </div>
            );
        }

        return (
            <div className="firstConnectionModal">
                <div className="windows">
                    <h2>
                        C’est votre premiere connexion, choisissez un mot passe
                        qui vous convient !{' '}
                        <span role="img" aria-label="emoji">
                            🙂
                        </span>
                    </h2>
                    {error}
                    <div className="row">
                        <div className="col-md-12">
                            <Input
                                type="password"
                                placeholder="Mot de passe transmis par mail"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.password}
                                name="password"
                                label="Mot de passe transmis par mail"
                                errors={this.errors}
                            />
                        </div>
                    </div>
                    <form onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-md-12">
                                <Input
                                    type="password"
                                    placeholder="Nouveau mot de passe"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.newPassword}
                                    name="newPassword"
                                    label="Nouveau mot de passe"
                                    infoLabel="8 caractères minimum — dont : 1 majuscule, 1 chiffre, 1 caractère spécial (#@&amp;%*^ ...)"
                                    greenLabel="8 caractères minimum — dont : 1 majuscule, 1 chiffre, 1 caractère spécial (#@&amp;%*^ ...)"
                                    greenLabelValid={passwdValidator.test(
                                        this.state.newPassword
                                    )}
                                    errors={this.errors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Input
                                    type="password"
                                    placeholder="Confirmez votre mot de passe"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.newPasswordC}
                                    name="newPasswordC"
                                    label="Confirmation"
                                    errors={this.errors}
                                />
                            </div>
                        </div>
                        <Button
                            disabled={buttonDisabled}
                            loading={this.state.loading}
                        >
                            Valider
                        </Button>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user
});

const mapDispatchToProps = dispatch => ({
    feedUserMeta: user => dispatch(feedUserMeta(user))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FirstConnectionModal);
