import React from 'react';
import '../../../../Assets/css/Pages/accountSettings.css';

import Quickbooks from './Quickbooks';
import Stats from './Stats';

const SettingsSideMenu = props => {
            //<button
            //    onClick={() => props.onClick(1)}
            //    className={props.activeSetting === 1 ? 'Id active' : 'Id'}
            //>
            //    <span>Statistiques</span>
            //</button>
    return (
        <div className="sidemenu">
            <button
                onClick={() => props.onClick(0)}
                className={props.activeSetting === 0 ? 'Facturation active' : 'Facturation'}
            >
                <span>Pennylane</span>
            </button>
        </div>
    );
};

class AdminSettings extends React.Component {
    constructor() {
        super();

        this.state = {
            activeSetting: 0
        };
    }

    render() {
        return (
            <div className="AccountSettings">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="Header">Paramètres de l'application</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <SettingsSideMenu
                            onClick={index => {
                                this.setState({ activeSetting: index });
                            }}
                            activeSetting={this.state.activeSetting}
                        />
                    </div>
                    <div className="col-md-8">
                        {(() => {
                            if (this.state.activeSetting === 0) {
                                return (
                                   <Quickbooks />
                                );
                            }
                            if (this.state.activeSetting === 1) {
                                return (
                                   <Stats />
                               );
                            }
                        })()}
                    </div>
                </div>
            </div>
        );
    }
}

export default AdminSettings;
