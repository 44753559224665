import React from 'react';
import PropTypes from 'prop-types';
import { getErrorFromCode } from '../../Constants';

class TextArea extends React.Component {
    constructor() {
        super();

        this.state = {
            focus: false
        };

        this.handleFocus = this.handleFocus.bind(this);
        this.handleFocusOut = this.handleFocusOut.bind(this);
    }

    componentDidMount() {
        this.refs.input.addEventListener('focus', this.handleFocus);
        this.refs.input.addEventListener('focusout', this.handleFocusOut);
    }

    componentWillUnmount() {
        this.refs.input.removeEventListener('focus', this.handleFocus);
        this.refs.input.removeEventListener('focusout', this.handleFocusOut);
    }

    handleFocus() {
        this.setState({ focus: true });
    }

    handleFocusOut() {
        this.setState({ focus: false });
    }

    render() {
        let error = null;
        let greenLabel = null;

        if (this.props.errors[this.props.name]) {
            error = (
                <span className="error">
                    {getErrorFromCode(this.props.errors[this.props.name])}
                </span>
            );
        }

        if (this.props.greenLabelValid && this.props.greenLabel) {
            greenLabel = (
                <span className="greenLabel">{this.props.greenLabel}</span>
            );
        } else if (this.props.greenLabel) {
            greenLabel = (
                <span className="greenLabel invalid">{this.props.greenLabel}</span>
            );
        }

        return (
            <div className="InputComp form-group">
                <label
                    htmlFor={this.props.name}
                    className={
                        (this.props.errors[this.props.name] ? 'error' : '') +
                        (this.state.focus ? ' focus' : '')
                    }
                    style={this.props.labelStyle}
                >
                    {this.props.label}
                    {this.props.children}
                </label>
                <textarea
                    ref="input"
                    className={
                        this.props.errors[this.props.name]
                            ? 'form-control error'
                            : 'form-control'
                    }
                    name={this.props.name}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    rows={this.props.rows || 2}
                    autoComplete="off"
                    placeholder={this.props.placeholder}
                    style={{resize: 'none'}}
                />
                {error}
                {greenLabel}
            </div>
        );
    }
}

TextArea.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    rows: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    error: PropTypes.object
};

export default TextArea;
