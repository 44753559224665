/* LIBRARIES IMPORT */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

/* SERVICES IMPORT */

import { fetchApi } from '../../../Services/Network';
import { endpoint } from '../../../Constants';
import axios from "axios";
/* COMPONENTS IMPORT */

import StartPage from '../../Ui/Tunnel/General/StartPage';
import DeliveryAddressSelection from '../../Ui/Tunnel/General/DeliveryAddressSelection';
import FrequenceSelection from '../../Ui/Tunnel/Product/FrequenceSelection';
import FrequenceCalendar from '../../Ui/Tunnel/Product/FrequenceCalendar';
import ProductOrderReviewPage from '../../Ui/Tunnel/Product/ProductOrderReviewPage';
import FrequencePromptly from '../../Ui/Tunnel/Product/FrequencePromptly';
import addNotification from '../../Ui/Notifications';

/* CSS IMPORT */

class ProductTunnel extends Component {
    constructor() {
        super();

        this.state = {
            delivery_location: null,
            delivery_frequency: null,
            delivery_date: null,
            packages: [[], [], [], [], []],
            estimateLink: null,
            loading: false,
            panier_test: false,
            subscription_end_date: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handlePlaceOrder = this.handlePlaceOrder.bind(this);
        this.handleCreateEstimate = this.handleCreateEstimate.bind(this);
    }

    handleCreateEstimate() {
        const packages = [];

        for (let index = 0; index < this.state.packages.length; index++) {
            for (const element of this.state.packages[index]) {
                packages.push(element);
            }
        }

        const headers = {
            'Content-Type': 'application/json'
        };

        this.setState({ loading: true });

        axios.post(`${endpoint}/api/delivery/estimate_api/${this.props.organization_id}/`, {
                serviceId: this.props.service.id,
                delivery_frequency: this.state.delivery_frequency,
                packages,
                deliveryId: this.state.delivery_location,
                delivery_date: this.state.delivery_date
            }, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
            .then(res => {
                this.setState({ loading: false, estimateLink: res.data });
            })
            .catch(err => console.error(err));
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handlePlaceOrder() {
        axios.post(`${endpoint}/api/delivery/place_order/${this.props.organization_id}/${localStorage.getItem('token')}`,
        {
                delivery_location: this.state.delivery_location,
                service: this.props.service.id,
                planning: this.state.packages.map(plan =>
                    plan.map(p => ({
                        package: p.package.id,
                        quantity: p.quantity
                    }))
                ),
                delivery_date: this.state.delivery_date == null ? new Date() : this.state.delivery_date,
                delivery_frequency: this.state.delivery_frequency,
                panier_test: this.state.panier_test,
                subscription_end_date: this.state.subscription_end_date
            }, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}}
        ).then(res => {
            if (this.props.adminMode) {
                addNotification('Votre commande a bien été créé !');
                return this.props.history.push(
                    `/customer/${res.data.organisation}`
                );
            } else {
                addNotification('Votre commande a bien été créé !');
                return this.props.history.push('/');
            }
        });
    }

    render() {
        console.log(this.state)
        switch (this.props.currentPage) {
            case 0:
                return (
                    <StartPage
                        title={this.props.service.homepageTitle}
                        description={this.props.service.homepageDescription}
                        handleNextPage={this.props.handleNextPage}
                    />
                );
            case 1:
                return (
                    <DeliveryAddressSelection
                        handleNextPage={this.props.handleNextPage}
                        delivery_location={this.state.delivery_location}
                        setDeliveryLocation={id => {
                            this.setState({ delivery_location: id });
                            this.props.setNextDisabled(false);
                        }}
                        organization_id={this.props.organization_id}
                    />
                );
            case 2:
                return (
                    <FrequenceSelection
                        handleNextPage={this.props.handleNextPage}
                        available_frequencies={
                            this.props.service.available_frequencies
                        }
                        frequence={this.state.delivery_frequency}
                        setFrequence={freq => {
                            this.setState({
                                delivery_frequency: freq,
                                packages:
                                    freq === 'promptly'
                                        ? [[]]
                                        : [[], [], [], [], []]
                            });

                            this.props.setNextDisabled(false);
                        }}
                    />
                );
            case 3:
                if (this.state.delivery_frequency === 'promptly') {
                    return (
                        <FrequencePromptly
                            service={this.props.service}
                            handleNextPage={this.props.handleNextPage}
                            delivery_date={this.state.delivery_date}
                            handleChange={this.handleChange}
                            servicePackages={this.props.service.packages}
                            activePackages={this.state.packages}
                            addPackages={(day, newPackages) => {
                                const items = this.state.packages;
                                items[day] = newPackages;
                                if (items[day].length > 0) {
                                    this.props.setNextDisabled(false);
                                } else {
                                    this.props.setNextDisabled(true);
                                }
                                this.setState({ packages: items });
                            }}
                            handleQuantityChange={(day, index, quantity) => {
                                const items = this.state.packages;
                                items[day][index].quantity = quantity;
                                this.setState({
                                    packages: items
                                });
                            }}
                            handleDelete={(day, index) => {
                                const items = this.state.packages;
                                items[day].splice(index, 1);
                                this.setState({
                                    packages: items
                                });
                                if (items[day].length > 0) {
                                    this.props.setNextDisabled(false);
                                } else {
                                    this.props.setNextDisabled(true);
                                }
                            }}
                            handleReset={() => {
                                this.setState({ packages: [[]] });
                                this.props.setNextDisabled(true);
                            }}
                            adminMode={this.props.adminMode}
                        />
                    );
                }
                return (
                    <FrequenceCalendar
                        handleNextPage={this.props.handleNextPage}
                        servicePackages={this.props.service.packages}
                        addPackages={(day, newPackages) => {
                            const items = this.state.packages;
                            items[day] = newPackages;

                            let nbItems = 0;

                            for (const d of items) {
                                nbItems += d.length;
                            }

                            if (nbItems > 0) {
                                this.props.setNextDisabled(false);
                            } else {
                                this.props.setNextDisabled(true);
                            }

                            this.setState({ packages: items });
                        }}
                        packages={this.state.packages}
                        handleQuantityChange={(day, index, quantity) => {
                            const items = this.state.packages;
                            items[day][index].quantity = quantity;
                            items[day][index].quantity_package = quantity;
                            this.setState({
                                packages: items
                            });
                        }}
                        handleDelete={(day, index) => {
                            const items = this.state.packages;
                            items[day].splice(index, 1);
                            this.setState({
                                packages: items
                            });

                            let nbItems = 0;

                            for (const d of items) {
                                nbItems += d.length;
                            }

                            if (nbItems > 0) {
                                this.props.setNextDisabled(false);
                            } else {
                                this.props.setNextDisabled(true);
                            }
                        }}
                        handleReset={() => {
                            this.setState({ packages: [[], [], [], [], []] });
                            this.props.setNextDisabled(true);
                        }}
                    />
                );
            case 4:
                return (
                        <ProductOrderReviewPage
                        organization_id={this.props.organization_id}
                        delivery_location={this.state.delivery_location}
                        frequence={this.state.delivery_frequency}
                        packages={this.state.packages}
                        handleQuantityChange={(day, index, quantity) => {
                            const items = this.state.packages;
                            items[day][index].quantity = quantity;
                            this.setState({
                                packages: items
                            });
                        }}
                        handleDelete={(day, index) => {
                            const items = this.state.packages;
                            items[day].splice(index, 1);
                            this.setState({
                                packages: items
                            });
                        }}
                        handleChange={this.handleChange}
                        handleSubmit={this.handlePlaceOrder}
                        handleCreateEstimate={this.handleCreateEstimate}
                        loading={this.state.loading}
                        estimateLink={this.state.estimateLink}
                        adminMode={this.props.adminMode}
                        serviceId={this.props.service.id}
                        subscription_end_date={this.state.subscription_end_date}
                    />
                );
            default:
                return null;
        }
    }
}

export default withRouter(ProductTunnel);
