import React, { Component } from 'react';

/* COMPONENTS IMPORT */

import Button from '../../Button';
import FrequenceSelector from './Components/FrequenceSelector';

/* CSS IMPORT */

import '../../../../Assets/css/Ui/Tunnel/Product/FrequenceSelection.css';

export default class FrequenceSelection extends Component {
    render() {
        return (
            <div className="FrequenceSelection">
                <div>
                    <p className="catchSentence">Super ! Une belle équipe</p>
                    <h2>
                        À quelle fréquence souhaitez-vous vous faire livrer ?
                    </h2>
                </div>

                <div className="frequenceSelector-container">
                    <FrequenceSelector
                        available_frequencies={this.props.available_frequencies}
                        frequenceSelected={this.props.frequence}
                        setFrequence={this.props.setFrequence}
                    />
                </div>

                <Button
                    className="mt-24"
                    onClick={this.props.handleNextPage}
                    disabled={this.props.frequence === null}
                >
                    Suivant
                </Button>
            </div>
        );
    }
}
