import React, { Component } from 'react';

export default class TunnelPosition extends Component {
    render() {
        let buffer = [];

        for (let index = 0; index < this.props.nbStates; index++) {
            if (index === this.props.actualState) {
                buffer.push(<div key={index} className="rec" />);
            } else {
                buffer.push(<div key={index} className="plot" />);
            }
        }

        return (
            <div className="tunnelPosition">
                <button
                    onClick={this.props.onPrev}
                    disabled={this.props.prevDisabled}
                >
                    <i className="fa fa-arrow-up" />
                </button>
                {buffer}
                <button
                    onClick={this.props.onNext}
                    disabled={this.props.nextDisabled}
                >
                    <i className="fa fa-arrow-down" />
                </button>
            </div>
        );
    }
}
