import React, { Component } from 'react';

/* COMPONENTS IMPORT */

import ProductSelection from './ProductSelection';
import Button from '../../../Button';

/* CSS IMPORT */

import '../../../../../Assets/css/Ui/Tunnel/Product/Components/ProductSelectionModal.css';

export default class ProductSelectionModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedPackages: []
        };
    }

    componentDidMount() {
        if (this.props.packages.length !== 0) {
            const items = [...this.props.packages[this.props.selectedDay]];
            this.setState({ selectedPackages: items });
        }
    }

    render() {
        return (
            <div
                className="ProductSelectionModal"
                style={{ display: `${this.props.show ? 'block' : 'none'}` }}
            >
                <div className="modal-content">
                    <button className="close-button" onClick={() =>this.props.handleClose()}>
                        <i className="fas fa-times" />
                    </button>
                    <div className="row">
                        {(() => {
                            return this.props.servicePackages.map(
                                (Servpackage, index) => {
                                    /*if (index % 4 === 0) {
                                        return (
                                            <div>
                                                <div className="divider col-md-12" />
                                                <div className="col-md-3">
                                                    <ProductSelection
                                                        key={index}
                                                        package={Servpackage}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    }*/

                                    const isInArray = this.state.selectedPackages.findIndex(
                                        obj =>
                                            obj.package.id === Servpackage.id
                                    );

                                    return (
                                        <div className="col-md-3" key={index}>
                                            <ProductSelection
                                                package={Servpackage}
                                                onClick={() => {
                                                    const items = [
                                                        ...this.state
                                                            .selectedPackages
                                                    ];
                                                    if (isInArray === -1) {
                                                        items.push({
                                                            package: Servpackage,
                                                            quantity: 1
                                                        });
                                                        this.setState({
                                                            selectedPackages: items
                                                        });
                                                    } else {
                                                        items.splice(
                                                            isInArray,
                                                            1
                                                        );
                                                        this.setState({
                                                            selectedPackages: items
                                                        });
                                                    }
                                                }}
                                                selected={isInArray > -1}
                                                quantity={
                                                    isInArray > -1
                                                        ? this.state
                                                              .selectedPackages[
                                                              isInArray
                                                          ].quantity
                                                        : null
                                                }
                                                handleQuantityChange={quantity => {
                                                    const items = [
                                                        ...this.state
                                                            .selectedPackages
                                                    ];
                                                    items[
                                                        isInArray
                                                    ].quantity = quantity;
                                                    items[
                                                        isInArray
                                                    ].quantity_package = quantity;
                                                    this.setState({
                                                        selectedPackages: items
                                                    });
                                                }}
                                            />
                                        </div>
                                    );
                                }
                            );
                        })()}
                    </div>
                    <Button
                        style={{ textAlign: 'center' }}
                        className="mt-24"
                        onClick={() => {
                            this.props.addPackages(
                                this.props.selectedDay,
                                this.state.selectedPackages
                            );
                            this.props.handleClose();
                        }}
                        disabled={this.state.selectedPackages.length === 0}
                    >
                        Valider ma sélection
                    </Button>
                </div>
            </div>
        );
    }
}
