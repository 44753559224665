import React from 'react';
import { fetchApi } from '../../Services/Network';
import { connect } from 'react-redux';

import { FruitsSubsContainer, SubsFruit } from '../Ui/Fruits';
import SpinnerLoading from '../Ui/SpinnerLoading';
import Button from '../Ui/Button';
import axios from "axios";
import { endpoint } from '../../Constants';
import { FullFruit } from '../Ui/Fruits';
import CompleteAccount from '../Ui/CompleteAccount';

class Subscriptions extends React.Component {
    constructor() {
        super();

        this.state = {
            subscriptions: [],
            services: [],
            orders: [],
            loading: true
        };
    }

    componentDidMount() {
        axios.get(`${endpoint}/api/delivery/orders_client/${localStorage.getItem('token')}`, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}}).then(res => {
            const subscriptions = [];
                    subscriptions.push(
                        ...res.data
                            .filter(({ status }) => status !== 'ABORTED')
                            .filter(
                                ({ delivery_frequency, delivery_date }) =>
                                    !(
                                        delivery_frequency === 'promptly' &&
                                        new Date(delivery_date).setHours(
                                            23,
                                            59,
                                            59
                                        ) < new Date()
                                    )
                            )
                    );
            this.setState({
                subscriptions,
                loading: false
            });
        });
        axios.get(`${endpoint}/api/service/`).then(services =>
          this.setState({ services: services.data })
        );
    }

    /*componentDidMount() {
        axios.get("${endpoint}/api/delivery/orders_org").then(res => {
            console.log(res.data)
            const subscriptions = [];
            // subscriptions.push(res.data);
            this.setState({
                subscriptions,
                loading: false
        });
        })

    }*/
    render() {

        if (this.state.loading) {
            return <SpinnerLoading />;
        }

        if (this.state.subscriptions.length === 0) {
            return (
                <div className="row">
	                {(() => {
			          if (!this.props.user.accountCompleted) {
			            return <CompleteAccount />;
			          }
			        })()}
                    <div className="col-md-12" style={{marginTop: '25px'}}>
                        <h1 className="Header">Mes Abonnements</h1>
                        <span className="noSubs">
                            Vous n’avez aucun Service…{' '}
                            <span role="img" aria-label="sad">
                                😭
                            </span>
                        </span>
                        <a href="/">
                            <Button style={{textAlign: 'center'}} className="mt-24">Découvrez nos services</Button>
                        </a>
                    </div>
                    <div className="col-md-12" style={{marginTop: '50px'}}>
                      <h2 className="grey0 mt-24 mb-24">
                        Decouvrez tous les services Pepp’r !
                      </h2>
                      <div className="row fullfruit-container">
                        {this.state.services.map(s => (
                          <div key={s.tag_id} className="col-md-3">
                            <FullFruit
                              title={s.catch_phrase}
                              product={s.name}
                              src={s.image}
                              onClick={() => this.props.history.push('/tunnel/' + s.id)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                </div>

            );
        }

        return (
            <div className="row">
            	{(() => {
                    if (!this.props.user.accountCompleted) {
                        return <CompleteAccount />;
                    }
                })()}
                <div className="col-md-12">
                    <h1 className="Header">Mes Abonnements</h1>
                    <FruitsSubsContainer>
                        {this.state.subscriptions.map(s => (
                            <SubsFruit
                                key={s.id}
                                {...s}
                                oid={this.props.user.organization.id}
                            />
                        ))}
                    </FruitsSubsContainer>
                </div>
	            <div className="col-md-12">
	                <h2 className="grey0 mt-24 mb-24" style={{marginTop: "40px"}}>
	                    Decouvrez tous les services Pepp’r !
	                </h2>
	                <div className="row fullfruit-container">
	                   	{this.state.services.map(s => (
	                    <div key={s.tag_id} className="col-md-3">
	                        <FullFruit
	                          title={s.catch_phrase}
	                          product={s.name}
	                          src={s.image}
	                          onClick={() => this.props.history.push('/tunnel/' + s.id)}
	                        />
	                     </div>
	                    ))}
	                </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user
});

export default connect(mapStateToProps)(Subscriptions);
