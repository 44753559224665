import React, { Component } from 'react';

import Button from '../../../Ui/Button';

export default class Welcome extends Component {
    render() {
        return (
            <div className="StartPage">
                <h2>Bonjour {this.props.user.first_name} !</h2>
                <p>
                    Afin de garantir nos services, nous avons besoin
                    d'informations complémentaires. Vous pourrez les modifier
                    ultérieurement dans vos paramètres
                </p>
                <Button
                    style={{ display: 'inline-block' }}
                    className="mt-24"
                    onClick={this.props.handleNextPage}
                >
                    Je complete mon profil !
                </Button>
            </div>
        );
    }
}
