import React from 'react';
import dateFormat from 'dateformat';

import '../../Assets/css/Ui/HistoryCard.css';

class HistoryCard extends React.Component {
    constructor(props) {
        super(props);

        this.getTotal = this.getTotal.bind(this);
    }

    getTotal = items => {
        let result = 0;
        for (let index = 0; index < items.length; index++) {
            result += items[index].quantity * items[index].price;
        }
        return result;
    };
    render() {
        return (
            <div className="historyCard">
                {this.props.editedAllowed ? (
                    <span
                        onClick={() =>
                            this.props.handleEdit(this.props.invoice.id)
                        }
                        className={
                            this.props.edit === this.props.invoice.id
                                ? 'closed'
                                : 'edit'
                        }
                    >
                        {this.props.edit === this.props.invoice.id
                            ? 'Fermer'
                            : 'Éditer'}
                    </span>
                ) : null}
                <p className="delivery_date">
                    Livrée le :{' '}
                    {dateFormat(this.props.invoice.date, 'dd/mm/yyyy')}
                </p>
                <p className="delivery_address">
                    {this.props.invoice.delivery_location.address}
                </p>
                <p>
                    Contenu de la livraison : <br />
                    {this.props.invoice.packages.map(element => (
                        <span className="product-line">
                            {element.quantity} x {element.name} (
                            {element.price}€/unité)
                        </span>
                    ))}
                </p>
                <p style={{ textAlign: 'right', marginTop: '20px' }}>
                    Total de la livraison :{' '}
                    {this.getTotal(this.props.invoice.packages)} € HT
                </p>
            </div>
        );
    }
}

export default HistoryCard;
