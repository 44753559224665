import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../../Button';

import '../../../../Assets/css/Ui/Tunnel/General/StartPage.css';

const StartPage = ({ title, description, handleNextPage }) => (
    <div className="StartPage">
        <h2>{title}</h2>
        <p>{description}</p>
        <Button
            style={{ display: 'inline-block' }}
            className="mt-24"
            onClick={handleNextPage}
        >
            C'est Parti !
        </Button>
        <Link to="/" style={{ marginLeft: '40px' }}>
            Retour à l'accueil
        </Link>
    </div>
);

export default StartPage;
