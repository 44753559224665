import React from 'react';

/* CSS IMPORT */

import '../../../../../Assets/css/Ui/Tunnel/Cafe/Components/CafeOption.css';

const CafeOption = props => (
    <div
        className={`CafeOption${props.selected ? ' selected' : ''}`}
        onClick={() => props.handleSelectOption(props.id)}
    >
        <img
            src={
                props.image
                    ? props.image
                    : 'https://via.placeholder.com/288x144'
            }
            alt="Pack preview"
        />
        <div className="text">
            <h3>{props.name}</h3>
            <p>{props.description}</p>
        </div>
    </div>
);

export default CafeOption;
