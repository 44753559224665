const INITIAL_STATE = {
    user: null,
    isLoggedIn: false
};

const auth = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'FEED_USER_META':
            return {
                ...state,
                user: action.payload,
                isLoggedIn: true
            };

        case 'CLEAR_USER_META':
            return INITIAL_STATE;

        default:
            return state;
    }
}

export default auth;
