import React from 'react';

import '../../Assets/css/Ui/Switch.css';

const Switch = props => {
    return (
        <label className={props.green ? 'switch green' : 'switch'}>
            <input type="checkbox" checked={props.value} readOnly />
            <span
                className="slider"
                onClick={() =>
                    props.handleChange({
                        target: { name: props.name, value: !props.value }
                    })
                }
            />
        </label>
    );
};

export default Switch;
