import React from 'react';
import FormLayout from '../Layout/FormLayout';
import Button from '../Ui/Button';
import { fetchApi } from '../../Services/Network';
import { Base64 } from 'js-base64';
import Input from '../Ui/Input';
import { Link } from 'react-router-dom';
import { getErrorFromCode } from '../../Constants';
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { endpoint } from '../../Constants';
import addNotification from '../Ui/Notifications';
export default class Register extends React.Component {
    constructor() {
        super();

        this.state = {
            email: '',
            organization: '',
            first_name: '',
            last_name: '',
            password1: '',
            password2: '',
            loading: false,
            error: ''
        };

        this.errors = {};

        this.handleChange = this.handleChange.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();

        if (this.state.loading) {
            return;
        }

        this.setState({ loading: true });
        const { email, organization, first_name, last_name, password1, password2 } = this.state;
        const user = {
          email: email,
          first_name: first_name,
          last_name: last_name,
          organization: organization,
          password1: password1,
          password2: password2
        };
        fetch(`${endpoint}/api/v1/users/auth/register/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(user)
        })
            .then(res => {
                if(!res.ok){
                    res.json().then(errorData => {
                        // Handle and display error messages here
                        let result = '';
                          for (const key in errorData) {
                            if (errorData.hasOwnProperty(key)) {
                              const value = errorData[key];
                              result += `${value}, `;
                            }
                          }
                        result = result.slice(0, -2);
                        this.setState({ error: result});
                    });
                    // this.setState({ error: "EMAIL_IN_USE"});
                    this.setState({ loading: false });
                }else{
                    this.setState({ loading: false });
                    this.props.history.push('/login')
                    addNotification(
                      'Veuillez vérifier votre adresse e-mail');
                }
            })
            .catch(err => {
                if (err.code === 400) {
                    Object.keys(err.body).forEach(k => {
                        this.errors[k] = err.body[k];
                    });
                }

                if (err.code === 409) {
                    this.setState({ error: 'EMAIL_IN_USE' });
                }

                this.setState({ loading: false });
            });
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    isFormValid() {
        // Checks that each field is not blank
        return ['email', 'organization', 'first_name', 'last_name', 'password1', 'password2'].every(
            k => this.state[k].trim().length > 0
        );

        // You're free to add any test
    }

    render() {
        let error = null;

        if (this.state.error) {
            error = (
                <div className="errorToast">
                    {getErrorFromCode(this.state.error)
                        .split('\n')
                        .map((b, i) => (
                            <span key={i} style={{ display: 'block' }}>
                                {b}
                            </span>
                        ))}
                </div>
            );
        }

        return (
            <FormLayout>
                <div className="Form register">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Simplifiez la gestion de votre bureau !</h2>
                                <h3>Et en plus Les Plaisirs Fruites est gratuit...</h3>
                                {error}
                            </div>
                        </div>
                    </div>
                    <form onSubmit={this.handleSubmit}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <Input
                                        type="text"
                                        placeholder="John"
                                        className="form-control"
                                        autoComplete="off"
                                        name="first_name"
                                        value={this.state.first_name}
                                        onChange={this.handleChange}
                                        errors={this.errors}
                                        label="PRENOM"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        autoComplete="off"
                                        name="last_name"
                                        value={this.state.last_name}
                                        onChange={this.handleChange}
                                        errors={this.errors}
                                        label="NOM"
                                        placeholder="Doe"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <Input
                                        type="email"
                                        className="form-control"
                                        autoComplete="off"
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.handleChange}
                                        errors={this.errors}
                                        label="Mail pro"
                                        placeholder="john.doe@gmail.com"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <Input
                                        type="text"
                                        placeholder="Mot de passe"
                                        className="form-control"
                                        autoComplete="off"
                                        name="password1"
                                        value={this.state.password1}
                                        onChange={this.handleChange}
                                        type="password"
                                        errors={this.errors}
                                        label="Mot de passe"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        autoComplete="off"
                                        name="password2"
                                        value={this.state.password2}
                                        onChange={this.handleChange}
                                        type="password"
                                        errors={this.errors}
                                        label="Confirmation mot de passe"
                                        placeholder=""
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <Input
                                        type="organization"
                                        className="form-control"
                                        autoComplete="off"
                                        name="organization"
                                        value={this.state.organization}
                                        onChange={this.handleChange}
                                        errors={this.errors}
                                        label="SOCIETE"
                                        placeholder="Doe Corporation"
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-md-6">
                                    <Button
                                        disabled={!this.isFormValid()}
                                        loading={this.state.loading}
                                    >
                                        Créer mon compte
                                    </Button>
                                </div>
                                <div
                                    className="col-md-6"
                                    style={{ textAlign: 'right' }}
                                >
                                    <Link
                                        to="/login"
                                        style={{
                                            lineHeight: '48px'
                                        }}
                                    >
                                        J'ai déjà un compte
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </FormLayout>
        );
    }
}
