import React from 'react';

import Button from '../../Button';
import CafeOption from './Components/CafeOption';

import '../../../../Assets/css/Ui/Tunnel/Cafe/PackageOptionsSelectionPage.css';

export const PackageOptionsSelectionPage = props => (
    <div className="PackageOptionsSelectionPage">
        <div>
            <p className="catchSentence">C'est parti pour le café !</p>
            <h2>Parfait, maintenant le plus important !</h2>
        </div>

        <div className="row mt-24">
            <div className="col-md-12">
                {props.packs.map(pack => (
                    <CafeOption
                        {...pack}
                        key={pack.id}
                        handleSelectOption={props.handleSelectOption}
                        selected={
                            props.options.length > 0 &&
                            props.options[0] === pack.id
                        }
                    />
                ))}
            </div>
        </div>
        <div className="row mt-32">
            <div className="col-md-12">
                <span className="small" style={{ marginBottom: '8px' }}>
                    {props.quantityLeft > 0
                        ? `Plus que ${props.quantityLeft} kg`
                        : 'Parfait !'}
                </span>
                <Button
                    onClick={props.handleNextPage}
                    disabled={props.buttonDisabled}
                >
                    Etape suivante !
                </Button>
            </div>
        </div>
    </div>
);
