import React from 'react';
import { Base64 } from 'js-base64';

import '../../Assets/css/Pages/SubscribeConfirm.css';

export const SubscribeConfirm = props => (
    <div className="SubscribeConfirm">
        <div
            style={{ width: '500px', marginLeft: 'auto', marginRight: 'auto' }}
        >
            <h2>Félicitation {Base64.decode(props.match.params.name)} !</h2>
            <p>
                Vous faites partie des offices managers qui vont gagner du temps
                et faire profiter à ses collaborateurs des meilleurs services
                Pepp’r !
            </p>
            <p>
                Nous venons de vous envoyer un mail – afin de{' '}
                <strong>valider votre compte</strong> – à cette adresse :
            </p>
            <p>
                <strong>{Base64.decode(props.match.params.mail)}</strong>
            </p>
            <div style={{ margin: '20px 0px' }}>
                <img
                    src="https://i.giphy.com/media/UEbfQpfuK0xO0/200w.webp"
                    height="280"
                    alt="Well Done img"
                    style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}
                />
            </div>
            <p>À tout de suite !</p>
            <p className="foot">
                Ce n’est pas le bon mail ?
                <span onClick={() => props.history.goBack()}>
                    Retourner à l’écran précédent
                </span>
            </p>
        </div>
    </div>
);
