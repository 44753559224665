/* LIBRARIES IMPORT */

import React from 'react';
import { withRouter } from 'react-router-dom';

/* SERVICES IMPORT */

import { fetchApi } from '../../../Services/Network';
import { endpoint } from '../../../Constants';

/* COMPONENTS IMPORT */

import StartPage from '../../Ui/Tunnel/General/StartPage';
import DeliveryAddressSelection from '../../Ui/Tunnel/General/DeliveryAddressSelection';
import ServiceSelection from '../../Ui/Tunnel/Service/ServiceSelection';
import ServiceOrderReviewPage from '../../Ui/Tunnel/Service/ServiceOrderReviewPage';
import addNotification from '../../Ui/Notifications';
import axios from "axios"

class ServiceTunnel extends React.Component {
    constructor() {
        super();

        this.state = {
            delivery_location: null,
            packages: [],
            estimateLink: null,
            loading: false
        };

        this.handlePlaceOrder = this.handlePlaceOrder.bind(this);
        this.handleCreateEstimate = this.handleCreateEstimate.bind(this);
    }

    handleCreateEstimate() {
        const headers = {
            'Content-Type': 'application/json'
        };

        this.setState({ loading: true });
        axios.post(`${endpoint}/api/delivery/estimate_api/${this.props.organization_id}/`, {
                serviceId: this.props.service.id,
                packages: this.state.packages,
                deliveryId: this.state.delivery_location,
            }, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
            .then(res => {
                this.setState({ loading: false, estimateLink: res.data });
            })
            .catch(err => console.error(err));
    }

    handlePlaceOrder() {
        axios.post(`${endpoint}/api/delivery/place_order_service/${this.props.organization_id}/${localStorage.getItem('token')}`,
            {
                delivery_location: this.state.delivery_location,
                packages: this.state.packages.map(p => ({
                    package: p.package.id,
                    quantity: p.quantity_package
                })),
                service: this.props.service.id
            }, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}}
        ).then(res => {
            if (this.props.adminMode) {
                addNotification('Votre commande a bien été créé !');
                return this.props.history.push(
                    `/orders/${res.data.organisation}/${res.data.id}`
                );
            } else {
                addNotification('Votre commande a bien été créé !');
                return this.props.history.push('/');
            }
        });
    }

    render() {
        switch (this.props.currentPage) {
            case 0:
                return (
                    <StartPage
                        title={this.props.service.homepageTitle}
                        description={this.props.service.homepageDescription}
                        handleNextPage={() => {
                            this.props.handleNextPage();
                        }}
                    />
                );
            case 1:
                return (
                    <DeliveryAddressSelection
                        handleNextPage={this.props.handleNextPage}
                        delivery_location={this.state.delivery_location}
                        setDeliveryLocation={id => {
                            this.setState({ delivery_location: id });
                            this.props.setNextDisabled(false);
                        }}
                        organization_id={this.props.organization_id}
                    />
                );
            case 2:
                return (
                    <ServiceSelection
                        handleNextPage={this.props.handleNextPage}
                        servicePackages={this.props.service.packages}
                        handleQuantityChange={(element, quantity) => {
                            const items = this.state.packages;
                            const isInArray = items.findIndex(
                                obj => obj.package.id === element.id
                            );
                            if (isInArray > -1 && quantity > 0) {
                                items[isInArray].quantity = quantity;
                                items[isInArray].quantity_package = quantity;
                                this.setState({ packages: items });
                            }
                            if (isInArray === -1 && quantity > 0) {
                                items.push({
                                    package: element,
                                    quantity: quantity,
                                    quantity_package: quantity
                                });
                                this.setState({ packages: items });
                            }
                            if (isInArray > -1 && quantity === 0) {
                                items.splice(isInArray, 1);
                                this.setState({ packages: items });
                            }

                            if (items.length === 0) {
                                this.props.setNextDisabled(true);
                            } else {
                                this.props.setNextDisabled(false);
                            }
                        }}
                        getQuantityLocationById={id => {
                            return this.state.packages.findIndex(
                                obj => obj.package.id === id
                            );
                        }}
                        packages={this.state.packages}
                        serviceName={this.props.service.name}
                    />
                );
            case 3:
                return (
                    <ServiceOrderReviewPage
                        organization_id={this.props.organization_id}
                        free_packages={this.props.service.free_packages}
                        delivery_location={this.state.delivery_location}
                        packages={this.state.packages}
                        handleQuantityChange={(element, quantity) => {
                            const items = this.state.packages;
                            const isInArray = items.findIndex(
                                obj => obj.package.id === element.id
                            );
                            if (isInArray > -1 && quantity > 0) {
                                items[isInArray].quantity = quantity;
                                items[isInArray].quantity_package = quantity;
                                this.setState({ packages: items });
                            }
                            if (isInArray === -1 && quantity > 0) {
                                items.push({
                                    package: element,
                                    quantity: quantity,
                                    quantity_package: quantity
                                });
                                this.setState({ packages: items });
                            }
                            if (isInArray > -1 && quantity === 0) {
                                items.splice(isInArray, 1);
                                this.setState({ packages: items });
                            }
                        }}
                        getQuantityLocationById={id => {
                            return this.state.packages.findIndex(
                                obj => obj.package.id === id
                            );
                        }}
                        handleSubmit={this.handlePlaceOrder}
                        handleCreateEstimate={this.handleCreateEstimate}
                        loading={this.state.loading}
                        estimateLink={this.state.estimateLink}
                    />
                );
            default:
                return null;
        }
    }
}

export default withRouter(ServiceTunnel);
