import React, { Component } from 'react';

/* CSS IMPORT */

import '../../../../../Assets/css/Ui/Tunnel/Product/Components/FrequenceSelector.css';

export default class FrequenceSelector extends Component {
    render() {
        let available_frequencies = this.props.available_frequencies.map(a => a.name || a);
        return (
            <div className="row FrequenceSelector">
                <div className={available_frequencies.indexOf("weekly") > -1 ? "col-md-3" : "col-md-3 hidden"}>
                    <div
                        className={
                            this.props.frequenceSelected === 'weekly'
                                ? 'frequence-choice selected'
                                : 'frequence-choice'
                        }
                        onClick={() => this.props.setFrequence('weekly')}
                    >
                        <p>Toutes les semaines</p>
                    </div>
                </div>
                <div className={available_frequencies.indexOf("fortnightly") > -1 ? "col-md-3" : "col-md-3 hidden"}>
                    <div
                        className={
                            this.props.frequenceSelected === 'fortnightly'
                                ? 'frequence-choice selected'
                                : 'frequence-choice'
                        }
                        onClick={() => this.props.setFrequence('fortnightly')}
                    >
                        <p>Tous les 15 jours</p>
                    </div>
                </div>
                <div className={available_frequencies.indexOf("monthly") > -1 ? "col-md-3" : "col-md-3 hidden"}>
                    <div
                        className={
                            this.props.frequenceSelected === 'monthly'
                                ? 'frequence-choice selected'
                                : 'frequence-choice'
                        }
                        onClick={() => this.props.setFrequence('monthly')}
                    >
                        <p>Tous les mois</p>
                    </div>
                </div>
                <div className={available_frequencies.indexOf("promptly") > -1 ? "col-md-3" : "col-md-3 hidden"}>
                    <div
                        className={
                            this.props.frequenceSelected === 'promptly'
                                ? 'frequence-choice selected'
                                : 'frequence-choice'
                        }
                        onClick={() => this.props.setFrequence('promptly')}
                    >
                        <p>Une seule fois</p>
                    </div>
                </div>
            </div>
        );
    }
}
