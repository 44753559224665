import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';

/* REDUX IMPORT */

import reducers from './Redux/Reducers';

/* COMPONENTS IMPORT */

import Layout from './Components/Layout/Layout';
import Login from './Components/Pages/Login';
import { SubscribeConfirm } from './Components/Pages/SubscribeOptions';
import ForgotPassword from './Components/Pages/ForgotPassword';
import Register from './Components/Pages/Register';
import TunnelLayout from './Components/Layout/TunnelLayout';
import AccountTunnel from './Components/Pages/Tunnel/AccountTunnel';
import { NotificationManager } from './Components/Ui/Notifications';
import { Modal } from './Components/Ui/Modals';
import EmailConfirmation from './Components/Pages/EmailConfirmation';
import Logout from './Components/Pages/Logout';

/* CSS IMPORT */

import './Assets/css/theme.css';

const store = createStore(reducers);

class App extends Component {
    render() {
        return (
            <Router>
                <Provider store={store}>
                    <div>
                        <Switch>
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/logout" component={Logout} />
                            <Route
                                exact
                                path="/register"
                                component={Register}
                            />
                            <Route
                                exact
                                path="/register/confirm/:mail/:name"
                                component={SubscribeConfirm}
                            />
                            <Route
                                exact
                                path="/email-confirmation/:token"
                                component={EmailConfirmation}
                            />
                            <Route
                                exact
                                path="/forgot-password"
                                component={ForgotPassword}
                            />
                            <Route
                                exact
                                path="/forgot-password/:token"
                                component={ForgotPassword}
                            />
                            <Route
                                exact
                                path="/tunnel/:service"
                                component={TunnelLayout}
                            />
                            <Route
                                exact
                                path="/tunnel/:service/:orgId"
                                component={TunnelLayout}
                            />
                            <Route
                                exact
                                path="/accountTunnel"
                                component={AccountTunnel}
                            />
                            <Route path="/" component={Layout} />
                            <Route render={() => <Redirect to="/404" />} />
                        </Switch>
                        <NotificationManager />
                        <Modal />
                    </div>
                </Provider>
            </Router>
        );
    }
}

export default App;
