/* LIBRARIES IMPORT */

import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

/* SERVICES IMPORT */

import { fetchApi } from '../../Services/Network';
import AuthWrapper from '../../Services/AuthWrapper';
import axios from "axios";
/* COMPONENTS IMPORT */

import TunnelPosition from '../Ui/TunnelPosition';
import ProductTunnel from '../Pages/Tunnel/ProductTunnel';
import ServiceTunnel from '../Pages/Tunnel/ServiceTunnel';
import CafeTunnel from '../Pages/Tunnel/CafeTunnel';
import addNotification from '../Ui/Notifications';
import SpinnerLoading from '../Ui/SpinnerLoading';
import { endpoint } from '../../Constants';
/* CSS IMPORT */

import '../../Assets/css/Layout/TunnelLayout.css';
import { show } from '../Ui/Modals';

class TunnelLayout extends React.Component {
  constructor() {
    super();

    this.state = {
      nbPages: 1,
      currentPage: 0,
      loading: true,
      service: null,
      admin: false,
      prevDisabled: true,
      nextDisabled: false
    };

    this.handleNextPage = this.handleNextPage.bind(this);
    this.handlePreviousPage = this.handlePreviousPage.bind(this);
  }

  componentDidMount() {
    let admin = false;

    if (this.props.match.params.orgId) {
      if (this.props.user.role !== 'Admin') {
        addNotification(
          'Vous ne pouvez pas accéder à cette page ...',
          'danger'
        );
        this.props.history.replace('/');
      }

      this.setState({ admin: true });
      admin = true;
    }

    if (!this.props.user.accountCompleted && !admin) {
      addNotification(
        'Veuillez compléter votre profil avant de commencer ...',
        'danger'
      );

      return this.props.history.push('/accountTunnel');
    }
    axios.get(`${endpoint}/api/service/infos/${this.props.match.params.service}/`)
      .then(res => {
        if (res.data.type === 'product') {
          this.setState({ service: res.data, loading: false, nbPages: 5 });
        }
        if (res.data.type === 'service') {
          this.setState({ service: res.data, loading: false, nbPages: 4 });
        }
        if (res.data.type === 'cafe') {
          this.setState({ service: res.data, loading: false, nbPages: 5 });
        }
      })
      .catch(err => console.log(err));
  }

  handleNextPage() {
    if (this.state.currentPage !== this.nbPages - 1) {
      this.setState({
        currentPage: this.state.currentPage + 1,
        nextDisabled: true
      });
    }
  }

  handlePreviousPage() {
    if (this.state.currentPage !== 0) {
      this.setState({
        currentPage: this.state.currentPage - 1,
        nextDisabled: false
      });
    }
  }

  render() {
    if (this.state.loading) {
      return <SpinnerLoading />;
    }

    return (
      <div className="TunnelLayout">
        <button
          className="closeBtn"
          onClick={_ =>
            show(
              'Êtes-vous sûrs de vouloir fermer ? Toute information sera perdue.',
              'info',
              _ => this.props.history.push('/')
            )
          }
        >
          <i className="fas fa-times" />
        </button>
        <TunnelPosition
          actualState={this.state.currentPage}
          nbStates={this.state.nbPages}
          prevDisabled={this.state.currentPage === 0}
          nextDisabled={this.state.nextDisabled}
          onPrev={this.handlePreviousPage}
          onNext={this.handleNextPage}
        />
        <Link to="/help" className="infoBtn">
          <span>?</span>
        </Link>
        <div className="container content">
          {(() => {
            if (this.state.service.type === 'product') {
              return (
                <ProductTunnel
                  service={this.state.service}
                  organization_id={
                    this.state.admin
                      ? this.props.match.params.orgId
                      : this.props.user.organization.id
                  }
                  currentPage={this.state.currentPage}
                  handleNextPage={this.handleNextPage}
                  adminMode={this.state.admin}
                  setNextDisabled={d => this.setState({ nextDisabled: d })}
                />
              );
            }
            if (this.state.service.type === 'service') {
              return (
                <ServiceTunnel
                  service={this.state.service}
                  organization_id={
                    this.state.admin
                      ? this.props.match.params.orgId
                      : this.props.user.organization.id
                  }
                  currentPage={this.state.currentPage}
                  handleNextPage={this.handleNextPage}
                  adminMode={this.state.admin}
                  setNextDisabled={d => this.setState({ nextDisabled: d })}
                />
              );
            }
            if (this.state.service.type === 'cafe') {
              return (
                <CafeTunnel
                  service={this.state.service}
                  organization_id={
                    this.state.admin
                      ? this.props.match.params.orgId
                      : this.props.user.organization.id
                  }
                  currentPage={this.state.currentPage}
                  handleNextPage={this.handleNextPage}
                  adminMode={this.state.admin}
                  setNextDisabled={d => this.setState({ nextDisabled: d })}
                />
              );
            }
          })()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStateToProps)(
  AuthWrapper(TunnelLayout, TunnelLayout)
);
