import React from 'react';

import '../../Assets/css/Ui/InvoiceItem.css';

export const InvoiceItem = props => (
    <div className={`InvoiceItem${props.small ? ' small' : ''}`}>
        <div className="invoice">
            <div className="productName">
                <div>
                    <h3>{props.product}</h3>
                </div>
            </div>
            <div className="data">
                <ul>
                    <li className="price">
                        <span className="grey0">{props.price} €</span>
                    </li>
                    <li className="invoice">
                        <a
                            href={props.href}
                            className="blue"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Voir la facture
                        </a>
                    </li>
                    <li className="status">
                        {(() => {
                            if (props.status === "paid") {
                                return <span className="green">Payée</span>;
                            }
                            else if (props.status === "upcoming") {
                                return <span className="yellow">A venir</span>;
                            }
                            else {
                                return <span className="red">En retard</span>;
                            }
                        })()}
                    </li>
                </ul>
            </div>
        </div>
    </div>
);
