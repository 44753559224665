import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/* COMPONENTS IMPORT */

import DeliveryLocation from '../../Ui/DeliveryLocation';
//import Button from '../../Ui/Button';
import CafePack from '../../Ui/Tunnel/Cafe/Components/CafePack';
import CafeOption from '../../Ui/Tunnel/Cafe/Components/CafeOption';
import { FreeServiceBox } from '../../Ui/FreeServiceBox';

/* SERVICE IMPORT */

import { getFreePackages } from '../../../Constants';

/* STYLE IMPORT */

import '../../../Assets/css/Pages/OrderReview/CafeOrderReview.css';
import ValidationButtons from './ValidationButtons';
import Calendar from 'react-calendar';

export default class CafeOrderReview extends Component {
    render() {
        return (
            <div className="CafeOrderReview">
                <div className="row Header">
                    <div className="col-md-12">
                        <h1>{this.props.order.service.name}</h1>

                        {(() => {

                            if (this.props.order.status !== 'WAITING') {
                                /*return (
                                    <span>
                                        <Button
                                            onClick={() => {}}
                                            disabled={false}
                                        >
                                            COMMANDE PONCTUELLE
                                        </Button>
                                    </span>
                                );*/
                            }
                        })()}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h2>Gestion de l'abonnement</h2>
                    </div>
                </div>
                <div className="row title-delimiter">
                    <div className="col-md-12">
                        <h4>Adresse de Livraison</h4>
                    </div>
                </div>
                {this.props.user.role === 'Admin' && (
                    <p>
                    <Link to={`/customer/${this.props.order.organization}`}>
                        <button className="customer_button">Fiche du client</button>
                    </Link>
                    </p>
                )}
                <div className="row">
                    <div className="col-md-8">
                        <DeliveryLocation
                            selected={this.props.order.delivery_location.id}
                            editable={false}
                            position={{lat: this.props.order.delivery_location.latitude, lng: this.props.order.delivery_location.longitude}}
                            {...this.props.order.delivery_location}
                        />
                    </div>
                </div>
                <div className="row title-delimiter">
                    <div className="col-md-12">
                        <h4>Choix du Pack</h4>
                    </div>
                </div>
                <div className="row">
                    {this.props.order.service.packages.map(pack => (
                        <div
                            key={pack.id}
                            className="pack-container col-sm-3 col-xs-3 "
                        >
                            <CafePack
                                {...pack}
                                selected={
                                    this.props.order.packages[0].length > 0
                                        ?
                                        this.props.order.packages[0][0]
                                              .package.id ||
                                          this.props.order.packages[0][0].package
                                        : null
                                }
                                onClick={() =>
                                    this.props.selectPackage(pack.id)
                                }
                            />
                        </div>
                    ))}
                    {/**
                        <div
                        className={`already-have${
                            this.props.order.packages.length === 0
                                ? ' active'
                                : ''
                        }`}
                        onClick={() => this.props.selectPackage(1)}
                        >
                            <p>Nous avons déjà une machine </p>
                        </div>
                    */}
                </div>
                <div className="row title-delimiter">
                    <div className="col-md-12">
                        <h4>Choix du Café</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {this.props.order.service.packages[0].package_options.map(pack => (
                            <CafeOption
                                {...pack}
                                key={pack.id}
                                selected={
                                    Object.keys(this.props.order.option).length > 0 &&
                                    this.props.order.option.id === pack.id
                                }
                                handleSelectOption={
                                    this.props.handleSelectOption
                                }
                            />
                        ))}
                    </div>
                </div>
                <div className="row title-delimiter">
                    <div className="col-md-12">
                        <h4>Les Options</h4>
                    </div>
                </div>
                <div className="row">
                    {(() => {
                        if (
                            this.props.order.service.free_packages.length === 0
                        ) {
                            return (
                                <div className="col-md-12">
                                    <p>Aucunes options pour ce service</p>
                                </div>
                            );
                        }
                        return getFreePackages(
                            this.props.order.service.free_packages
                        ).map((element, index) => {
                            return (
                                <div
                                    key={index}
                                    className="col-md-2 service-container"
                                >
                                    <FreeServiceBox
                                        freeService={element}
                                        active={true}
                                    />
                                </div>
                            );
                        });
                    })()}
                </div>
                {this.props.user.role !== 'Admin' || (
                    <div>
                        <div className="row title-delimiter">
                            <div className="col-md-12">
                                <h4>Date de d&eacute;but</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Calendar
                                    value={
                                        new Date(this.props.order.start_date)
                                    }
                                    onChange={e =>
                                        this.props.handleChange({
                                            target: {
                                                name: 'start_date',
                                                value: e
                                            }
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                )}
                <div className="row footer">
                    <div className="col-md-12">
                        <ValidationButtons {...this.props} />
                    </div>
                </div>
            </div>
        );
    }
}
