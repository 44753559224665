import React, { Component } from 'react';

import CalendarBox from './CalendarBox';
import ProductSelectionModal from './ProductSelectionModal';
import Calendar from 'react-calendar';

import '../../../../../Assets/css/Ui/Tunnel/Product/Components/FrequencePromptlySelector.css';

export default class FrequencePromptlySelector extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalShow: false
        };
    }

    render() {
        return (
            <div className="FrequencePromptlySelector">
                {(() => {
                    if (this.state.modalShow) {
                        return (
                            <ProductSelectionModal
                                packages={this.props.activePackages}
                                show={this.state.modalShow}
                                servicePackages={this.props.servicePackages.filter(
                                    obj => obj.bio === this.props.biological
                                )}
                                addPackages={this.props.addPackages}
                                selectedDay={0}
                                handleClose={() => this.setState({ modalShow: false })}
                            />
                        );
                    }
                })()}
                {!this.props.serviceSapin && (
                    <div className="row" style={{ marginTop: '24px' }}>
                        <div className="col-md-5">
                            <h3 className="black">Choisissez votre date de livraison</h3>
                            <Calendar
                                value={new Date(this.props.delivery_date)}
                                onChange={e =>
                                    this.props.handleChange({
                                        target: {
                                            name: 'delivery_date',
                                            value: e
                                        }
                                    })
                                }
                                tileDisabled={({ date }) => date.getDay() === 0 | date.getDay() === 6}
                                minDate={(d => new Date(d.setDate(d.getDate() + 1)))(new Date)}
                            />
                        </div>
                    </div>
                )}
                <div className="row" style={{ marginTop: '24px' }}>
                    <div className="col-md-12">
                        <p className="addProduct-title">Choisissez les produits qui vont ferraient plaisir !</p>
                    </div>
                    <div className="col-md-12 calendarbox-slider">
                        {(() => {
                            return this.props.activePackages[0].map((myPackage, index) => {
                                let price = myPackage.amount == null ? myPackage.package.price : myPackage.amount;
                                return (
                                    <CalendarBox
                                        key={index}
                                        price={price}
                                        name={myPackage.package.name}
                                        description={myPackage.package.description}
                                        quantity={myPackage.quantity_package}
                                        handleQuantityChange={quantity => {
                                            this.props.handleQuantityChange(0, index, quantity);
                                        }}
                                        handlePriceChange={
                                            this.props.handlePriceChange
                                                ? price => this.props.handlePriceChange(0, index, price.target.value)
                                                : null
                                        }
                                        handleDelete={() => {
                                            this.props.handleDelete(0, index);
                                        }}
                                    />
                                );
                            });
                        })()}
                    </div>
                    <div className="col-md-12">
                        <div
                            className="addProduct"
                            onClick={() => {
                                this.setState({ modalShow: true });
                            }}
                        >
                            <i className="fas fa-plus blue" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
