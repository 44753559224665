import React from 'react';

import '../../Assets/css/Ui/Checkbox.css';

const Checkbox = props => (
    <div
        onClick={props.onChange}
        className={props.checked ? 'cbcontainer checked' : 'cbcontainer'}
    >
        <span className="cMark" />
    </div>
);

export default Checkbox;
