import React, { Component } from 'react';

import '../../Assets/css/Ui/Collapsible.css';

class Collapsible extends Component {
    constructor() {
        super();

        this.state = {
            isToggle: false
        };

        this.toggleCollapse = this.toggleCollapse.bind(this);
    }

    toggleCollapse() {
        this.setState({ isToggle: !this.state['isToggle'] });
    }

    render() {
        return (
            <div>
                <button
                    onClick={this.toggleCollapse}
                    className={
                        this.state.isToggle
                            ? 'collapsible active'
                            : 'collapsible'
                    }
                >
                    <span className="collapse-title">{this.props.title}</span>
                </button>
                <div
                    className={
                        this.state.isToggle
                            ? 'collapse-content active'
                            : 'collapse-content'
                    }
                >
                    <p>{this.props.content}</p>
                </div>
            </div>
        );
    }
}

export default Collapsible;
