import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchApi } from '../../../../Services/Network';
import HistoryCard from '../../../Ui/HistoryCard';
import HistoryCardForm from '../../../Ui/HistoryCardForm';
import addNotification from '../../../Ui/Notifications';
import { show } from '../../../Ui/Modals';
import { endpoint } from '../../../../Constants';
import axios from "axios"
class DeliveryHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoices: [],
      loading: true,
      selectedInvoice: null,
      edit: null
    };

    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.getData = this.getData.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  /*async getData() {
    const invoices = await fetchApi(`/invoices/${this.props.id || this.props.user.organization._id}`);
    this.setState({ invoices, loading: false });
    return Promise.resolve();
  }*/

  async getData() {
    axios.get(`${endpoint}/api/delivery/invoices/${this.props.id || this.props.user.organization.id}`,{headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
    .then(res => {console.log(res.data.peppr)
    this.setState({ invoices: res.data.peppr, loading: false })})
    return Promise.resolve();
  }

  componentDidMount() {
    this.getData();
  }

  handleDelete(id) {
    show('Êtes-vous sûrs de vouloir supprimer cette historique de livraison?', 'delete', _ => {
      axios.delete(`${endpoint}/api/delivery/invoices/${id}`,{headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
        .then(() => {
          addNotification('Historique de livraison supprimé');
          this.getData().then(_ => this.setState({ loading: false, edit: null }));
        })
        .catch(err => {
          addNotification('Erreur lors de la suppression', 'danger');
        });
    });
  }

  handleEdit(id) {
    const selectedInvoice = this.state.invoices.find(({ id }) => id === id);

    if (id === this.state.edit) {
      return this.setState({ edit: null });
    }

    this.setState({
      edit: id,
      selectedInvoice
    });
  }

  handleSave(invoice) {
    axios.put(`${endpoint}/api/delivery/invoices/${invoice.id}/`, {
        items: invoice.packages.filter(e => e.quantity !== 0)
      }, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
      .then(() => {
        addNotification('Historique de livraison modifié');
        this.getData().then(_ => this.setState({ loading: false, edit: null }));
      })
      .catch(err => {
        console.log(err);
        addNotification('Erreur lors de la modification', 'danger');
      });
  }

  render() {
    if (this.state.invoices.length === 0 && !this.state.loading) {
      return (
        <div className="row">
          <div className="col-md-12">
            <span className="noSubs">Retrouvez ici l'historique des livraisons réalisées dans vos locaux</span>
          </div>
        </div>
      );
    }

    return (
      <div>
        {this.state.invoices.map(invoice => (
          <div key={invoice.id}>
            <HistoryCard editedAllowed={this.props.editedAllowed} invoice={invoice} edit={this.state.edit} handleEdit={this.handleEdit} />
            {invoice.id !== this.state.edit || (
              <HistoryCardForm
                invoice={invoice}
                handleDelete={() => this.handleDelete(invoice.id)}
                handleCancel={() => this.setState({ edit: null })}
                handleSave={invoiceToSave => this.handleSave(invoiceToSave)}
              />
            )}
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStateToProps)(DeliveryHistory);
