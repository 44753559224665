import React from 'react';

/* COMPONENTS IMPORT */

import DeliveryLocation from '../../DeliveryLocation';
import { FreeServiceBox } from '../../FreeServiceBox';
import Button from '../../Button';

/* SERVICES IMPORT */

import { fetchApi } from '../../../../Services/Network';
import { getFreePackages } from '../../../../Constants';
import axios from "axios";
import { endpoint } from '../../../../Constants';
/* CSS IMPORT */

import '../../../../Assets/css/Ui/Tunnel/Cafe/CafeOrderReviewPage.css';


const Card = props => (
    <div className="Card">
        <img src={props.image} height="128" alt="card thumb" />
        <div className="text">
            <h3>{props.name}</h3>
            <p>{props.description.split('\n')[0]}</p>
        </div>
        <div className="bottom">{props.price}</div>
    </div>
);

export class OrderReviewPage extends React.Component {
    constructor() {
        super();

        this.state = {
            location: null
        };
    }

    componentDidMount() {
        //this.props.organization_id
        axios.get(`${endpoint}/api/delivery/locations_org/${this.props.organization_id}/${localStorage.getItem('token')}`, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
            .then(res => {
                this.setState({ location: res.data[0] });
            })
            .catch(err => console.log(err));
    }



    render() {
        return (
            <div className="CafeOrderReviewPage">
                <p className="catchSentence">Parfait !</p>
                <h2>C'est tout bon ?</h2>

                <div className="row" style={{ marginTop: '30px' }}>
                    <div className="col-md-8">
                        {!this.state.location || (
                            <DeliveryLocation
                                selected={this.props.delivery_location.id}
                                editable={false}
                                {...this.state.location}
                            />
                        )}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 servicebox-slider">
                        {this.props.packages.map(p => (
                            <Card
                                {...p}
                                key={p.id}
                                price="Location gratuite"
                            />
                        ))}

                        {this.props.chosenOptions.map(co => {
                            const option = this.props.options.find(
                                ({ id }) => id === co
                            );

                            return (
                                <Card
                                    price={
                                        this.props.packages[0].quantity +
                                        'kg / mois'
                                    }
                                    {...option}
                                    key={co}
                                />
                            );
                        })}
                        {(() => {
                            return getFreePackages(
                                this.props.free_packages
                            ).map((element, index) => (
                                <FreeServiceBox
                                    key={index}
                                    freeService={element}
                                    active={true}
                                />
                            ));
                        })()}

                    </div>
                </div>
                <div className="row mt-32">
                    <div
                        className="col-md-6"
                        style={{ paddingLeft: '8px', paddingRight: '8px' }}
                    >
                        <Button
                            onClick={this.props.handleSubmit}
                            style={{ display: 'inline-block' }}
                        >
                            Valider ma commande
                        </Button>
                        {this.props.estimateLink ? (
                            <a
                                href={this.props.estimateLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                download="devis.pdf"
                            >
                                <Button onClick={() => {}} className="bordered">
                                    Télécharger le devis
                                </Button>
                            </a>
                        ) : (
                            <Button
                                onClick={this.props.handleCreateEstimate}
                                loading={this.props.loading}
                                className="bordered"
                            >
                                Recevoir mon devis par mail
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
