import React from 'react';

import '../../../Assets/css/Pages/accountSettings.css';
import General from './General';
import Password from './Password';
import Billing from './Billing';
import Settings from './Settings';
import Delivery from './Delivery';
import DeliveryHistory from '../Admin/Customers/DeliveryHistory';

const AccountSideMenu = props => {
    return (
        <div className="sidemenu">
            <button
                onClick={() => props.onClick(0)}
                className={props.activeSetting === 0 ? 'Id active' : 'Id'}
            >
                <span>Infos de compte</span>
            </button>

            <button
                onClick={() => props.onClick(2)}
                className={props.activeSetting === 2 ? 'Truck active' : 'Truck'}
            >
                <span>Sites de livraison</span>
            </button>
            <button
                onClick={() => props.onClick(5)}
                className={
                    props.activeSetting === 5 ? 'History active' : 'History'
                }
            >
                <span>Historique</span>
            </button>
            <button
                onClick={() => props.onClick(3)}
                className={
                    props.activeSetting === 3
                        ? 'Facturation active'
                        : 'Facturation'
                }
            >
                <span>Facturation</span>
            </button>
            <button
                onClick={() => props.onClick(1)}
                className={props.activeSetting === 1 ? 'Lock active' : 'Lock'}
            >
                <span>Mot de passe</span>
            </button>
            <button
                onClick={() => props.onClick(4)}
                className={
                    props.activeSetting === 4 ? 'Settings active' : 'Settings'
                }
            >
                <span>Paramètres</span>
            </button>
        </div>
    );
};

class AccountSettings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeSetting: 0
        };
    }

    render() {
        return (
            <div className="AccountSettings">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="Header">Mon Compte</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <AccountSideMenu
                            onClick={index => {
                                this.setState({ activeSetting: index });
                            }}
                            activeSetting={this.state.activeSetting}
                        />
                    </div>
                    <div className="col-md-8" style={{ position: 'relative' }}>
                        {(() => {
                            if (this.state.activeSetting === 0) {
                                return <General />;
                            }
                            if (this.state.activeSetting === 1) {
                                return <Password />;
                            }
                            if (this.state.activeSetting === 2) {
                                return <Delivery />;
                            }
                            if (this.state.activeSetting === 3) {
                                return <Billing />;
                            }
                            if (this.state.activeSetting === 4) {
                                return <Settings />;
                            }
                            if (this.state.activeSetting === 5) {
                                return (
                                    <DeliveryHistory editedAllowed={false} />
                                );
                            }
                        })()}
                    </div>
                </div>
            </div>
        );
    }
}

export default AccountSettings;
