import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import addNotification from '../../../Ui/Notifications';

import Button from '../../../Ui/Button';
import { fetchApi } from '../../../../Services/Network';
import axios from "axios";
import { endpoint } from '../../../../Constants';

class End extends Component {
    constructor(props) {
        super(props);

        this.handleWelcome = this.handleWelcome.bind(this);
    }

    handleWelcome() {
        axios.post(`${endpoint}/api/v1/users/welcome_email/`, { clientId: this.props.user.id })
        .then(()=>{
            this.props.history.push('/');
        })

        {/*fetchApi('/admin/send-welcome', {
            method: 'POST',
            body: JSON.stringify({ clientId: this.props.user.id })
        })
            .then(() => {
                this.props.history.push('/');
            })
            .catch(err => {
                console.error(err);
                addNotification("Echec de l'envoie du mail", 'danger');
            });*/}
    }

    render() {
        return (
            <div className="StartPage">
                <p className="subTitle">C'est terminé !</p>
                <h2>Le client a bien été créé </h2>
                <Link to="/">
                    <Button
                        style={{ display: 'inline-block' }}
                        className="mt-24"
                        onClick={this.handleWelcome}
                    >
                        Envoyer un mail de bienvenue
                    </Button>
                </Link>
                <Link style={{ marginLeft: '20px' }} to="/">
                    Retour à l'acceuil
                </Link>
            </div>
        );
    }
}

export default withRouter(End);
