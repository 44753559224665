import React, { Component } from 'react';
import { getErrorFromCode } from '../../Constants';

const generateTimes = _ => {
    let h = 0;
    const times = [];

    while (h < 24) {
        times.push(h.toString().padStart(2, '0') + ':00');
        times.push(h.toString().padStart(2, '0') + ':30');
        h++;
    }

    return times.map(t => (
        <option key={t} value={t}>
            {t}
        </option>
    ));
};

export default class TimeInput extends Component {
    render() {
        let error = null;

        if (this.props.errors[this.props.name]) {
            error = (
                <span className="error">
                    {getErrorFromCode(this.props.errors[this.props.name])}
                </span>
            );
        }

        return (
            <div className="row timeInput">
                <div className="col-md-12">
                    <div>
                        <label>{this.props.label}</label>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group timeRange">
                                        <select
                                            name={this.props.name1}
                                            value={this.props.value1}
                                            onChange={this.props.onChange}
                                            className={
                                                this.props.errors[
                                                    this.props.name1
                                                ]
                                                    ? 'form-control error'
                                                    : 'form-control'
                                            }
                                        >
                                            {generateTimes()}
                                        </select>
                                        {error}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <select
                                            name={this.props.name2}
                                            value={this.props.value2}
                                            onChange={this.props.onChange}
                                            className={
                                                this.props.errors[
                                                    this.props.name2
                                                ]
                                                    ? 'form-control error'
                                                    : 'form-control'
                                            }
                                        >
                                            {generateTimes()}
                                        </select>
                                        {error}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group timeRange">
                                        <select
                                            name={this.props.name3}
                                            value={this.props.value3}
                                            onChange={this.props.onChange}
                                            className={
                                                this.props.errors[
                                                    this.props.name3
                                                ]
                                                    ? 'form-control error'
                                                    : 'form-control'
                                            }
                                        >
                                            {generateTimes()}
                                        </select>
                                        {error}
                                        <span className="optionnal">
                                            Optionnelles
                                        </span>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <select
                                            name={this.props.name4}
                                            value={this.props.value4}
                                            onChange={this.props.onChange}
                                            className={
                                                this.props.errors[
                                                    this.props.name4
                                                ]
                                                    ? 'form-control error'
                                                    : 'form-control'
                                            }
                                        >
                                            {generateTimes()}
                                        </select>
                                        {error}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
