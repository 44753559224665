import React from 'react';
import { connect } from 'react-redux';
import DeliveryLocation from '../../Ui/DeliveryLocation';
import Input from '../../Ui/Input';
import SpinnerLoading from '../../Ui/SpinnerLoading';
import Button from '../../Ui/Button';
import { fetchApi } from '../../../Services/Network';
import StandaloneMapsSearchBox from '../../Ui/StandaloneMapsSearchBox';
import { show } from '../../Ui/Modals';
import addNotification from '../../Ui/Notifications';
import TimeInput from '../../Ui/TimeInput';
import axios from "axios";
import { endpoint } from '../../../Constants';

const DeliveryForm = props => (
    <div className="sidecontent mb-16">
        <div className="sideLayoutInput">
            <form onSubmit={props.handleSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <Input
                            type="text"
                            name="name"
                            value={props.name}
                            placeholder="Nom du site"
                            label="NOM DU SITE DE LIVRAISON"
                            errors={props.errors}
                            onChange={props.handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <div>
                                <label htmlFor="address">Adresse du site</label>
                                <StandaloneMapsSearchBox
                                    initialValue={props.address}
                                    onPlacesChanged={({
                                        address,
                                        city,
                                        zip_code,
                                        latitude,
                                        longitude
                                    }) => {
                                        props.handleSet({
                                            address,
                                            city,
                                            zip_code,
                                            latitude,
                                            longitude
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <Input
                            type="text"
                            name="zip_code"
                            value={props.zip_code}
                            placeholder="Code Postal"
                            label="Code Postal"
                            errors={props.errors}
                            onChange={props.handleChange}
                        />
                    </div>
                    <div className="col-md-8">
                        <Input
                            type="text"
                            name="city"
                            value={props.city}
                            placeholder="Ville"
                            label="Ville"
                            errors={props.errors}
                            onChange={props.handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <Input
                            type="text"
                            name="first_name"
                            value={props.first_name}
                            placeholder="Prénom"
                            label="Prénom"
                            errors={props.errors}
                            onChange={props.handleChange}
                        />
                    </div>
                    <div className="col-md-8">
                        <Input
                            type="text"
                            name="last_name"
                            value={props.last_name}
                            placeholder="Nom"
                            label="Nom"
                            errors={props.errors}
                            onChange={props.handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Input
                            type="text"
                            name="telephone"
                            value={props.telephone}
                            placeholder="Téléphone"
                            label="Téléphone"
                            errors={props.errors}
                            onChange={props.handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Input
                            type="text"
                            name="emails"
                            value={props.emails}
                            placeholder="Email"
                            label="Email"
                            errors={props.errors}
                            onChange={props.handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Input
                            type="text"
                            name="delivery_instructions"
                            value={props.delivery_instructions}
                            placeholder="Etage, digicode, bâtiment, ..."
                            label="INSTRUCTIONS (Code d'accès, étage etc.)"
                            errors={props.errors}
                            onChange={props.handleChange}
                        />
                    </div>
                </div>
                <TimeInput
                    label="HORAIRES D'OUVERTURE"
                    label2="AUTRES (OPTIONNELLES)"
                    errors={props.errors}
                    onChange={props.handleChange}
                    name1="first_opening_hours"
                    value1={props.first_opening_hours}
                    name2="first_closing_hours"
                    value2={props.first_closing_hours}
                    name3="second_opening_hours"
                    value3={props.second_opening_hours}
                    name4="second_closing_hours"
                    value4={props.second_closing_hours}
                />
                
                <div className="row">
                    <div className="col-md-12">
                        <Input
                            type="text"
                            name="workforce"
                            value={props.workforce}
                            placeholder="Nombre de personnes"
                            label="EFFECTIF DU SITE"
                            errors={props.errors}
                            onChange={props.handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Button
                            style={{ display: 'inline-block' }}
                            loading={props.loading}
                        >
                            Enregistrer
                        </Button>
                        {props.canDelete === false || (
                            <button
                                className="link red"
                                style={{ marginLeft: '24px' }}
                                type="button"
                                onClick={props.handleDelete}
                            >
                                Supprimer
                            </button>
                        )}
                        <button
                            type="button"
                            className="link"
                            style={{ marginLeft: '24px' }}
                            onClick={props.handleCancel}
                        >
                            Annuler
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
);

class Delivery extends React.Component {
    constructor() {
        super();
        this.state = {
            locations: [],
            orgUser: null,
            edit: null, // Null = no edit, -1 = new, id = _id
            loading: true,
            name: '',
            address: '',
            zip_code: '',
            city: '',
            delivery_instructions: '',
            first_opening_hours: '',
            first_closing_hours: '',
            second_opening_hours: '',
            second_closing_hours: '',
            workforce: '',
            latitude: 0,
            longitude: 0,
            first_name: '',
            last_name: '',
            telephone: '',
            emails: []
        };

        this.errors = {};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.getData = this.getData.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    async getData() {
        let locations;
        let orgUser;
        axios.get(`${endpoint}/api/delivery/locations_client/${this.props.id||
                this.props.user.organization.id}`, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
        .then(res => {
            locations = res.data
            this.setState({ locations: locations, loading: false });
        })

        axios.get(`${endpoint}/api/v1/users/user_data/${this.props.id||
                this.props.user.organization.id}`, {headers:{
                            'Authorization': `Token ${localStorage.getItem('apikey')}`
                            }
                        })
        .then(res => {
            locations = res.data.delivery_locations
            orgUser = res.data
            this.setState({ locations: locations, loading: false, orgUser: orgUser});
            console.log(this.state)
        })

    }

    handleChange(e) {
        console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.errors = {};

        this.setState({ loading: true });

        const {
            name,
            address,
            zip_code,
            city,
            delivery_instructions,
            first_opening_hours,
            first_closing_hours,
            second_opening_hours,
            second_closing_hours,
            workforce,
            latitude,
            longitude,
            first_name,
            last_name,
            telephone,
            emails
        } = this.state;

        const handleError = err => {
            if (err.code === 400) {
                Object.keys(err.body).forEach(k => {
                    this.errors[k] = err.body[k];
                });

                this.setState({ loading: false });
            }
        };

        const body = {
            name,
            address,
            zip_code,
            city,
            delivery_instructions,
            workforce,
            first_opening_hours,
            first_closing_hours,
            second_opening_hours,
            second_closing_hours,
            latitude,
            longitude,
            first_name,
            last_name,
            telephone,
            emails
        };

        if (this.state.edit === -1) {
            // We will post
            axios.post(`${endpoint}/api/delivery/locations_org/${this.props.id ||
                    this.props.user.organization.id}/${localStorage.getItem('token')}`, body, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
                .then(() => {
                    this.getData().then(() =>
                        this.setState({ loading: false, edit: null })
                    );
                })
                .catch(handleError);
        } else {
            axios.put(`${endpoint}/api/delivery/locations_edition/edit/${this.state.edit}/`, body, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
                .then(() => {
                    this.getData().then(() =>
                        this.setState({ loading: false, edit: null })
                    );
                })
                .catch(handleError);
        }
    }

    handleNew() {
        if (this.state.edit === -1) {
            return this.setState({ edit: null });
        }
        let emails = this.state.orgUser.email.split('/')
        this.setState({
            name: '',
            address: '',
            zip_code: '',
            city: '',
            delivery_instructions: '',
            first_opening_hours: '',
            first_closing_hours: '',
            second_opening_hours: '',
            second_closing_hours: '',
            workforce: '',
            latitude: 0,
            longitude: 0,
            first_name: this.state.orgUser.first_name,
            last_name: this.state.orgUser.last_name,
            telephone: this.state.orgUser.phone,
            emails: emails,
            edit: -1
        });
    }

    handleEdit(idf) {
        const location = this.state.locations.find(({ id }) => id === idf);
        if (idf === this.state.edit) {
            return this.setState({ edit: null });
        }

        this.setState({
            name: location.name,
            address: location.address,
            zip_code: location.zip_code,
            city: location.city,
            delivery_instructions: location.delivery_instructions,
            first_opening_hours: location.first_opening_hours,
            first_closing_hours: location.first_closing_hours,
            second_opening_hours: location.second_opening_hours,
            second_closing_hours: location.second_closing_hours,
            workforce: location.workforce.toString(),
            latitude: location.latitude,
            longitude: location.longitude,
            first_name: location.first_name,
            last_name: location.last_name,
            telephone: location.telephone,
            emails: location.emails,
            edit: idf
        });
    }

    handleDelete(id) {
        show(
            'Êtes-vous sûrs de vouloir supprimer ce site de livraison?',
            'delete',
            _ => {
                axios.delete(`${endpoint}/api/delivery/locations_org/delete/${id}/`, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
                    .then(res => {
                        if (res.status === 200) {
                            addNotification('Site de livraison supprimé');
                            this.getData().then(_ =>
                                this.setState({ loading: false, edit: null })
                            );
                        }
                    })
                    .catch(err => {
                        if (err.code === 409) {
                            addNotification(
                                'Impossible de supprimer: Des commandes sont en cours pour ce site de livraison',
                                'danger'
                            );
                        } else {
                            addNotification(
                                'Erreur lors de la suppression',
                                'danger'
                            );
                        }
                    });
            }
        );
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        const deliveryForm = (
            <DeliveryForm
                {...this.state}
                errors={this.errors}
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
                handleSet={s => this.setState(s)}
                handleCancel={() => this.setState({ edit: null })}
                handleDelete={_ => this.handleDelete(this.state.edit)}
                canDelete={this.state.locations.length > 1}
            />
        );

        if (this.state.loading) {
            return (
                <div style={{ height: '200px' }}>
                    <SpinnerLoading />
                </div>
            );
        }

        return (
            <div>
                {this.state.locations.map(l =>
                (
                    <div key={l.id}>
                        <DeliveryLocation
                            {...l}
                            position={{lat: l.latitude, lng: l.longitude}}
                            handleEdit={this.handleEdit}
                            edit={this.state.edit}
                            selectable={this.props.selectable || false}
                            selected={this.props.selected}
                            onSelect={this.props.onSelect}
                        />
                        {l.id !== this.state.edit || deliveryForm}
                    </div>
                ))}
                {this.state.edit !== -1 || deliveryForm}
                <div className="addDeliveryLocation" onClick={this.handleNew}>
                    <i className="fas fa-plus blue" />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user
});

export default connect(mapStateToProps)(Delivery);
