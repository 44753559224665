import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import dateFormat from 'dateformat';

/* COMPONENTS IMPORT */

import DeliveryLocation from '../../Ui/DeliveryLocation';
import FrequenceSelector from '../../Ui/Tunnel/Product/Components/FrequenceSelector';
import FrequencePromptlySelector from '../../Ui/Tunnel/Product/Components/FrequencePromptlySelector';
import Calendar from '../../Ui/Tunnel/Product/Components/Calendar';
import Button from '../../Ui/Button';
import Switch from '../../Ui/Switch';
import { show } from '../../Ui/Modals';
import ValidationButtons from './ValidationButtons';
import addNotification from '../../Ui/Notifications';
import {Calendar as CalendarLib} from 'react-calendar';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
/* STYLE IMPORT */

import '../../../Assets/css/Pages/OrderReview/ProductOrderReview.css';

class ProductOrderReview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            biological: true,
            subscription_end_date: this.props.order.subscription_end_date
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            biological: Object.values(this.props.order.packages).every(e => {
                return e.every(e => {
                    return e.package.bio === true;
                });
            })
        });
    }


    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
        this.setState({ edited: true });
    }

    render() {
        return (
            <div className="ProductOrderReview">
                <div className="row Header">
                    <div className="col-md-12">
                        <h1>{this.props.order.service.name}</h1>
                        {(this.props.order.status !== 'WAITING' &&
                            ((!this.props.order.disabled_date_start && !this.props.order.disabled_date_end) ||
                                (!!this.props.order.disabled_date_start &&
                                    !!this.props.order.disabled_date_end &&
                                    new Date(this.props.order.disabled_date_end) < new Date()))) ? (
                                <span>
                                    <Button
                                        onClick={() =>
                                            show(
                                                'Sélectionnez votre intervalle de suspension',
                                                'date-interval',
                                                interval => {
                                                    if (interval.length < 2) {
                                                        addNotification(
                                                            'Veuillez sélectionner un intervalle de temps.',
                                                            'danger'
                                                        );
                                                    } else {
                                                        this.props.handleSuspendOrder(interval);
                                                    }
                                                }
                                            )
                                        }
                                        disabled={this.props.order.status !== 'ACTIVE'}
                                    >
                                        Suspendre temporairement
                                    </Button>
                                </span>
                            ) : this.props.order.status === 'WAITING' ? (<div />) : (
                                <span>
                                    <Button
                                        onClick={() =>
                                            show(
                                                'Voulez vous vraiment annuler la suspension ?',
                                                'info',
                                                () => {
                                                    this.props.handleUnSuspendOrder();
                                                },
                                                () => {
                                                    console.log('close');
                                                }
                                            )
                                        }
                                    >
                                        <div className="re_enable">
                                            Abonnement suspendu du{' '}
                                            {dateFormat(this.props.order.disabled_date_start, 'dd/mm/yyyy')} au{' '}
                                            {dateFormat(this.props.order.disabled_date_end, 'dd/mm/yyyy')}
                                        </div>
                                        Annuler la suspension
                                    </Button>
                                </span>
                            )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h2>Gestion de l'abonnement</h2>
                        <p className="info">
                            Vous pouvez moduler votre abonnement en changeant la fréquence de livraison ou bien les
                            quantités souhaitées
                        </p>
                    </div>
                </div>
                <div className="row title-delimiter" style={{ marginTop: '24px' }}>
                    <div className="col-md-12">
                        <h4>Adresse de Livraison</h4>
                    </div>
                </div>
                {this.props.user.role === 'Admin' && (
                    <p>
                        <Link to={`/customer/${this.props.order.organisation}`}>
                            <button className="customer_button">Fiche du client</button>
                        </Link>
                    </p>
                )}
                <div className="row">
                    <div className="col-md-8">
                        <DeliveryLocation
                            selected={this.props.order.delivery_location.id}
                            editable={false}
                            position={{lat: this.props.order.delivery_location.latitude, lng: this.props.order.delivery_location.longitude}}
                            {...this.props.order.delivery_location}
                        />
                    </div>
                </div>
                {this.props.user.role === 'Admin' && this.props.order.delivery_frequency !== "promptly" && (
                    <div className="row" style={{ marginTop: '24px' }}>
                        <div className="col-md-5">
                            <h4 className="black">Choisissez la date de fin d'abonnement</h4>

                            <DatePicker
                              selected={this.state.subscription_end_date ? new Date(this.state.subscription_end_date) : null}
                              onChange={e => {
                                    this.props.handleChange({
                                        target: {
                                            name: 'subscription_end_date',
                                            value: e
                                        }
                                    })
                                    this.state.subscription_end_date = e
                                }
                                }
                              minDate={new Date()}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="Select a date"
                            />
                        </div>
                    </div>
                )}
                <div className="row title-delimiter">
                    <div className="col-md-12">
                        <h4>Fréquence de livraison</h4>
                    </div>
                </div>
                <FrequenceSelector
                    available_frequencies={this.props.order.service.available_frequencies}
                    frequenceSelected={this.props.order.delivery_frequency}
                    setFrequence={this.props.setFrequence}
                />
                <div className="row title-delimiter" style={{display: this.props.order.service.id === "5d8daf5ba08e4e325f24f699" && this.props.user.role === 'Client' ? 'none' : 'block'}}>
                    <div className="col-md-6">
                        <h4>Planning de livraison</h4>
                        <p className="sub-title">Modifier votre semaine de prestation Toutes les semaines</p>
                    </div>
                    {(() => {
                        if (
                            this.props.order.service.packages.find(e => {
                                return e.bio === true;
                            })
                        ) {
                            return (
                                <div className="col-md-6" style={{ marginTop: '3px' }}>
                                    <span className="bio-title">Agriculture Biologique</span>
                                    <Switch
                                        handleChange={e => {
                                            if (window.confirm('Cela supprimera tout les packages sélectionnés')) {
                                                this.handleChange(e);
                                                this.props.handleReset();
                                            }
                                        }}
                                        name="biological"
                                        value={this.state.biological}
                                        green={true}
                                    />
                                </div>
                            );
                        }
                    })()}
                </div>

                {(() => {
                    if (this.props.order.delivery_frequency === 'promptly') {
                        return (
                            <FrequencePromptlySelector
                                servicePackages={this.props.order.service.packages} // Packages du service
                                activePackages={this.props.order.packages} // Packages sélectionné par l'utilisateur
                                delivery_date={this.props.order.delivery_date} // Date ponctuelle de livraison
                                handleChange={this.props.handleChange} // Handle Change
                                handlePriceChange={this.props.handlePriceChange}
                                handleQuantityChange={this.props.handleQuantityChange} // Fonction pour changer la quantité
                                handleDelete={this.props.handleDelete} // Fonction pour supprimer un package
                                addPackages={this.props.addPackages} // Fonction pour ajouter un package
                                biological={this.state.biological} // Boolean agriculure biologique
                                serviceSapin={this.props.order.service.id === "5d8daf5ba08e4e325f24f699" && this.props.user.role === 'Client'}
                            />
                        );
                    }
                    return (
                        <Calendar
                            servicePackages={this.props.order.service.packages} // Packages du service
                            activePackages={this.props.order.packages} // Packages sélectionné par l'utilisateur
                            addPackages={this.props.addPackages} // Fonction pour ajouter un/des packages
                            handleQuantityChange={this.props.handleQuantityChange} // Fonction pour changer la quantité
                            handleDelete={this.props.handleDelete} // Fonction pour supprimer un package
                            biological={this.state.biological} // Boolean agriculure biologique
                            handlePriceChange={this.props.handlePriceChange}
                            mondayLock={this.props.user.role !== 'Admin' && this.props.order.packages[0].length === 0}
                        />
                    );
                })()}
                {<div className="row footer">
                    <div className="col-md-12">
                        <ValidationButtons {...this.props} />
                    </div>
                </div>}
            </div>
        );
    }
}

export default withRouter(ProductOrderReview);