import React, { Component } from 'react';

/* COMPONENTS IMPORT */

import Switch from '../../Switch';
import Button from '../../Button';
import FrequencePromptlySelector from './Components/FrequencePromptlySelector';

/* CSS IMPORT */

import '../../../../Assets/css/Ui/Tunnel/Product/FrequencePromptly.css';

export default class FrequencePromptly extends Component {
    constructor(props) {
        super(props);

        this.errors = {};

        this.state = {
            biological: false
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value, edited: true });
    }

    getPrice = () => {
        let price = 0;
        for (let index = 0; index < this.props.activePackages.length; index++) {
            for (
                let index2 = 0;
                index2 < this.props.activePackages[index].length;
                index2++
            ) {
                price +=
                    this.props.activePackages[index][index2].package.price *
                    this.props.activePackages[index][index2].quantity;
            }
        }
        return price;
    };

    render() {
        return (
            <div className="FrequencePromptly">
                <p className="catchSentence">C'est parti !</p>
                <h2>
                    Qu'est ce qui vous ferait plaisir ?
                    {this.props.servicePackages.find(e => { return e.bio === true }) && (
                        <React.Fragment>
                            <span className="bio-title">Agriculture Biologique</span>
                            <Switch
                                handleChange={e => {
                                    this.handleChange(e);
                                    this.props.handleReset();
                                }}
                                name="biological"
                                value={this.state.biological}
                                green={true}
                            />
                        </React.Fragment>
                    )}
                </h2>

                <div style={{ marginTop: '24px', paddingLeft: '16px' }}>
                    <FrequencePromptlySelector
                        servicePackages={this.props.servicePackages} // Packages du service
                        activePackages={this.props.activePackages} // Packages sélectionné par l'utilisateur
                        delivery_date={this.props.delivery_date || new Date()} // Date ponctuelle de livraison
                        handleChange={this.props.handleChange} // Handle Change
                        handleQuantityChange={this.props.handleQuantityChange} // Fonction pour changer la quantité
                        handleDelete={this.props.handleDelete} // Fonction pour supprimer un package
                        addPackages={this.props.addPackages} // Fonction pour ajouter un package
                        biological={this.state.biological} // Boolean agriculure biologique
                        // Specifique case for Sapin
                        serviceSapin={this.props.service.id === "5d8daf5ba08e4e325f24f699" && !this.props.adminMode}
                    />
                </div>
                <div className="row footer" style={{ marginTop: '40px' }}>
                    <div className="col-md-2">
                        <Button
                            onClick={this.props.handleNextPage}
                            disabled={this.props.activePackages[0].length === 0}
                        >
                            Suivant !
                        </Button>
                    </div>
                    <div className="col-md-10" style={{ paddingTop: '3px' }}>
                        <p className="price">
                            Total — {this.getPrice()}€ HT <br />
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
