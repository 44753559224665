import React from 'react';
import { fetchApi } from '../../../Services/Network';
import Input from '../../Ui/Input';
import addNotification from '../../Ui/Notifications';
import Button from '../../Ui/Button';
import { connect } from 'react-redux';
import { feedUserMeta } from '../../../Redux/Actions';
import { getErrorFromCode } from '../../../Constants';
import { endpoint } from '../../../Constants';
const passwdValidator = new RegExp(
    /(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}/
);

class Password extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            newPassword: '',
            newPasswordC: '',
            error: ''
        };

        this.errors = {};

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();

        /* Handler */
        const { password, newPassword, newPasswordC } = this.state;

        this.setState({ loading: true });
        fetch(`${endpoint}/api/v1/users/auth/password/change/`, {
          method: 'POST',
          headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('token')}`
            },
          body: JSON.stringify({ old_password: password, new_password1: newPassword, new_password2: newPasswordC })
        })
            .then((res) => {
                if(res.status == 400){
                    this.setState({ error: 'PASSWORD_NOT_MATCHING' });
                    this.setState({ loading: false });
                }else{
                    this.setState({
                        loading: false,
                        edited: false,
                        password: '',
                        newPassword: '',
                        newPasswordC: '',
                        error: ''
                    });
                    addNotification('Votre mot de passe a bien été modifié ! 👍');
                }
            })
            .catch(err => {
                if (err.code === 400) {
                    Object.keys(err.body).forEach(k => {
                        this.errors[k] = err.body[k];
                    });
                }

                if (err.code === 403) {
                    this.setState({ error: 'PASSWORD_NOT_MATCHING' });
                }

                this.setState({ loading: false });
            });
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    isFormValid() {
        // Checks that each field is not blank
        const toCheck = {
            password: this.state.password,
            newPassword: this.state.newPassword
        };

        return (
            Object.keys(toCheck).every(k => this.state[k].trim().length > 0) &&
            this.state.newPassword === this.state.newPasswordC &&
            passwdValidator.test(this.state.newPassword)
        );
    }

    render() {
        const buttonDisabled = !this.isFormValid();

        let error = null;

        if (this.state.error) {
            error = (
                <div className="errorToast" style={{ marginBottom: '12px' }}>
                    {getErrorFromCode(this.state.error)
                        .split('\n')
                        .map((b, i) => (
                            <span key={i} style={{ display: 'block' }}>
                                {b}
                            </span>
                        ))}
                </div>
            );
        }

        return (
            <div className="sidecontent">
                <div className="sideLayoutInput">
                    {error}
                    <form onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-md-12">
                                <Input
                                    type="password"
                                    placeholder="Saisissez votre ancien mot de passe"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.password}
                                    name="password"
                                    label="ANCIEN MOT DE PASSE"
                                    errors={this.errors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Input
                                    type="password"
                                    placeholder="Nouveau mot de passe"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.newPassword}
                                    name="newPassword"
                                    label="Nouveau mot de passe"
                                    infoLabel="8 caractères minimum — dont : 1 majuscule, 1 chiffre, 1 caractère spécial (#@&amp;%*^ ...)"
                                    greenLabel="8 caractères minimum — dont : 1 majuscule, 1 chiffre, 1 caractère spécial (#@&amp;%*^ ...)"
                                    greenLabelValid={passwdValidator.test(
                                        this.state.newPassword
                                    )}
                                    errors={this.errors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Input
                                    type="password"
                                    placeholder="Confirmez votre mot de passe"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.newPasswordC}
                                    name="newPasswordC"
                                    label="Confirmation"
                                    errors={this.errors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Button disabled={buttonDisabled}>
                                    Enregistrer
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user
});

const mapDispatchToProps = dispatch => ({
    feedUserMeta: user => dispatch(feedUserMeta(user))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Password);
