import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Base64 } from "js-base64";

import FormLayout from "../Layout/FormLayout";
import Button from "../Ui/Button";
import Input from "../Ui/Input";
import addNotification from "../Ui/Notifications";

import { fetchApi } from "../../Services/Network";
import axios from "axios";
import { endpoint } from '../../Constants';
const passwdValidator = new RegExp(
  /(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}/
);

const emailValidator = new RegExp(
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
);

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      newPassword: "",
      newPasswordC: "",
      token: false,
      decodedToken: null
    };

    this.errors = {};

    this.decodedToken = null;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.token && this.state.decodedToken) {
        axios.post(`${endpoint}/api/password_reset/confirm/`, {
          token: this.props.match.params.token,
          password: this.state.newPassword
        })
        .then(() => {
          addNotification("Votre mot de passe est modifié ! 👍");
          this.props.history.replace("/login");
        })
        .catch(err => {
            console.log(err)
          if (err.code === 410) {
            addNotification("Votre lien de récupération a expiré !", "danger");
            return this.props.history.replace("/login");
          }
          addNotification(
            "Erreur dans la récupération de votre mot de passe !",
            "danger"
          );
          return this.props.history.replace("/login");
        });
    }
    else{
    axios.post(`${endpoint}/api/password_reset/`, { email: this.state.email })
      .then(() => {
        addNotification("Un e-mail vient de vous être envoyé ! 👍");
        this.props.history.replace("/login");
      })
      .catch(err => {
        if (err.code === 404) {
          return addNotification(
            "Cet e-mail n'est associé à aucun compte",
            "danger"
          );
        }
        addNotification("Erreur API contacter contact@peppr.fr", "danger");
      });}
  }

  isFormValid() {
    if (this.state.token && this.state.decodedToken) {
      const toCheck = {
        newPassword: this.state.newPassword,
        newPasswordC: this.state.newPasswordC
      };

      return (
        Object.keys(toCheck).every(k => this.state[k].trim().length > 0) &&
        this.state.newPassword === this.state.newPasswordC &&
        passwdValidator.test(this.state.newPassword)
      );
    }
    // Checks that each field is not blank
    return ["email"].every(k => this.state[k].trim().length > 0);

    // You're free to add any test
  }

  componentDidMount() {
      var decoded = this.props.match.params.token
      this.setState({ token: true, decodedToken: decoded });
  }

  render() {
    const buttonDisabled = !this.isFormValid();

    if (this.state.token && this.state.decodedToken) {
      return (
        <FormLayout>
          <div className="Form login">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <h2>Mot de passe oublié ?</h2>
                  <h3>Indiquer ci dessous votre nouveau mot de passe :</h3>
                </div>
              </div>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <Input
                      type="password"
                      placeholder="Nouveau mot de passe"
                      className="form-control"
                      onChange={this.handleChange}
                      value={this.state.newPassword}
                      name="newPassword"
                      label="Nouveau mot de passe"
                      infoLabel="8 caractères minimum — dont : 1 majuscule, 1 chiffre, 1 caractère spécial (#@&amp;%*^ ...)"
                      greenLabel="8 caractères minimum — dont : 1 majuscule, 1 chiffre, 1 caractère spécial (#@&amp;%*^ ...)"
                      greenLabelValid={passwdValidator.test(
                        this.state.newPassword
                      )}
                      errors={this.errors}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Input
                      type="password"
                      placeholder="Confirmez votre mot de passe"
                      className="form-control"
                      onChange={this.handleChange}
                      value={this.state.newPasswordC}
                      name="newPasswordC"
                      label="Confirmation"
                      errors={this.errors}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Button disabled={buttonDisabled}>Enregistrer</Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </FormLayout>
      );
    }

    return (
      <FormLayout>
        <div className="Form login">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <h2>Mot de passe oublié ?</h2>
                <h3>
                  Pas de panique... un lien de réinitialisation de mot de passe
                  vous à été envoyé par mail.
                </h3>
              </div>
            </div>
          </div>
          <form onSubmit={this.handleSubmit}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <Input
                    name="email"
                    type="email"
                    label="Mail"
                    value={this.state.email}
                    onChange={this.handleChange}
                    placeholder="email@mail.com"
                    errors={this.errors}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Button
                    disabled={buttonDisabled}
                    loading={this.state.loading}
                  >
                    Envoyer Email
                  </Button>
                </div>
                <div className="col-md-6" style={{ textAlign: "right" }}>
                  <Link
                    to="/login"
                    style={{
                      lineHeight: "48px"
                    }}
                  >
                    Connectez vous
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </FormLayout>
    );
  }
}
