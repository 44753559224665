import React, { Component } from 'react';
import { Link } from 'react-router-dom';

/* COMPONENTS IMPORT */

import DeliveryLocation from '../../Ui/DeliveryLocation';
import ServiceBox from '../../Ui/ServiceBox';
import { FreeServiceBox } from '../../Ui/FreeServiceBox';

/* Service IMPORT */

import { getFreePackages } from '../../../Constants';

/* STYLE IMPORT */

import '../../../Assets/css/Pages/OrderReview/ServiceOrderReview.css';
import ValidationButtons from './ValidationButtons';
import Calendar from 'react-calendar';

export default class ServiceOrderReview extends Component {

    render() {
        return (
            <div className="ServiceOrderReview">
                <div className="row Header">
                    <div className="col-md-12">
                        <h1>{this.props.order.service.name}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h2>Gestion de l'abonnement</h2>
                    </div>
                </div>
                <div
                    className="row title-delimiter"
                    style={{ marginTop: '24px' }}
                >
                    <div className="col-md-12">
                        <h4>Adresse de Livraison</h4>
                    </div>
                </div>
                {this.props.user.role === 'Admin' && (
                    <p>
                    <Link to={`/customer/${this.props.order.organization}`}>
                        <button className="customer_button">Fiche du client</button>
                    </Link>
                    </p>
                )}
                <div className="row">
                    <div className="col-md-8">
                        <DeliveryLocation
                            selected={this.props.order.delivery_location.id}
                            editable={false}
                            position={{lat: this.props.order.delivery_location.latitude, lng: this.props.order.delivery_location.longitude}}
                            {...this.props.order.delivery_location}
                        />
                    </div>
                </div>
                <div className="row title-delimiter">
                    <div className="col-md-12">
                        <h4>Choix des packages</h4>
                    </div>
                </div>
                <div className="row">
                    {(() => {
                        return this.props.order.service.packages.map(
                            (element, index) => {
                                return (
                                    <div
                                        className="col-md-2 service-container"
                                        key={element.id}
                                    >
                                        <ServiceBox
                                            packages={this.props.order.packages[0]}
                                            servicePackage={element}
                                            handleQuantityChange={
                                                this.props.handleQuantityChange
                                            }
                                            getQuantityLocationById={
                                                this.props
                                                    .getQuantityLocationById
                                            }
                                        />
                                    </div>
                                );
                            }
                        );
                    })()}
                </div>
                <div className="row title-delimiter">
                    <div className="col-md-12">
                        <h4>Les options</h4>
                    </div>
                </div>
                <div className="row">
                    {(() => {
                        if (
                            this.props.order.service.free_packages.length === 0
                        ) {
                            return (
                                <div className="col-md-12">
                                    <p>Aucunes options pour ce service</p>
                                </div>
                            );
                        }
                        return getFreePackages(
                            this.props.order.service.free_packages
                        ).map((element, index) => {
                            return (
                                <div
                                    key={element.id}
                                    className="col-md-2 service-container"
                                >
                                    <FreeServiceBox
                                        freeService={element}
                                        active={true}
                                    />
                                </div>
                            );
                        });
                    })()}
                </div>
                {this.props.user.role !== 'Admin' || (
                    <div>
                        <div className="row title-delimiter">
                            <div className="col-md-12">
                                <h4>Date de d&eacute;but</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Calendar
                                    value={
                                        new Date(this.props.order.start_date)
                                    }
                                    onChange={e =>
                                        this.props.handleChange({
                                            target: {
                                                name: 'start_date',
                                                value: e
                                            }
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                )}
                <div className="row footer">
                    <div className="col-md-12">
                        <ValidationButtons {...this.props} />
                    </div>
                </div>
            </div>
        );
    }
}
