import React from 'react';
import { connect } from 'react-redux';
import { fetchApi } from './Network';
import { feedUserMeta } from '../Redux/Actions';
import axios from "axios";
import { endpoint } from '../Constants';

const AuthWrapper = (WrappedComponent, AdminComponent) => {
    class AuthWrapped extends React.Component {
        componentDidMount() {
            // Do check
            if (localStorage.getItem('token') !== null){
                axios.post(`${endpoint}/api/v1/users/user_data/`, {token: localStorage.getItem('token')}, {headers:{
                            'Authorization': `Token ${localStorage.getItem('apikey')}`
                            }
                        })
                .then(res => {
                    this.props.feedUserMeta(res.data);
                })
            }else{
                this.props.history.push('/login');
            }
        }

        render() {
            return this.props.isLoggedIn ? (
                this.props.role === 'Admin' ? (
                    <AdminComponent {...this.props} />
                ) : (
                    <WrappedComponent {...this.props} />
                )
            ) : null;
        }
    }

    const mapStateToProps = ({ auth }) => ({
        isLoggedIn: auth.isLoggedIn,
        role: auth.user ? auth.user.role : null
    });

    const mapDispatchToProps = dispatch => ({
        feedUserMeta: user => dispatch(feedUserMeta(user))
    });

    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(AuthWrapped);
};

export default AuthWrapper;
