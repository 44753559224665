import React, { Component } from 'react';
import { fetchApi } from '../../../../Services/Network';

export default class Stats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      stats: null
    };
  }

  componentWillMount() {
    fetchApi('/admin/statistiques').then(res => this.setState({ stats: res, loading: false }));
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="sidecontent">
          <div className="row">
            <div className="col-md-12">
              <p>Chargement des données ....</p>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="sidecontent">
        <div className="row">
          <div className="col-md-12">
            <p>
              <strong>Nombres de client actifs : </strong> {this.state.stats.countsActiveCustomers}
            </p>
            <p>
              <strong>CA ACTUEL LPF : </strong> {this.state.stats.actualLPFMoney} €
            </p>
            <p>
              <strong>CA ACTUEL PEPPR : </strong> {this.state.stats.actualPepprMoney} €
            </p>
            <p>
              <strong>CA PREVISIONEL LPF : </strong> {this.state.stats.previsionLPFMoney} €
            </p>
            <p>
              <strong>CA PREVISIONEL PEPPR : </strong> {this.state.stats.previsionPepprMoney} €
            </p>
            <p>
              <strong>FRUITS SEMAINE EN COURS : </strong>
            </p>

                {Object.keys(this.state.stats.fruitOfWeek).map((key, index) => (
                  <div key={index}><strong>{key}</strong> - {this.state.stats.fruitOfWeek[key]}</div>
                ))}

            <p>
              <strong>FRUITS SEMAINE SEMAINE PROCHAINE : </strong>
            </p>
                {Object.keys(this.state.stats.fruitOfNextWeek).map((key) => (
                  <div key={key}><strong>{key}</strong> - {this.state.stats.fruitOfNextWeek[key]}</div>
                ))}
          </div>
        </div>
      </div>
    );
  }
}
