import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from "axios";

/* COMPONENTS IMPORT */

import CompleteAccount from '../Ui/CompleteAccount';
import { FullFruit } from '../Ui/Fruits';
import NextDeliveryItem from '../Ui/NextDeliveryItem';
import FirstConnectionModal from '../Ui/FirstConnectionModal';
import { endpoint } from '../../Constants';
/* SERVICES IMPORT */

import { fetchApi } from '../../Services/Network';

/* CSS IMPORT */

import '../../Assets/css/Pages/homePage.css';

class Landing extends React.Component {
  constructor() {
    super();

    this.state = {
      services: [],
      orders: []
    };
  }

  /*componentDidMount() {
    fetchApi('/services').then(services =>
      this.setState({ services: services })
    );

    fetchApi(`/orders/${this.props.user.organization._id}`).then(res =>
      this.setState({ delivery_locations: res.delivery_locations })
    );
  }*/

  componentDidMount() {
    axios.get(`${endpoint}/api/service/`).then(services =>
      this.setState({ services: services.data })
    );
    axios.get(`${endpoint}/api/delivery/orders_client/${localStorage.getItem('token')}`, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}}).then(res =>
      {this.setState({ orders: res.data })}
    );
  }

  render() {
    const deliveryContainer = () => (
      <div className="row" key="deliveryContainer">
        <div className="col-md-12">
          <h2 className="grey0 mt-24 mb-24">Vos Prochaines Livraisons</h2>
          {this.state.orders.filter(({ status }) => status !== 'ABORTED')
          .filter(
                  ({ delivery_frequency, delivery_date }) =>
                    !(
                      delivery_frequency === 'promptly' &&
                      new Date(delivery_date).setHours(23, 59, 59) < new Date()
                    )
          )
          .slice(0, 5)
          .map((order, index) => (
            <div key={order.id}>
              <span className="deliveryLocations">
                <h4>{order.delivery_location.name}</h4>
                {order.delivery_location.address}
              </span>
              <NextDeliveryItem key={order.id} {...order}/>
              <Link
                to="/"
                style={{
                  marginBottom: '40px',
                  marginTop: '24px',
                  display: 'block',
                  textDecoration: 'underline'
                }}
              >
                Voir les prochaines livraisons du {order.delivery_location.name}
              </Link>
            </div>
          ))}
        </div>
      </div>
    );

    const servicesContainer = () => (
      <div className="row" key="serviceContainer">
        <div className="col-md-12">
          <h2 className="grey0 mt-24 mb-24">
            Decouvrez tous les services Pepp’r !
          </h2>
          <div className="row fullfruit-container">
            {this.state.services.map(s => (
              <div key={s.tag_id} className="col-md-3">
                <FullFruit
                  title={s.catch_phrase}
                  product={s.name}
                  src={s.image}
                  onClick={() => this.props.history.push('/tunnel/' + s.id)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );

    return (
      <div className="homePage">
        {/*!this.props.user.last_connection ? <FirstConnectionModal /> : null*/}
        <div className="row">
          <div className="col-md-12">
            <h1>Bonjour {this.props.user.first_name} !</h1>
            <p className="subtitle">
              Voici votre fil d'actualité,
              <br /> vous retrouverez tous nos services sans engagement ainsi
              que le suivi de vos abonnements.
            </p>
          </div>
        </div>
        {(() => {
          if (!this.props.user.accountCompleted) {
            return <CompleteAccount />;
          }
        })()}

        {(() => {
          for (
            let index = 0;
            index < this.state.orders.length;
            index++
          ) {
            if (
              this.state.orders
                .filter(({ status }) => status !== 'ABORTED')
                .filter(
                  ({ delivery_frequency, delivery_date }) =>
                    !(
                      delivery_frequency === 'promptly' &&
                      new Date(delivery_date) < new Date()
                    )
                ).length > 0
            ) {
              const result = [];
              result.push(deliveryContainer());
              result.push(servicesContainer());
              return result;
            }
          }

          const result = [];
          result.push(servicesContainer());
          return result;
        })()}
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

export default connect(mapStateToProps)(Landing);
