import React from 'react';

import Input from '../../../Ui/Input';
import TextArea from '../../../Ui/TextArea';
import FileInput from '../../../Ui/FileInput';
import Switch from '../../../Ui/Switch';
import {
    FreeServiceBox,
    FreeServiceBoxSlider
} from '../../../Ui/FreeServiceBox';
import Checkbox from '../../../Ui/Checkbox';
import Button from '../../../Ui/Button';
import addNotification from '../../../Ui/Notifications';
import { FullFruit, SubsFruit, LongFruit } from '../../../Ui/Fruits';

import { getAllFreePackages } from '../../../../Constants';
import { fetchApi } from '../../../../Services/Network';
import { endpoint } from '../../../../Constants';

import '../../../../Assets/css/Pages/Admin/services.css';
import axios from "axios"

const Package = props => (
    <div className="col-md-6">
        <div className="product-box mb-32">
            <img
                onClick={props.handleDeletePackage}
                src="/assets/img/delete.svg"
                alt="Delete Icon"
                className="delete-button"
            />
            <div className="row">
                <div className="col-md-12">
                    <Input
                        type="text"
                        placeholder="Nom du service"
                        label="NOM DU produit"
                        className="form-control"
                        onChange={props.handleChangePackage}
                        value={props.name}
                        name="name"
                        errors={{}}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <Input
                        type="text"
                        placeholder="Nom mapotempo"
                        label="NOM mapotempo"
                        className="form-control"
                        onChange={props.handleChangePackage}
                        value={props.mapotempo_name}
                        name="mapotempo_name"
                        errors={{}}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <FileInput
                        label="IMAGE DU produit (280px*160px)"
                        fileName={
                            props.image
                                ? props.image.name
                                    ? props.image.name
                                    : props.image.split('/').pop()
                                : ''
                        }
                        className="form-control"
                        onChange={e => {
                            if (!e.target.files[0]) {
                                return;
                            }

                            const file = e.target.files[0];
                            props.handleChangePackage({
                                target: {
                                    name: 'image',
                                    value: file
                                }
                            });
                        }}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <TextArea
                        rows="2"
                        placeholder="Description"
                        label="Description"
                        className="form-control"
                        onChange={props.handleChangePackage}
                        value={props.description}
                        name="description"
                        errors={{}}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <Input
                        type="text"
                        placeholder="Prix"
                        label="Prix"
                        className="form-control"
                        onChange={props.handleChangePackage}
                        value={props.price}
                        name="price"
                        errors={{}}
                    />
                </div>
                <div style={{ marginTop: '14px' }}>
                    <div className="col-md-2">
                        <p style={{ lineHeight: '60px' }}>&euro; HT / </p>
                    </div>
                    <div className="col-md-4">
                        <Input
                            type="text"
                            className="form-control"
                            onChange={props.handleChangePackage}
                            value={props.unit_name}
                            name="unit_name"
                            placeholder={
                                props.type === 'cafe' ? 'café' : 'mois'
                            }
                            errors={{}}
                        />
                    </div>
                </div>
            </div>
            {props.type !== 'cafe' || (
                <div>
                    <div className="row">
                        <div className="col-md-4">
                            <Input
                                type="text"
                                placeholder="Prix mois"
                                label="Soit"
                                className="form-control"
                                onChange={props.handleChangePackage}
                                value={props.price_month}
                                name="price_month"
                                errors={{}}
                            />
                        </div>
                        <div style={{ marginTop: '14px' }}>
                            <div className="col-md-8">
                                <p style={{ lineHeight: '60px' }}>
                                    &euro; HT / Mois
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Input
                                type="number"
                                placeholder="Maximum Kg autorisés"
                                label="Quantité"
                                className="form-control"
                                onChange={props.handleChangePackage}
                                value={props.quantity}
                                name="quantity"
                                errors={{}}
                            />
                        </div>
                    </div>
                </div>
            )}
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label>TVA</label>
                        <select
                            className="form-control"
                            name="quickbooks_taxcode"
                            onChange={props.handleChangePackage}
                            value={props.quickbooks_taxcode}
                        >
                            <option value="taxcode_20">TVA 20%</option>
                            <option value="taxcode_10">TVA 10%</option>
                            <option value="taxcode_05">TVA 5.5%</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const Options = props => (
    <div className="col-md-6">
        <div className="product-box mb-32">
            <img
                onClick={props.handleDeleteOption}
                src="/assets/img/delete.svg"
                alt="Delete Icon"
                className="delete-button"
            />
            <div className="row">
                <div className="col-md-12">
                    <Input
                        type="text"
                        placeholder="Nom du produit"
                        label="Nom du produit"
                        className="form-control"
                        onChange={props.handleChangeOption}
                        value={props.name}
                        name="name"
                        errors={{}}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <FileInput
                        label="IMAGE DU produit (280px*160px)"
                        fileName={props.image ? props.image.name : ''}
                        className="form-control"
                        onChange={e => {
                            if (!e.target.files[0]) {
                                return;
                            }

                            const file = e.target.files[0];
                            props.handleChangeOption({
                                target: {
                                    name: 'image',
                                    value: file
                                }
                            });
                        }}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <TextArea
                        rows="2"
                        placeholder="Description"
                        label="Description"
                        className="form-control"
                        onChange={props.handleChangeOption}
                        value={props.description}
                        name="description"
                        errors={{}}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <Input
                        type="text"
                        placeholder="Nom Mapotempo"
                        label="Nom Mapotempo"
                        className="form-control"
                        onChange={props.handleChangeOption}
                        value={props.mapotempo_name}
                        name="mapotempo_name"
                        errors={{}}
                    />
                </div>
            </div>
        </div>
    </div>
);

export default class ServiceSettings extends React.Component {
    constructor() {
        super();
        this.errors = {};

        this.state = {
            id: null,
            name: '',
            type: 'cafe',
            imageFile: null,
            image: 'https://via.placeholder.com/256x160',
            available_frequencies: [],
            catch_phrase: '',
            description: '',
            next_text: '',
            packages: [],
            free_packages: [],
            options: [],
            biological: false,
            facturation: 'lpf',
            homepageTitle: '',
            homepageDescription: '',
            loading: false,

            deliveryActionName: 'mois',
            whyDisplay: false,
            whyContent: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangePackage = this.handleChangePackage.bind(this);
        this.handleDeletePackage = this.handleDeletePackage.bind(this);
        this.handleAddPackage = this.handleAddPackage.bind(this);
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value, edited: true });
    }

    handleAddPackage(bio) {
        this.setState({
            packages: [
                ...this.state.packages,
                {
                    name: '',
                    mapotempo_name: '',
                    image: null,
                    description: '',
                    price: '',
                    unit_name: '',
                    quickbooks_taxcode: 'taxcode_20',
                    bio: bio
                }
            ]
        });
    }

    handleChangePackage(e, index, bio) {
        const items = this.state.packages;
        if (bio) {
            const bioPackages = items.filter(p => p.bio);
            bioPackages[index][e.target.name] = e.target.value;
            this.setState({
                packages: [...bioPackages, ...items.filter(p => !p.bio)]
            });
        } else {
            const noBioPackages = items.filter(p => !p.bio);
            noBioPackages[index][e.target.name] = e.target.value;
            this.setState({
                packages: [...noBioPackages, ...items.filter(p => p.bio)]
            });
        }
    }

    handleDeletePackage(index, bio) {
        const items = this.state.packages;
        if (bio) {
            const bioPackages = items.filter(p => p.bio);
            bioPackages.splice(index, 1);
            this.setState({
                packages: [...bioPackages, ...items.filter(p => !p.bio)]
            });
        } else {
            const noBioPackages = items.filter(p => !p.bio);
            noBioPackages.splice(index, 1);
            this.setState({
                packages: [...noBioPackages, ...items.filter(p => p.bio)]
            });
        }
    }

    handleDeleteOption(id) {
        this.setState({
            options: this.state.options.filter((_, i) => i !== id)
        });
    }

    handleChangeOption(e, id) {
        const options = this.state.options;

        options[id][e.target.name] = e.target.value;

        this.setState({
            options: options
        });
    }

    async handleSubmit() {
        this.setState({ loading: true });
        const {
            name,
            type,
            available_frequencies,
            catch_phrase,
            description,
            next_text,
            facturation,
            homepageTitle,
            homepageDescription,
            free_packages,
            deliveryActionName,
            whyDisplay,
            whyContent
        } = this.state;

        const sendFile = file => {
            const data = new FormData();
            data.append('image', file);
            return axios.post(`${endpoint}/api/service/upload/`, data)
                .then(res => {
                    let imageUrl = res.data
                    if (res.status === 200) {
                        return Promise.resolve(`${endpoint}` + res.data.url)
                    }

                    return Promise.reject(res.status);
                })
                .catch(err => Promise.reject(err));
        };

        if (!this.state.id) {
            // New service
            const image = !!this.state.imageFile
                ? await sendFile(this.state.imageFile)
                : 'https://via.placeholder.com/256x160';
            const promises = await this.state.packages.map(async p => ({
                ...p,
                image: await sendFile(p.image)
            }));

            const packages = await Promise.all(promises);

            const optionsPromises = await this.state.options.map(async o => ({
                ...o,
                image: await sendFile(o.image)
            }));

            const options = await Promise.all(optionsPromises);
            axios.post(`${endpoint}/api/service/manage/`,
            {
                    name,
                    type,
                    image,
                    available_frequencies,
                    catch_phrase,
                    description,
                    next_text,
                    packages,
                    homepageTitle,
                    homepageDescription,
                    free_packages,
                    facturation,
                    options,
                    deliveryActionName,
                    whyDisplay,
                    whyContent
            })
                .then(() => {
                    addNotification('Le service a bien été ajouté ! 👍');
                    this.props.history.push('/services');
                })
                .catch(err => {
                    console.error(err);
                    this.setState({ loading: false });
                });
        } else {
            // Update
            const body = {
                name,
                type,
                available_frequencies,
                catch_phrase,
                homepageTitle,
                homepageDescription,
                description,
                facturation,
                free_packages,
                next_text,
                deliveryActionName,
                whyDisplay,
                whyContent
            };

            // Send new images
            if (this.state.imageFile) {
                const image = await sendFile(this.state.imageFile);
                body.image = image;
            } else {
                body.image = this.state.image;
            }

            // Upload new images

            const packages = this.state.packages;
            let options = this.state.options;
            if (typeof variable == 'undefined') {
                options = []
            }
            for (const p of packages) {
                if (typeof p.image !== 'string') {
                    try {
                        // New image to upload
                        const image = await sendFile(p.image);
                        p.image = image;
                    } catch (err) {
                        this.setState({ loading: false });
                        return alert(
                            'Une image est manquante pour un des packages'
                        );
                    }
                }
            }

            for (const o of options) {
                if (typeof o.image !== 'string') {
                    try {
                        // New image to upload
                        const image = await sendFile(o.image);
                        o.image = image;
                    } catch (err) {
                        this.setState({ loading: false });
                        return alert(
                            'Une image est manquante pour une des options'
                        );
                    }
                }
            }

            body.packages = packages; // Keep old packages
            body.options = options; // Keep old options

            axios.put(`${endpoint}/api/service/infos/${this.state.id}/`, body)
                .then(() => {
                    addNotification('Le service a bien été modifé ! 👍');
                    this.props.history.push('/services');
                })
                .catch(err => {
                    console.error(err);
                    this.setState({ loading: false });
                });
        }
    }

    componentDidMount() {
        if (this.props.match.params.id === 'new') {
            return;
        }
        axios.get(`${endpoint}/api/service/infos/${this.props.match.params.id}/`)
        .then(service =>
            {
            service = service.data
            this.setState({
                id: service.id,
                name: service.name,
                type: service.type,
                image: service.image,
                available_frequencies: service.available_frequencies,
                homepageTitle: service.homepageTitle,
                homepageDescription: service.homepageDescription,
                catch_phrase: service.catch_phrase,
                description: service.description,
                next_text: service.next_text,
                facturation: service.facturation,
                packages: service.packages.map(p => ({
                    id: p.id,
                    name: p.name,
                    mapotempo_name: p.mapotempo_name,
                    bio: p.bio,
                    image: p.image,
                    description: p.description,
                    price: p.price.toString(),
                    unit_name: p.unit_name,
                    price_month: p.price_month,
                    quantity: p.quantity,
                    quickbooks_taxcode: p.quickbooks_taxcode
                })),
                options: service.packages[0].package_options,
                free_packages: service.free_packages,
                biological: service.packages.findIndex(e => e.bio) > -1,
                deliveryActionName: service.deliveryActionName ? service.deliveryActionName : 'mois',
                whyDisplay: service.whyDisplay ? service.whyDisplay : false,
                whyContent: service.whyContent ? service.whyContent : '',
            })}
        );
    }

    render() {
        return (
            <div className="admin-services-settings">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="Header">
                            Paramètres généraux du Service
                            {(() => {
                                if (this.state.type !== 'cafe') {
                                    return (
                                        <span>
                                            <span className="bio-title">
                                                Agriculture Biologique
                                            </span>
                                            <Switch
                                                handleChange={this.handleChange}
                                                name="biological"
                                                value={this.state.biological}
                                                green={true}
                                            />
                                        </span>
                                    );
                                }
                            })()}
                        </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="general-edit">
                            <div className="row">
                                <div className="col-md-12">
                                    <Input
                                        type="text"
                                        placeholder="Nom du service"
                                        label="NOM DU SERVICE"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.name}
                                        name="name"
                                        errors={this.errors}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Type</label>
                                        <select
                                            className="form-control"
                                            name="type"
                                            value={this.state.type}
                                            onChange={e => {
                                                this.handleChange(e);
                                                this.setState({ packages: [] });
                                            }}
                                        >
                                            <option value="cafe">Cafe</option>
                                            <option value="product">
                                                Produit
                                            </option>
                                            <option value="service">
                                                Service
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Facturation</label>
                                        <select
                                            className="form-control"
                                            name="facturation"
                                            value={this.state.facturation}
                                            onChange={this.handleChange}
                                        >
                                            <option value="peppr">
                                                Pepp'r
                                            </option>
                                            <option value="lpf">
                                                Les plaisirs fruités
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <FileInput
                                        label="IMAGE DU SERVICE"
                                        fileName={
                                            this.state.image
                                                ? this.state.image.name
                                                    ? this.state.image.name
                                                    : this.state.image
                                                        .split('/')
                                                        .pop()
                                                : ''
                                        }
                                        className="form-control"
                                        onChange={e => {
                                            if (!e.target.files[0]) {
                                                return;
                                            }
                                            this.setState({
                                                imageFile: e.target.files[0],
                                                image: URL.createObjectURL(
                                                    e.target.files[0]
                                                )
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>FREQUENCE</label>
                                        <div className="row checkbox-container">
                                            <div className="col-md-6 col-sm-6 col-xs-6">
                                                <div className="checkbox-line">
                                                    <Checkbox
                                                        checked={
                                                            this.state.available_frequencies.indexOf(
                                                                'weekly'
                                                            ) !== -1
                                                        }
                                                        onChange={() => {
                                                            if (
                                                                this.state.available_frequencies.indexOf(
                                                                    'weekly'
                                                                ) !== -1
                                                            ) {
                                                                this.setState({
                                                                    available_frequencies: this.state.available_frequencies.filter(
                                                                        f =>
                                                                            f !==
                                                                            'weekly'
                                                                    )
                                                                });
                                                            } else {
                                                                this.setState({
                                                                    available_frequencies: [
                                                                        ...this
                                                                            .state
                                                                            .available_frequencies,
                                                                        'weekly'
                                                                    ]
                                                                });
                                                            }
                                                        }}
                                                    />
                                                    <label>Toutes les semaines</label>
                                                </div>
                                                <div className="checkbox-line">
                                                    <Checkbox
                                                        checked={
                                                            this.state.available_frequencies.indexOf(
                                                                'monthly'
                                                            ) !== -1
                                                        }
                                                        onChange={() => {
                                                            if (
                                                                this.state.available_frequencies.indexOf(
                                                                    'monthly'
                                                                ) !== -1
                                                            ) {
                                                                this.setState({
                                                                    available_frequencies: this.state.available_frequencies.filter(
                                                                        f =>
                                                                            f !==
                                                                            'monthly'
                                                                    )
                                                                });
                                                            } else {
                                                                this.setState({
                                                                    available_frequencies: [
                                                                        ...this
                                                                            .state
                                                                            .available_frequencies,
                                                                        'monthly'
                                                                    ]
                                                                });
                                                            }
                                                        }}
                                                    />
                                                    <label>Tous les mois</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-6">
                                                <div className="checkbox-line">
                                                    <Checkbox
                                                        checked={
                                                            this.state.available_frequencies.indexOf(
                                                                'fortnightly'
                                                            ) !== -1
                                                        }
                                                        onChange={() => {
                                                            if (
                                                                this.state.available_frequencies.indexOf(
                                                                    'fortnightly'
                                                                ) !== -1
                                                            ) {
                                                                this.setState({
                                                                    available_frequencies: this.state.available_frequencies.filter(
                                                                        f =>
                                                                            f !==
                                                                            'fortnightly'
                                                                    )
                                                                });
                                                            } else {
                                                                this.setState({
                                                                    available_frequencies: [
                                                                        ...this
                                                                            .state
                                                                            .available_frequencies,
                                                                        'fortnightly'
                                                                    ]
                                                                });
                                                            }
                                                        }}
                                                    />
                                                    <label>Tous les 15 jours</label>
                                                </div>
                                                <div className="checkbox-line">
                                                    <Checkbox
                                                        checked={
                                                            this.state.available_frequencies.indexOf(
                                                                'promptly'
                                                            ) !== -1
                                                        }
                                                        onChange={() => {
                                                            if (
                                                                this.state.available_frequencies.indexOf(
                                                                    'promptly'
                                                                ) !== -1
                                                            ) {
                                                                this.setState({
                                                                    available_frequencies: this.state.available_frequencies.filter(
                                                                        f =>
                                                                            f !==
                                                                            'promptly'
                                                                    )
                                                                });
                                                            } else {
                                                                this.setState({
                                                                    available_frequencies: [
                                                                        ...this
                                                                            .state
                                                                            .available_frequencies,
                                                                        'promptly'
                                                                    ]
                                                                });
                                                            }
                                                        }}
                                                    />
                                                    <label>Une seule fois</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <Input
                                        type="text"
                                        placeholder="Teaser carte accueil"
                                        label="TEASER CARTE ACCUEIL"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.catch_phrase}
                                        name="catch_phrase"
                                        errors={this.errors}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <TextArea
                                        rows="2"
                                        placeholder="Sous-titre carte abonnement"
                                        label="SOUS-TITRE CARTE ABONNEMENT"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.description}
                                        name="description"
                                        errors={this.errors}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <Input
                                        type="text"
                                        placeholder="Prochain(e)..."
                                        label="PROCHAIN(E) ..."
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.next_text}
                                        name="next_text"
                                        errors={this.errors}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="preview-edit">
                            <p className="preview-title">Preview</p>
                            <div className="row">
                                <div className="col-md-6 col-sm-12 col-xs-12">
                                    <FullFruit
                                        title={this.state.catch_phrase}
                                        product={this.state.name}
                                        srcPreview={this.state.image}
                                    />
                                </div>
                                <div className="col-md-6 hidden-sm hidden-xs">
                                    <SubsFruit
                                        service={{
                                            name: this.state.name,
                                            description: this.state.description
                                        }}
                                        srcPreview={this.state.image}
                                        nextDelivery="Mercredi 23 Mai"
                                    />
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: '27px' }}>
                                <div className="col-md-12">
                                    <LongFruit
                                        text={this.state.description}
                                        product={this.state.name}
                                        srcPreview={this.state.image}
                                        nextDelivery="Demain !"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-48">
                    <div className="col-md-12">
                        <h1 className="mt-0 mb-24">Homepage du Service</h1>
                    </div>
                </div>
                <div className="general-edit">
                    <div className="row">
                        <div className="col-md-12">
                            <Input
                                type="text"
                                placeholder="Titre homepage"
                                label="Titre homepage"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.homepageTitle}
                                name="homepageTitle"
                                errors={this.errors}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <TextArea
                                rows="3"
                                placeholder="Sous-titre carte abonnement"
                                label="SOUS-TITRE CARTE ABONNEMENT"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.homepageDescription}
                                name="homepageDescription"
                                errors={this.errors}
                            />
                        </div>
                    </div>
                </div>
                <div className="row mt-48">
                    <div className="col-md-12">
                        <h1 className="mt-0 mb-24">Paramètres affichage</h1>
                    </div>
                </div>
                <div className="general-edit">
                    <div className="row">
                        <div className="col-md-12">
                            <Input
                                type="text"
                                placeholder="Descriptif Livraison"
                                label="Descriptif Livraison"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.deliveryActionName}
                                name="deliveryActionName"
                                errors={this.errors}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <span>
                                <label style={{marginBottom: '25px'}}>
                                    Affichage de pourquoi ?
                                </label>
                                <Switch
                                    handleChange={this.handleChange}
                                    name="whyDisplay"
                                    value={this.state.whyDisplay}
                                    green={true}
                                />
                            </span>
                        </div>
                        <div className="col-md-9" style={{display: this.state.whyDisplay ? 'block' : 'none'}}>
                            <Input
                                type="text"
                                placeholder="Contenue de pourquoi ?"
                                label="Contenue de pourquoi ?"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.whyContent}
                                name="whyContent"
                                errors={this.errors}
                            />
                        </div>
                    </div>
                </div>
                <div className="row mt-48">
                    <div className="col-md-12">
                        <h1 className="mt-0 mb-24">Produits du Service</h1>
                    </div>
                </div>
                <div className="row">
                    {this.state.packages
                        .filter(p => !p.bio)
                        .map((p, i) => (
                            <Package
                                {...p}
                                index={i}
                                key={i}
                                handleChangePackage={e =>
                                    this.handleChangePackage(e, i, false)
                                }
                                handleDeletePackage={() =>
                                    this.handleDeletePackage(i, false)
                                }
                                type={this.state.type}
                            />
                        ))}
                    <div className="col-md-6">
                        <div
                            className="add-product-box"
                            onClick={() => this.handleAddPackage(false)}
                        >
                            <span>+</span>
                        </div>
                    </div>
                </div>
                {(() => {
                    if (this.state.biological && this.state.type !== 'cafe') {
                        return (
                            <div>
                                <div className="row mt-48">
                                    <div className="col-md-12">
                                        <h1 className="mt-0 mb-24">
                                            Produits du Service (Biologique)
                                        </h1>
                                    </div>
                                </div>
                                <div className="row">
                                    {this.state.packages
                                        .filter(p => p.bio)
                                        .map((p, i) => (
                                            <Package
                                                {...p}
                                                index={i}
                                                key={i}
                                                handleChangePackage={e =>
                                                    this.handleChangePackage(
                                                        e,
                                                        i,
                                                        true
                                                    )
                                                }
                                                handleDeletePackage={() =>
                                                    this.handleDeletePackage(
                                                        i,
                                                        true
                                                    )
                                                }
                                            />
                                        ))}
                                    <div className="col-md-6">
                                        <div
                                            className="add-product-box"
                                            onClick={() =>
                                                this.handleAddPackage(true)
                                            }
                                        >
                                            <span>+</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                })()}
                {this.state.type !== 'cafe' || (
                    <div>
                        <div className="row mt-48">
                            <div className="col-md-12">
                                <h1 className="mt-0 mb-24">Les cafés</h1>
                            </div>
                        </div>
                        <div className="row">
                            {this.state.options.map((o, i) => (
                                <Options
                                    {...o}
                                    key={i}
                                    handleChangeOption={e =>
                                        this.handleChangeOption(e, i)
                                    }
                                    handleDeleteOption={() =>
                                        this.handleDeleteOption(i)
                                    }
                                />
                            ))}
                            <div className="col-md-6">
                                <div
                                    className="add-product-box"
                                    onClick={() =>
                                        this.setState({
                                            options: [
                                                ...this.state.options,
                                                {
                                                    name: '',
                                                    image: null,
                                                    description: '',
                                                    mapotempo_name: ''
                                                }
                                            ]
                                        })
                                    }
                                >
                                    <span>+</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {this.state.type === 'product' || (
                    <div>
                        <div className="row mt-48">
                            <div className="col-md-12">
                                <h1 className="mt-0 mb-24">
                                    Les packages inclus :
                                </h1>
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: '20px' }}>
                            <div className="col-md-12">
                                <FreeServiceBoxSlider>
                                    {getAllFreePackages().map(
                                        (element, index) => (
                                            <FreeServiceBox
                                                key={index}
                                                freeService={element}
                                                active={
                                                    this.state.free_packages.findIndex(
                                                        obj =>
                                                            element.id === obj
                                                    ) > -1
                                                }
                                                handleClick={() => {
                                                    const items = this.state
                                                        .free_packages;
                                                    const isInArray = items.findIndex(
                                                        obj =>
                                                            element.id === obj
                                                    );
                                                    if (isInArray > -1) {
                                                        items.splice(
                                                            isInArray,
                                                            1
                                                        );
                                                        this.setState({
                                                            free_packages: items
                                                        });
                                                    } else {
                                                        items.push(element.id);
                                                        this.setState({
                                                            free_packages: items
                                                        });
                                                    }
                                                }}
                                            />
                                        )
                                    )}
                                </FreeServiceBoxSlider>
                            </div>
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col-md-12 mt-32">
                        <Button
                            loading={this.state.loading}
                            onClick={this.handleSubmit}
                            style={{ display: 'inline-block' }}
                        >
                            Enregistrer
                        </Button>
                        {/*<Button
                            loading={this.state.loading}
                            onClick={this.handleDelete}
                            buttonType="danger"
                            style={{display: 'inline-block', marginLeft: '15px'}}
                        >
                            Supprimer le service
                        </Button>*/}
                    </div>
                </div>
            </div>
        );
    }
}
