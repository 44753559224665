import React from 'react';
import PropTypes from 'prop-types';

class FileInput extends React.Component {
    render() {
        let greenLabel = null;

        if (this.props.greenLabelValid && this.props.greenLabel) {
            greenLabel = (
                <span className="greenLabel">{this.props.greenLabel}</span>
            );
        } else if (this.props.greenLabel) {
            greenLabel = (
                <span className="greenLabel invalid">
                    {this.props.greenLabel}
                </span>
            );
        }

        return (
            <div className="form-group">
                <label
                    htmlFor={this.props.name}
                    style={this.props.labelStyle}
                >
                    {this.props.label}
                    {this.props.children}
                </label>
                <label className="filename">{this.props.fileName}</label>
                <input
                    ref="input"
                    type="file"
                    accept="image/*"
                    name={this.props.name}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    autoComplete="off"
                    placeholder={this.props.placeholder}
                />
                <label htmlFor="upload" onClick={() => this.refs.input.click()}>
                    Upload
                </label>
                {greenLabel}
            </div>
        );
    }
}

FileInput.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.object
};

export default FileInput;
