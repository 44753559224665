import React from 'react';
import PropTypes from 'prop-types';
import { getErrorFromCode } from '../../Constants';

import '../../Assets/css/Ui/Input.css';

class Input extends React.Component {
    constructor() {
        super();

        this.state = {
            focus: false
        };

        this.handleFocus = this.handleFocus.bind(this);
        this.handleFocusOut = this.handleFocusOut.bind(this);
    }

    componentDidMount() {
        this.refs.input.addEventListener('focus', this.handleFocus);
        this.refs.input.addEventListener('focusout', this.handleFocusOut);
    }

    componentWillUnmount() {
        this.refs.input.removeEventListener('focus', this.handleFocus);
        this.refs.input.removeEventListener('focusout', this.handleFocusOut);
    }

    handleFocus() {
        this.setState({ focus: true });
    }

    handleFocusOut() {
        this.setState({ focus: false });
    }

    render() {
        let error = null;
        let greenLabel = null;
        let infoLabel = null;

        if (this.props.errors[this.props.name]) {
            error = (
                <span className="error">
                    {getErrorFromCode(this.props.errors[this.props.name])}
                </span>
            );
        }

        if (this.props.greenLabelValid && this.props.greenLabel) {
            greenLabel = (
                <span className="greenLabel">{this.props.greenLabel}</span>
            );
        } else if (this.props.greenLabel) {
            greenLabel = (
                <span className="greenLabel invalid">
                    {this.props.greenLabel}
                </span>
            );
        }

        if (this.props.infoLabel) {
            infoLabel = (
                <span className="infoLabel">{this.props.infoLabel}</span>
            );
        }

        return (
            <div className="InputComp form-group">
                <label
                    htmlFor={this.props.name}
                    className={
                        (this.props.errors[this.props.name] ? 'error' : '') +
                        (this.state.focus ? ' focus' : '')
                    }
                    style={this.props.labelStyle}
                >
                    {this.props.label}
                    {this.props.children}
                </label>
                <input
                    ref="input"
                    type={this.props.type || 'text'}
                    className={
                        this.props.errors[this.props.name]
                            ? 'form-control error'
                            : 'form-control'
                    }
                    name={this.props.name}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    autoComplete="off"
                    placeholder={this.props.placeholder}
                    disabled={this.props.disabled}
                    onKeyDown={this.props.onKeyDown}
                />
                {error}
                {/*greenLabel*/}
                {/*infoLabel*/}
                {this.props.value === '' && infoLabel ? infoLabel : greenLabel}
            </div>
        );
    }
}

Input.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    error: PropTypes.object
};

export default Input;
