export const endpoint = 'https://back.lesplaisirsfruites.com';
//export const endpoint = 'http://localhost:8000';
const errors = {
    INVALID_EMAIL_FORMAT: 'Format invalide',
    NO_SUCH_EMAIL: "Oh...\nAucun compte n'est lié à ce mail.",
    INVALID_PASSWORD:
        'Oups, vos identifiants ne sont pas valides…\nVérifiez votre mail et votre mot de passe.',
    REQUIRED_FIELD: 'Champ requis',
    EMAIL_IN_USE: "L'email ou le mot de passe n'est pas vailde !",
    PASSWORD_NOT_MATCHING: 'Le mot de passe indiqué ne correspond pas !',
    INVALID_FORMAT: 'Format Invalide',
    USER_NOT_ACTIVATED : 'Votre compte n\'est pas activé.'
};

const freePackages = {
    FREE_INSTALLATION: {
        id: 'FREE_INSTALLATION',
        name: 'Installation',
        description: 'Installation là où vous voulez',
        price: 'Gratuite',
        img: {
            filename: 'free-installation.svg',
            height: 49,
            width: 49,
            alt: 'Free Installation img'
        }
    },
    FREE_PLANTS_MAINTENANCE: {
        id: 'FREE_PLANTS_MAINTENANCE',
        name: 'Entretien',
        description: ' Remplacement de votre plante si nécessaire 🥀',
        price: '20€ HT / mois',
        img: {
            filename: 'free-plants-maintenance.svg',
            height: 49,
            width: 73,
            alt: 'Free Plants Maintenance img'
        }
    },
    FREE_RECYCLE: {
        id: 'FREE_RECYCLE',
        name: 'Enlevement',
        description: 'Tous les 15 jours ou sur demande',
        price: '20€ HT / mois',
        img: {
            filename: 'free-recycle.svg',
            height: 48,
            width: 48,
            alt: 'Free Recycle img'
        }
    },
    FREE_MAINTENANCE: {
        id: 'FREE_MAINTENANCE',
        name: 'Assistance & Entretien',
        description: 'Entretien SAV inclus',
        price: 'Offert',
        img: {
            filename: 'free-maintenance.svg',
            height: 48,
            width: 48,
            alt: 'Free General Maintenance img'
        }
    }
};

export const getFreePackages = packages => {
    return packages.map(element => {
        return freePackages[element.description];
    });
};

export const getAllFreePackages = () => {
    return Object.values(freePackages);
};

export const getErrorFromCode = code => (errors[code] ? errors[code] : code);
