import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import Button from '../../../Ui/Button';
import Checkbox from '../../../Ui/Checkbox';

import { fetchApi } from '../../../../Services/Network';
import axios from "axios";
import { endpoint } from '../../../../Constants';
const getStatusString = status => {
    switch (status) {
        case 'WAITING':
            return 'A valider';
        case 'ACTIVE':
            return 'En cours';
        case 'DISABLED':
            return 'Suspendue';
        case 'ABORTED':
            return 'Résiliée';
        default:
            return '';
    }
};

const getStatusClass = status => {
    switch (status) {
        case 'WAITING':
            return 'orange';
        case 'ACTIVE':
            return 'green';
        case 'DISABLED':
            return 'grey';
        case 'ABORTED':
            return 'red';
        default:
            return '';
    }
};

function containsNumericCharacters(str) {
  // Use a regular expression to check for numeric characters
  var regex = /\d/;
  return regex.test(str);
}

const getFrequency = order => {
    let fqs = ""
    if (order.delivery_frequency === 'weekly') { fqs = "Toutes les semaines"}
    if (order.delivery_frequency === 'fortnightly') { fqs = "Tous les 15 jours" }
    if (order.delivery_frequency === 'monthly') { fqs = "Tous les mois" }
    if (order.delivery_frequency === 'promptly') { fqs = "Une seul fois" }

    return fqs
}


const getDateInfoMapo = order => {
    const dateStr = order.mapo_date;
    let formattedDate = "Visite introuvable";
    if (containsNumericCharacters(dateStr)){
        const [year, month, day] = dateStr.split('-');
        formattedDate = `${day}-${month}-${year}`;
    }


    return formattedDate
}

const getDateInfo = order => {
    const dateStr = order.next_delivery;
    if(dateStr !== null && dateStr !== "" && dateStr !== "null"){
        const [year, month, day] = dateStr.split('-');
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate
    }
    return ""
}

const getPrice = order => {
    let acc = 0;

    if (order.service.type === 'product') {
        // Iterate over planning
        Object.values(order.packages).forEach(day =>
            day.forEach(pack => {
                // Iterate over days
                // Get package
                const packageRef = pack.package;
                /*if (packageRef) {
                    if (pack.amount && pack.amount !== 0){
                        acc += pack.quantity_package * pack.amount;
                    }else{
                        acc += pack.quantity_package * (pack.price || packageRef.price);
                    }
                }*/
                if (packageRef) {
                    acc += pack.quantity_package * pack.amount;
                }
            })
        );
    } else if (order.service.type === 'service') {
        // Iterate over chosen packages
        order.packages[0].forEach(pack => {
            // Get package
            const packageRef = pack.package;

            if (packageRef) {
                // Should always be true
                if (pack.amount && pack.amount !== 0){
                    acc += pack.quantity_package * pack.amount;
                }else{
                    acc += pack.quantity_package * packageRef.price;
                }
            }
        });
    } else if (order.service.type === 'cafe') {
        const packageRef = order.packages[0].package;

        if (packageRef) {
            // Should always be true
            if (packageRef.amount && packageRef.amount !== 0){
                acc += packageRef.quantity_package * packageRef.amount;
            }else{
                acc += packageRef.price_month;
            }
        }
    }

    if (order.delivery_frequency === 'weekly') {
        acc *= 4;
    } else if (order.delivery_frequency === 'fortnightly') {
        acc *= 2;
    }

    return acc;
};

class Orders extends React.Component {
    constructor() {
        super();

        this.state = {
            orders: [],
            availableServices: [],
            filtervalidate: false,
            filtertovalidate: false,
            filtercanceled: false
        };
    }

    componentDidMount() {
        axios.get(`${endpoint}/api/delivery/organisation_orders/` + this.props.organization + "/", {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
        .then(orders => {
            let orders_data = orders.data
            this.setState({
                orders: orders_data
            });
        });
        axios.get(`${endpoint}/api/service/`).then(res => this.setState({ availableServices: res.data }));
    }

    render() {
        return (
            <div className="OrdersSettings">
                <div className="row">
                    <div className="col-md-12">
                        <div className="col-md-4">
                            <div className="checkbox-line">
                                <Checkbox
                                    onChange={() => {
                                        this.setState({
                                            filtervalidate: !this.state.filtervalidate,
                                            filtercanceled: false,
                                            filtertovalidate: false
                                        });
                                    }}
                                    checked={this.state.filtervalidate}
                                />
                                <label>Commande validée</label>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="checkbox-line">
                                <Checkbox
                                    onChange={() => {
                                        this.setState({
                                            filtertovalidate: !this.state.filtertovalidate,
                                            filtercanceled: false,
                                            filtervalidate: false
                                        });
                                    }}
                                    checked={this.state.filtertovalidate}
                                />
                                <label>Commande a valider</label>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="checkbox-line">
                                <Checkbox
                                    onChange={() => {
                                        this.setState({
                                            filtercanceled: !this.state.filtercanceled,
                                            filtertovalidate: false,
                                            filtervalidate: false
                                        });
                                    }}
                                    checked={this.state.filtercanceled}
                                />
                                <label>Commande annulée</label>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                {this.state.orders
                    .filter(c =>
                        this.state.filtercanceled
                            ? c.status === 'ABORTED' ||
                              (c.delivery_frequency === 'promptly' && new Date(c.delivery_date) < new Date())
                            : c
                    )
                    .filter(c => (this.state.filtertovalidate ? c.status === 'WAITING' : c))
                    .filter(c => (this.state.filtervalidate ? c.status === 'ACTIVE' : c))
                    .filter(c => c.status !== 'ABORTED' || this.state.filtercanceled === true)
                    .filter(
                        c =>
                            this.state.filtercanceled === true ||
                            c.delivery_frequency !== 'promptly' ||
                            new Date(c.delivery_date) > new Date()
                    )
                    .map(o => (
                        <div className="">
                            <div className="row order-container" key={o.id}>
                                <div className="col-md-4">
                                    <span className="order-name">{o.service.name}</span>
                                </div>
                                <div className="col-md-8 nopad" style={{ textAlign: 'center' }}>
                                    <Link to={`/orders/${this.props.organization}/${o.id}`} className="order-link">
                                        Modifier
                                    </Link>
                                    <span className="order-price">{getPrice(o)} &euro;</span>
                                    <span className={'order-status ' + getStatusClass(o.status)}>
                                        {o.delivery_frequency === 'promptly' && new Date(o.delivery_date) < new Date()
                                            ? 'Livrée'
                                            : getStatusString(o.status)}
                                    </span>
                                </div>
                                <div className="order-details">
                                    <span className="">Fréquence : {getFrequency(o)}</span><br/>
                                    <span className="">Livraison : {o.delivery_location.name}</span><br/>
                                    <span className="">Adresse : {o.delivery_location.address}</span><br/>
                                    <span className="">Prochaine livraison : {getDateInfo(o)} | Livraison Mapotempo : {getDateInfoMapo(o)}</span><br/>
                                </div>
                            </div>
                        </div>
                    ))}
                <div className="row">
                    <div className="col-md-12 customer-order">
                        <p>Commander pour le client</p>
                        {this.state.availableServices.map(service => {
                            return (
                                <Button
                                    onClick={() => {
                                        this.props.history.push(`/tunnel/${service.id}/${this.props.organization}`);
                                    }}
                                    key={service.id}
                                >
                                    {service.name}
                                </Button>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Orders);
