import React from 'react';

import { connect } from 'react-redux';

import { fetchApi } from '../../../Services/Network';
import { feedUserMeta } from '../../../Redux/Actions';

import Button from '../../Ui/Button';
import SpinnerLoading from '../../Ui/SpinnerLoading';
import Input from '../../Ui/Input';

import addNotification from '../../Ui/Notifications';
import axios from "axios"
import { endpoint } from '../../../Constants';
import Switch from '../../Ui/Switch';

class Billing extends React.Component {
    constructor() {
        super();

        this.state = {
            entity_name: '',
            address: '',
            zip_code: '',
            city: '',
            siret_number: '',
            vat_number: '',
            contact_email_address: '',
            phone: '',
            purchase_order: '',
            initLoading: true,
            loading: false,
            affacturage: false,
            payment_bank: 'bnp',
            blocked_account: false,
            payment_condition: '',
            gc_id: ''
        };

        this.errors = {};

        this.isFormValid = this.isFormValid.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    isFormValid() {
        // Checks that each field is not blank
        const toCheck = {
            entity_name: this.state.entity_name,
            address: this.state.address,
            zip_code: this.state.zip_code,
            city: this.state.city,
            phone: this.state.phone,
            // siret_number: this.state.siret_number,
            // vat_number: this.state.vat_number,
            contact_email_address: this.state.contact_email_address
        };

        return Object.keys(toCheck).every(k => this.state[k].trim().length > 0);

        // You're free to add any test
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();

        const {
            entity_name,
            address,
            zip_code,
            city,
            siret_number,
            vat_number,
            phone,
            purchase_order,
            contact_email_address,
            affacturage,
            payment_bank,
            blocked_account,
            payment_condition,
            gc_id,
        } = this.state;

        const body = {
            entity_name,
            address,
            zip_code,
            city,
            siret_number,
            vat_number,
            phone,
            purchase_order,
            contact_email_address,
            affacturage,
            payment_bank,
            blocked_account,
            payment_condition,
            id: this.props.id,
            gc_id
        };
        this.setState({ loading: true });

        axios.put(`${endpoint}/api/delivery/edit_org/${localStorage.getItem('token')}`, body, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
            .then(() => {
                this.setState({ loading: false });

                const newUser = {
                    ...this.props.user
                };

                newUser.organization = {
                    ...newUser.organization,
                    entity_name,
                    address,
                    zip_code,
                    city,
                    siret_number,
                    vat_number,
                    phone,
                    purchase_order,
                    contact_email_address,
                    affacturage,
                    payment_bank,
                    blocked_account,
                    payment_condition,
                    gc_id,
                };

                if (!this.props.id) {
                    this.props.feedUserMeta(newUser);
                } else {
                    this.props.refreshUserData();
                }

                addNotification('Vos informations ont bien été modifées ! 👍');
            })
            .catch(err => {
                if (err.code === 400) {
                    Object.keys(err.body).forEach(k => {
                        this.errors[k] = err.body[k];
                    });
                }

                this.setState({ loading: false });
            });
    }

    componentDidMount() {
        if (this.props.id) {
            const {
                entity_name,
                address,
                zip_code,
                city,
                siret_number,
                vat_number,
                phone,
                purchase_order,
                contact_email_address,
                affacturage,
                payment_bank,
                blocked_account,
                payment_condition,
                gc_id,
            } = this.props.userOrga;

            this.setState({
                entity_name: entity_name || '',
                address: address || '',
                zip_code: zip_code || '',
                city: city || '',
                siret_number: siret_number || '',
                vat_number: vat_number || '',
                contact_email_address: contact_email_address || '',
                phone: phone || '',
                purchase_order: purchase_order || '',
                initLoading: false,
                affacturage: affacturage || false,
                payment_bank: payment_bank || 'bnp',
                blocked_account: blocked_account || false,
                payment_condition: payment_condition || '',
                gc_id: gc_id || '',
            });
        } else {
            const {
                entity_name,
                address,
                zip_code,
                city,
                siret_number,
                vat_number,
                phone,
                purchase_order,
                contact_email_address,
                affacturage,
                payment_bank,
                blocked_account,
                payment_condition,
                gc_id
            } = this.props.user.organization;

            this.setState({
                entity_name: entity_name || '',
                address: address || '',
                zip_code: zip_code || '',
                city: city || '',
                siret_number: siret_number || '',
                vat_number: vat_number || '',
                phone: phone || '',
                purchase_order: purchase_order || '',
                contact_email_address: contact_email_address || '',
                initLoading: false,
                affacturage: affacturage || '',
                payment_bank: payment_bank || 'bnp',
                blocked_account: blocked_account || '',
                payment_condition: payment_condition || '',
                gc_id: gc_id || ''
            });
        }
    }

    render() {
        const buttonDisabled = !this.isFormValid();

        if (this.state.initLoading) {
            return <SpinnerLoading />;
        }
        if (this.props.user.role == "Admin"){
            return (
            <div className="sidecontent">
                <div className="sideLayoutInput">
                    <form onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Banque</label>
                                    <select
                                        name="payment_bank"
                                        value={this.state.payment_bank}
                                        onChange={this.handleChange}
                                        className={'form-control'}
                                    >
                                        <option key="affacturage" value="affacturage">
                                            Affacturage
                                        </option>
                                        <option key="bnp" value="bnp">
                                            BNP
                                        </option>
                                        <option key="banque_populaire" value="banque_populaire">
                                            Banque Populaire
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <p className="mail-settings" style={{ marginTop: '30px' }}>
                                    Compte bloqué
                                    <span className="switch-settings">
                                        <Switch
                                            handleChange={this.handleChange}
                                            name="blocked_account"
                                            value={this.state.blocked_account}
                                        />
                                    </span>
                                </p>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <Input
                                        type="text"
                                        placeholder="Saisissez le nom de votre société"
                                        label="SOCIETE DE FACTURATION"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.entity_name}
                                        name="entity_name"
                                        errors={this.errors}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <Input
                                        type="text"
                                        placeholder="adresse de facturation"
                                        label="Adresse de facturation"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.address}
                                        name="address"
                                        errors={this.errors}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <Input
                                        type="text"
                                        placeholder="00000"
                                        label="CODE POSTAL"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.zip_code}
                                        name="zip_code"
                                        errors={this.errors}
                                    />
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="form-group">
                                    <Input
                                        type="text"
                                        placeholder="Ville"
                                        label="VILLE"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.city}
                                        name="city"
                                        errors={this.errors}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Input
                                    type="text"
                                    label="Ligne directe"
                                    placeholder="0X XX XX XX XX"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.phone}
                                    name="phone"
                                    errors={this.errors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <Input
                                        type="text"
                                        placeholder="N° 1234"
                                        label="Bon de commande (facultative)"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.purchase_order}
                                        name="purchase_order"
                                        errors={this.errors}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <Input
                                        type="text"
                                        placeholder="XXX XXX XXX XXXXX"
                                        label="N° SIRET"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.siret_number}
                                        name="siret_number"
                                        errors={this.errors}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <Input
                                        type="text"
                                        placeholder="XX XXX XXX XXX XX"
                                        label="N° TVA"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.vat_number}
                                        name="vat_number"
                                        errors={this.errors}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <Input
                                        type="email"
                                        placeholder="Addresse EMail"
                                        label="CONTACT POUR FACTURATION (COMPTABILITÉ)"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.contact_email_address}
                                        name="contact_email_address"
                                        errors={this.errors}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Paiements</label>
                                    <select
                                        name="payment_condition"
                                        value={this.state.payment_condition}
                                        onChange={this.handleChange}
                                        className={'form-control'}
                                    >
                                        <option key="upon_receipt" value="upon_receipt">
                                            Reception
                                        </option>
                                        <option key="30_days" value="30_days">
                                            30 jours
                                        </option>
                                        <option key="45_days" value="45_days">
                                            45 jours
                                        </option>
                                        <option key="60_days" value="60_days">
                                            60 jours
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <Input
                                        type="text"
                                        placeholder="Saisissez l'identifiant du mandat de prélèvement"
                                        label="ID DU MANDAT"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.gc_id}
                                        name="gc_id"
                                        errors={this.errors}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Button
                                    disabled={buttonDisabled}
                                    loading={this.state.loading}
                                >
                                    Enregistrer
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
        }else{
            return (
            <div className="sidecontent">
                <div className="sideLayoutInput">
                    <form onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <Input
                                        type="text"
                                        placeholder="Saisissez le nom de votre société"
                                        label="SOCIETE DE FACTURATION"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.entity_name}
                                        name="entity_name"
                                        errors={this.errors}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <Input
                                        type="text"
                                        placeholder="adresse de facturation"
                                        label="Adresse de facturation"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.address}
                                        name="address"
                                        errors={this.errors}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <Input
                                        type="text"
                                        placeholder="00000"
                                        label="CODE POSTAL"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.zip_code}
                                        name="zip_code"
                                        errors={this.errors}
                                    />
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="form-group">
                                    <Input
                                        type="text"
                                        placeholder="Ville"
                                        label="VILLE"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.city}
                                        name="city"
                                        errors={this.errors}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Input
                                    type="text"
                                    label="Ligne directe"
                                    placeholder="0X XX XX XX XX"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    value={this.state.phone}
                                    name="phone"
                                    errors={this.errors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <Input
                                        type="text"
                                        placeholder="N° 1234"
                                        label="Bon de commande (facultative)"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.purchase_order}
                                        name="purchase_order"
                                        errors={this.errors}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <Input
                                        type="text"
                                        placeholder="XXX XXX XXX XXXXX"
                                        label="N° SIRET"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.siret_number}
                                        name="siret_number"
                                        errors={this.errors}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <Input
                                        type="text"
                                        placeholder="XX XXX XXX XXX XX"
                                        label="N° TVA"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.vat_number}
                                        name="vat_number"
                                        errors={this.errors}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <Input
                                        type="email"
                                        placeholder="Addresse EMail"
                                        label="CONTACT POUR FACTURATION (COMPTABILITÉ)"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.contact_email_address}
                                        name="contact_email_address"
                                        errors={this.errors}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <Input
                                        type="text"
                                        placeholder="Saisissez l'identifiant du mandat de prélèvement"
                                        label="ID DU MANDAT"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        value={this.state.gc_id}
                                        name="gc_id"
                                        errors={this.errors}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Button
                                    disabled={buttonDisabled}
                                    loading={this.state.loading}
                                >
                                    Enregistrer
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
        }

    }
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user
});

const mapDispatchToProps = dispatch => ({
    feedUserMeta: user => dispatch(feedUserMeta(user))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Billing);
