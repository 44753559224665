import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { fetchApi } from '../../Services/Network';

import Button from '../Ui/Button';
import addNotification from '../Ui/Notifications';

export default class EmailConfirmation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            success: false,
            tokenExpired: false
        };

        this.resendValidationEmail = this.resendValidationEmail.bind(this);
    }

    componentDidMount() {
        fetchApi(`/auth/validate/${this.props.match.params.token}`, {
            method: 'PUT'
        })
            .then(() => {
                this.setState({ loading: false, success: true });
            })
            .catch(err => {
                if (err) {
                    if (err.code === 410) {
                        return this.setState({
                            loading: false,
                            tokenExpired: true
                        });
                    }
                }
                return this.setState({ loading: false });
            });
    }

    resendValidationEmail = () => {
        var jwtDecode = require('jwt-decode');
        var decoded = jwtDecode(this.props.match.params.token);

        fetchApi('/auth/validate', {
            method: 'POST',
            body: JSON.stringify({ email: decoded.email })
        })
            .then(() => {
                addNotification(
                    'Un nouveau e-mail vient de vous être envoyé ! 👍'
                );
                this.props.history.push('/login');
            })
            .catch(err => {
                if (err) {
                    if (
                        err.code === 400 &&
                        err.body.error === 'USER_ALREADY_ACTIVATED'
                    ) {
                        addNotification(
                            'Cet utilisateur est déja activé !',
                            'danger'
                        );
                        this.props.history.push('/login');
                    }
                }
            });
    };

    render() {
        if (this.state.loading) {
            return (
                <div className="SubscribeConfirm">
                    <div>
                        <h2>Chargement en cours ...</h2>
                    </div>
                </div>
            );
        } else {
            if (this.state.success) {
                return (
                    <div className="SubscribeConfirm">
                        <div style={{width: '500px', marginLeft: 'auto', marginRight: 'auto'}}>
                            <h2>Félicitation votre compte est activé !</h2>
                            <p>
                                Vous faites partie des offices managers qui vont
                                gagner du temps et faire profiter à ses
                                collaborateurs des meilleurs services Pepp’r !
                            </p>
                            <div style={{margin: '20px 0px'}}>
                                <img
                                    src="https://i.giphy.com/media/11ISwbgCxEzMyY/200w.webp"
                                    height="280"
                                    alt="Well Done img"
                                    style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}}
                                />
                            </div>
                            <p>À tout de suite !</p>
                            <p className="foot">
                                <span style={{ marginRight: '15px' }}>
                                    Prêt à commencer ?
                                </span>
                                <Link to="/">
                                    <Button
                                        style={{ display: 'inline-block' }}
                                        disabled={false}
                                        onClick={() => {}}
                                    >
                                        Aller sur Les Plaisirs Fruites !
                                    </Button>
                                </Link>
                            </p>
                        </div>
                    </div>
                );
            }
            if (this.state.tokenExpired) {
                return (
                    <div className="SubscribeConfirm">
                        <div style={{width: '500px', marginLeft: 'auto', marginRight: 'auto'}}>
                            <h2>Erreur : Le lien d'activation a expiré !</h2>
                            <p style={{ textAlign: 'center' }}>
                                <Button
                                    disabled={false}
                                    onClick={this.resendValidationEmail}
                                >
                                    Renvoyer un mail d'activation
                                </Button>
                            </p>
                            <div style={{margin: '20px 0px'}}>
                                <img
                                    src="https://i.giphy.com/media/9Y5BbDSkSTiY8/200w.webp"
                                    height="280"
                                    alt="Well Done img"
                                    style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}}
                                />
                            </div>
                            <p className="foot">
                                <span
                                    onClick={() =>
                                        this.props.history.push('/register')
                                    }
                                >
                                    Retourner à l’écran d'enregistrement
                                </span>
                            </p>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="SubscribeConfirm">
                        <div style={{width: '500px', marginLeft: 'auto', marginRight: 'auto'}}>
                            <h2>Erreur dans l'activation de votre compte !</h2>
                            <p>
                                Contacter le service client de Les Plaisirs Fruites si le
                                problème persiste.
                            </p>
                            <div style={{margin: '20px 0px'}}>
                                <img
                                    src="https://i.giphy.com/media/9Y5BbDSkSTiY8/200w.webp"
                                    height="280"
                                    alt="Well Done img"
                                    style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}}
                                />
                            </div>
                            <p className="foot">
                                Ce n’est pas le bon mail ?
                                <span
                                    onClick={() =>
                                        this.props.history.push('/register')
                                    }
                                >
                                    Retourner à l’écran précédent
                                </span>
                            </p>
                        </div>
                    </div>
                );
            }
        }
    }
}
