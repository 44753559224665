import React from 'react';
import { connect } from 'react-redux';

/* Components Import */

import Input from '../../Ui/Input';
import Button from '../../Ui/Button';
import TextArea from '../../Ui/TextArea';
import addNotification from '../../Ui/Notifications';

/* Services Import */

import { fetchApi } from '../../../Services/Network';

class Contact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            message: '',
            organization_name: '',
            loading: false
        };

        this.errors = {};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const {
            first_name,
            last_name,
            email,
            phone,
            organization
        } = this.props.user;

        this.setState({
            first_name: first_name || '',
            last_name: last_name || '',
            email: email || '',
            phone: phone || '',
            organization: organization.name || ''
        });
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ loading: true });

        const {
            first_name,
            last_name,
            email,
            phone,
            message,
            organization
        } = this.state;

        fetchApi(`/static/contact/${this.props.user.id}`, {
            method: 'POST',
            body: JSON.stringify({
                first_name,
                last_name,
                email,
                phone,
                message,
                organization
            })
        })
            .then(() => {
                addNotification('Message bien envoyée !', 'success');
                this.setState({ loading: false });
            })
            .catch(err => {
                if (err.code === 400) {
                    Object.keys(err.body).forEach(k => {
                        this.errors[k] = err.body[k];
                    });
                }
                this.setState({ loading: false });
            });
    }

    render() {
        return (
            <div className="sidecontent">
                <form autoComplete="off" onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <Input
                                type="text"
                                placeholder="John"
                                className="form-control"
                                autoComplete="off"
                                name="first_name"
                                value={this.state.first_name}
                                onChange={this.handleChange}
                                errors={this.errors}
                                label="PRENOM"
                            />
                        </div>
                        <div className="col-md-6">
                            <Input
                                type="text"
                                className="form-control"
                                autoComplete="off"
                                name="last_name"
                                value={this.state.last_name}
                                onChange={this.handleChange}
                                errors={this.errors}
                                label="NOM"
                                placeholder="Doe"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Input
                                type="email"
                                className="form-control"
                                autoComplete="off"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                                errors={this.errors}
                                label="EMAIL"
                                placeholder="email@email.fr"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Input
                                type="phone"
                                className="form-control"
                                autoComplete="off"
                                name="phone"
                                value={this.state.phone}
                                onChange={this.handleChange}
                                errors={this.errors}
                                label="LIGNE DIRECTE"
                                placeholder="0600000000"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <TextArea
                                rows="4"
                                type="text"
                                name="message"
                                value={this.state.message}
                                placeholder="Votre message ..."
                                label="MESSAGE"
                                onChange={this.handleChange}
                                errors={this.errors}
                            />
                        </div>
                    </div>
                    <Button loading={this.state.loading}>Envoyer !</Button>
                </form>
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user
});

export default connect(
    mapStateToProps,
    null
)(Contact);
