import React from 'react';

import '../../Assets/css/Ui/Notification.css';

// Singleton design pattern
let notif = null;

const addNotification = (message, type = 'success') => {
    if (!notif) {
        return console.error('No notification manager');
    }

    notif.addNotification(message, type);
};

export default addNotification;

export class NotificationManager extends React.Component {
    constructor() {
        super();

        this.state = {
            currentNotification: null
        };

        notif = this;

        this.queue = [];
        this.counter = 0;
        this.setNext = this.setNext.bind(this);
    }

    setNext() {
        if (this.queue.length > 0) {
            const next = this.queue.pop();
            this.setState({ currentNotification: next });
        } else {
            this.setState({ currentNotification: null });
        }
    }

    addNotification(message, type) {
        const n = (
            <Notification
                key={this.counter++}
                message={message}
                setNext={this.setNext}
                type={type}
            />
        );

        this.queue.push(n);

        if (this.state.currentNotification === null) {
            this.setNext();
        }
    }

    render() {
        if (this.state.currentNotification === null) {
            return null;
        }

        return (
            <div className="NotificationManager">
                {this.state.currentNotification}
            </div>
        );
    }
}

class Notification extends React.Component {
    constructor() {
        super();

        this.state = {
            closing: false
        };

        this.handleClose = this.handleClose.bind(this);

        this.to = null;
        this.toClose = null;
    }

    handleClose() {
        clearTimeout(this.toClose);
        this.setState({ closing: true });
        this.to = setTimeout(this.props.setNext, 750);
    }

    componentDidMount() {
        this.toClose = setTimeout(this.handleClose, 5000);
    }

    componentWillUnmount() {
        clearTimeout(this.to);
    }

    render() {
        return (
            <div
                className={`Notification ${this.props.type} ${
                    this.state.closing ? 'fadeOutDown' : 'fadeInUp'
                }`}
            >
                <p>{this.props.message}</p>
            </div>
        );
    }
}
