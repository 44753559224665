import React from 'react';
import { Link } from 'react-router-dom';
import Pagination from 'react-paginate';

import Button from '../../Ui/Button';
import SpinnerLoading from '../../Ui/SpinnerLoading';
import addNotification from '../../Ui/Notifications';
import { show } from '../../Ui/Modals';

import { fetchApi } from '../../../Services/Network';

import '../../../Assets/css/Pages/adminPage.css';
import axios from "axios"
import { endpoint } from '../../../Constants';
const ClientCard = props => (
    <div className="col-md-4 col-sm-4 col-xs-6">
        <div className="home-card">
            <span className="card-title">{props.id} - {props.name}</span>
            <span className="card-address">
                {props.address ? props.address : 'Profil Utilisateur Incomplet'}
                <br />
                {props.zip_code} {props.city}
            </span>
            <Link to={`/customer/${props.id}`} className="card-edit">
                Editer
            </Link>
            {props.imported ? (
                <button
                    className="card-activate"
                    onClick={() => {
                        show(
                            'Êtes-vous sûrs de vouloir activer ce client ?',
                            'info',
                            _ => props.handleActivate()
                        )
                    }}
                >
                    Activer
                </button>
            ) : null}
        </div>
    </div>
);

class Landing extends React.Component {
    constructor(props) {
        super();
        this.state = {
            searchInput: props.history.location.state ? props.history.location.state.searchInput : '',
            clients: [],
            loading: true,
            currentPage: 1,
            totalPages: 0
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleActivate = this.handleActivate.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value, edited: true });
    }

    componentWillMount() {
        this.unlisten = this.props.history.listen(location => {
          if(location.state){
          this.setState({
              clients: location.state.clients,
              totalPages: location.state.totalPages ,
              searchInput: location.state.searchInput,
              loading: false
          })}
        });
    }
    componentWillUnmount() {
        this.unlisten();
    }

    componentDidMount() {
        fetch(`${endpoint}/api/v1/users/clients?page=1`,{
            headers: {
                'Authorization': `Token ${localStorage.getItem('apikey')}`
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    clients: data.data,
                    totalPages: data.total_pages,
                    loading: false
                })
                if(this.props.history.location.state){
                  this.setState({
                      clients: this.props.history.location.state.clients,
                      totalPages: this.props.history.location.state.totalPages ,
                      searchInput: this.props.history.location.state.searchInput,
                      loading: false
                  })}
            })
    }

    fetchClients = (page, searchInput) => {
        this.setState({ loading: true });
        fetch(`${endpoint}/api/v1/users/clients?page=${page}&q=${searchInput}`,{
            headers: {
                'Authorization': `Token ${localStorage.getItem('apikey')}`
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    clients: data.data,
                    totalPages: data.total_pages,
                    loading: false
                });
            })
            .catch(error => {
                this.setState({ loading: false });
            });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.fetchClients(1, this.state.searchInput);
    }

    handlePageClick(data) {
        const selectedPage = data.selected + 1;
        fetch(`${endpoint}/api/v1/users/clients?page=${selectedPage}`, {
            headers: {
                'Authorization': `Token ${localStorage.getItem('apikey')}`
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    clients: data.data,
                    currentPage: selectedPage
                });
            });
    }

    handleActivate(userId) {
        axios.post(`${endpoint}/api/v1/users/welcome_email/`, {
                clientId: userId
            })
            .then(() => {
                fetch(`${endpoint}/api/v1/users/clients?page=1`, {
                    headers: {
                        'Authorization': `Token ${localStorage.getItem('apikey')}`
                    }
                })
                .then(response => response.json())
                .then(data => {
                    this.setState({
                        clients: data.data,
                        totalPages: data.total_pages,
                        loading: false
                    })
                })
                addNotification(
                    "Compte utilisateur activé",
                );
            })
            .catch(err => {
                console.error(err);
                addNotification(
                    "Erreur lors de l'activation de l'utilisateur",
                    'danger'
                );
            });
    }

    render() {
        if (this.state.loading) {
            return <SpinnerLoading />;
        }
        /*
        <!--<div className="col-md-6 col-md-offset-1" >
                        <form onSubmit={this.handleSubmit} className="searchForm">
                            <input
                                type="text"
                                name="searchInput"
                                className="searchInput"
                                placeholder="Nom, société, …"
                                onChange={this.handleChange}
                                value={this.state.searchInput}
                            />
                            <Button type="submit" disabled={false} className="searchCustomer">
                                Chercher
                            </Button>
                        </form>
                    </div>-->
        */
        return (
            <div className="adminPage">
                <div className="row">
                    <div className="col-md-2">
                        <h1 className="Header">Nos Clients</h1>
                    </div>

                    <div className="col-md-3" style={{ textAlign: 'right', float: "right" }}>
                        <Link to="/accountTunnel">
                            <Button disabled={false} className="newCustomer">
                                Nouveau Client
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="row card-container">
                    {this.state.clients
                        .map(c => (
                            <ClientCard
                                key={c.id}
                                id={c.id}
                                imported={c.imported}
                                {...c.organization}
                                handleActivate={() => this.handleActivate(c.id)}
                            />
                        ))}
                </div>
                <Pagination
                    pageCount={this.state.totalPages}
                    onPageChange={this.handlePageClick}
                    forcePage={this.state.currentPage-1}
                />
            </div>
        );
    }
}

export default Landing;
{/**/}