import { endpoint } from '../Constants';

export const fetchApi = (url, options, noTransform = false) => {
    const headers = {
        'Content-Type': 'application/json'
    };

    return fetch(`${endpoint}${url}`, {
        ...options,
        headers,
        credentials: 'include'
    })
        .then(async res => {
            if (noTransform) {
                return Promise.resolve(res);
            }

            if (res.status === 400) {
                return Promise.reject({
                    code: 400,
                    body: await res.json()
                });
            } else if (res.status > 400) {
                return Promise.reject({ code: res.status });
            }

            if (res.status === 204) {
                return Promise.resolve();
            }

            try {
                return Promise.resolve(await res.json());
            } catch (_) {
                return Promise.resolve(res);
            }
        })
        .catch(err => Promise.reject(err));
};
