/* LIBRARIES IMPORT */

import React from 'react';

/* COMPONENTS IMPORT */

import Delivery from '../../../Pages/MyAccount/Delivery';
import Button from '../../Button';

/* CSS IMPORT */

import '../../../../Assets/css/Ui/Tunnel/General/DeliveryAddressSelection.css';




const DeliveryAddressSelection = props => (
    <div className="DeliveryAddressSelection">
        <p className="catchSentence">Première étape :</p>
        <h2>Où est ce que vous souhaitez être livré ?</h2>
        <p className="catchSentence" style={{marginTop: '8px', marginBottom: '22px'}}>Sélectionnez le site de votre choix ou cliquez sur "+" pour en créér un nouveau</p>
        <Delivery
            selectable={true}
            selected={props.delivery_location}
            onSelect={props.setDeliveryLocation}
            id={props.organization_id}
        />
        <Button
            className="mt-24"
            onClick={props.handleNextPage}
            disabled={props.delivery_location === null}
        >
            Suivant
        </Button>
    </div>
);

export default DeliveryAddressSelection;
