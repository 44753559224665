import React from 'react';
import { Switch, Route, Link, NavLink, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { endpoint } from '../../Constants';
/* CLIENT COMPONENTS */

import Landing from '../Pages/Landing';
import Subscriptions from '../Pages/Subscriptions';
import AccountSettings from '../Pages/MyAccount';
import Invoices from '../Pages/Invoices';
import Help from '../Pages/Help/';
import NotFound from '../Pages/NotFound';

/* BACK-OFFICE COMPONENTS */

import Services from '../Pages/Admin/Services';
import ServicesSettings from '../Pages/Admin/Services/Settings';
import CustomerSettings from '../Pages/Admin/Customers';
import Admin from '../Pages/Admin/Landing';
import Orders from '../Pages/Admin/Orders';
import OrderReview from '../Pages/OrderReview/';
import AdminSettings from '../Pages/Admin/Settings/';
import OrdersExport from '../Pages/Admin/Orders/Export';
import Button from '../Ui/Button';
/* SERVICES IMPORT */

import AuthWrapper from '../../Services/AuthWrapper';

/* STYLE IMPORT */

import '../../Assets/css/Layout/mainLayout.css';

const Footer = () => (
    <div className="Footer">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <span>Pepp’r • Copyright © — 2018</span>
                    <span>Conditions d’utilisation</span>
                    <span>Mentions légales</span>
                    <span>Conditions Générales de Ventes</span>
                    <span>Politique de confidentialité</span>
                </div>
            </div>
        </div>
    </div>
);

class Layout extends React.Component {
    render() {
        return (
            <div className="main-layout">
                {/* NAVBAR */}
                <div className="nav-bar">
                    <Link to="/">
                        <img
                            src="/assets/img/peppr-mail.png"
                            className="logo"
                            alt="Logo Peppr"
                        />
                    </Link>
                    <div className="divider" />
                    <Switch>
                        <Route path="/404">
                            <div className="nav-content">
                                <Link to="/" className="btn-back">
                                    RETOUR À L'ACCUEIL
                                </Link>
                            </div>
                        </Route>
                        <Route path="/">
                            <div className="nav-content">
                                {/*<NavLink exact to="/" activeClassName="active">
                                    MON FIL D'ACTUALITÉ
                                </NavLink>*/}
                                <NavLink
                                    to="/"
                                    activeClassName="active"
                                >
                                    MES ABONNEMENTS
                                </NavLink>
                                <NavLink
                                    to="/invoices"
                                    activeClassName="active"
                                >
                                    MES FACTURES
                                </NavLink>
                                <NavLink to="/help" activeClassName="active">
                                    AIDE &amp; CONTACT
                                </NavLink>
                                <div className="pull-right">
                                    <NavLink
                                        to="/my-account"
                                        activeClassName="active"
                                    >
                                        Paramètres
                                    </NavLink>
                                    <Link to="/logout">
                                        <i className="fa fa-sign-out-alt" />
                                    </Link>
                                </div>
                            </div>
                        </Route>
                    </Switch>
                </div>

                <div className="content">
                    <div className="container">
                        <Switch>
                            {/*Route exact path="/" component={Subscriptions} */}
                            <Route
                                exact
                                path="/"
                                component={Subscriptions}
                            />
                            <Route
                                exact
                                path="/invoices"
                                component={Invoices}
                            />
                            <Route exact path="/help" component={Help} />
                            <Route
                                exact
                                path="/my-account"
                                component={AccountSettings}
                            />
                            <Route
                                exact
                                path="/orders/:id/:oid"
                                component={OrderReview}
                            />
                            <Route exact path="/404" component={NotFound} />
                            <Route render={() => <Redirect to="/404" />} />
                        </Switch>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

class AdminLayout extends React.Component {
    constructor() {
        super();

        this.state = {
            searchInput: '',
            clients: [],
            loading: true,
            currentPage: 1,
            totalPages: 0
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value, edited: true });
    }
    componentWillMount() {
       if(this.props){
            if(this.props.history.location.state){
                this.setState({searchInput: this.props.history.location.state.searchInput})
            }
       }
    }

    fetchClients = (page, searchInput) => {
        this.setState({ loading: true });
        fetch(`${endpoint}/api/v1/users/clients?page=${page}&q=${searchInput}`,{
            headers: {
                'Authorization': `Token ${localStorage.getItem('apikey')}`
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    clients: data.data,
                    searchInput: this.state.searchInput,
                    totalPages: data.total_pages,
                    loading: false
                });
                this.props.history.push({
                 pathname: '/',
                 state: this.state
                });
            })
            .catch(error => {
                this.setState({ loading: false });
            });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.fetchClients(1, this.state.searchInput);

    }
    render() {

        return (
            <div className="main-layout">
                {/* NAVBAR */}

                <div className="nav-bar">
                    <Link to="/">
                        <img
                            src="/assets/img/peppr-mail.png"
                            className="logo"
                            alt="Logo Peppr"
                        />
                    </Link>
                    <div className="col-md-6 col-md-offset-1 center" >
                        <form onSubmit={this.handleSubmit} className="searchForm">
                            <input
                                type="text"
                                name="searchInput"
                                className="searchInput"
                                placeholder="Nom, société, …"
                                onChange={this.handleChange}
                                value={this.state.searchInput}
                            />
                        </form>
                    </div>
                    <div className="divider" />
                    <Switch>
                        <Route path="/404">
                            <div className="nav-content">
                                <Link to="/" className="btn-back">
                                    RETOUR À L'ACCUEIL
                                </Link>
                            </div>
                        </Route>
                        <Route exact path="/orders/:id/:oid">
                            <div className="nav-content">
                                <button onClick={() => this.props.history.goBack()} className="btn-back">
                                    Retour
                                </button>
                            </div>
                        </Route>

                        <Route path="/">
                            <div className="nav-content">
                                <NavLink exact to="/" activeClassName="active">
                                    CLIENTS
                                </NavLink>
                                <NavLink to="/orders" activeClassName="active">
                                    COMMANDES
                                </NavLink>
                                <NavLink
                                    to="/services"
                                    activeClassName="active"
                                >
                                    SERVICES &amp; PRODUITS
                                </NavLink>
                                <div className="pull-right">
                                    <NavLink
                                        to="/settings"
                                        activeClassName="active"
                                    >
                                        Paramètres
                                    </NavLink>
                                    <Link to="/logout">
                                        <i className="fa fa-sign-out-alt" />
                                    </Link>
                                </div>
                            </div>
                        </Route>
                    </Switch>

                </div>

                <div className="content admin">
                    <div className="container">
                        <Switch>
                            <Route exact path="/" component={Admin} />
                            <Route
                                exact
                                path="/customer/:id"
                                component={CustomerSettings}
                            />
                            <Route
                                exact
                                path="/services"
                                component={Services}
                            />
                            <Route
                                exact
                                path="/services/:id"
                                component={ServicesSettings}
                            />
                            <Route
                                exact
                                path="/orders/:id/:oid"
                                component={OrderReview}
                            />
                            <Route exact path="/orders" component={Orders} />
                            <Route
                                exact
                                path="/export"
                                component={OrdersExport}
                            />
                            <Route
                                exact
                                path="/settings"
                                component={AdminSettings}
                            />
                            <Route exact path="/404" component={NotFound} />
                            <Route render={() => <Redirect to="/404" />} />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user
});

export default connect(mapStateToProps)(AuthWrapper(Layout, AdminLayout));