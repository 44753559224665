import React, { Component } from 'react';

import Input from './Input';
import Button from './Button';

const HistoryInput = props => (
    <div className="row">
        <div className="col-md-6">
            <Input
                type="text"
                name="name"
                value={props.name}
                placeholder="Nom du service/produit"
                label="Nom du service/produit"
                errors={{}}
                onChange={() => {}}
                disabled={true}
            />
        </div>
        <div className="col-md-3">
            <Input
                type="text"
                name="quantity"
                value={props.quantity.toString()}
                placeholder="Quantité"
                label="Quantité"
                errors={{}}
                onChange={props.handleChangeItem}
            />
        </div>
        <div className="col-md-3">
            <Input
                type="text"
                name="price"
                value={props.price.toString()}
                placeholder="Prix"
                label="Prix"
                errors={{}}
                onChange={props.handleChangeItem}
            />
        </div>
    </div>
);

class HistoryCardForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            invoice: {
                date: null,
                order: null,
                items: null,
                organization: null
            }
        };
    }

    componentDidMount() {
        this.setState({ invoice: this.props.invoice });
    }

    handleChangeItem(e, index) {
        const value = e.target.value;
        const items = this.state.invoice;
        items.packages[index][e.target.name] = value.replace(/,/g, '.');
        this.setState({ invoice: items });
    }

    render() {
        return (
            <div className="HistoryCardForm">
                <form
                    onSubmit={e => {
                        e.preventDefault();
                        this.props.handleSave(this.state.invoice);
                    }}
                >
                    {this.state.invoice.packages
                        ? this.state.invoice.packages.map((item, index) => (
                              <HistoryInput
                                  key={item.id + 'P'}
                                  {...item}
                                  handleChangeItem={e =>
                                      this.handleChangeItem(e, index)
                                  }
                              />
                          ))
                        : null}
                    <div className="row">
                        <div className="col-md-12">
                            <Button
                                style={{ display: 'inline-block' }}
                                loading={this.props.loading}
                            >
                                Enregistrer
                            </Button>

                            <button
                                className="link red"
                                style={{ marginLeft: '24px' }}
                                type="button"
                                onClick={this.props.handleDelete}
                            >
                                Supprimer
                            </button>

                            <button
                                type="button"
                                className="link"
                                style={{ marginLeft: '24px' }}
                                onClick={this.props.handleCancel}
                            >
                                Annuler
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default HistoryCardForm;
