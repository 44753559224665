import React from 'react';
import '../../../../Assets/css/Pages/accountSettings.css';

import Orders from './Orders';
import Password from './Password';
import DeliveryHistory from './DeliveryHistory';
import Invoices from '../../Invoices';
import General from '../../MyAccount/General';
import Delivery from '../../MyAccount/Delivery';
import { fetchApi } from '../../../../Services/Network';
import Billing from '../../MyAccount/Billing';

import addNotification from '../../../Ui/Notifications';
import { show } from '../../../Ui/Modals';
import SpinnerLoading from '../../../Ui/SpinnerLoading';
import axios from "axios";
import { endpoint } from '../../../../Constants';

const CustomerSideMenu = props => {
    return (
        <div className="sidemenu">
            <button
                onClick={() => props.onClick(2)}
                className={props.activeSetting === 2 ? 'Id active' : 'Id'}
            >
                <span>Infos du Client</span>
            </button>
            <button
                onClick={() => props.onClick(3)}
                className={props.activeSetting === 3 ? 'Truck active' : 'Truck'}
            >
                <span>Sites de livraison</span>
            </button>
            <button
                onClick={() => props.onClick(0)}
                className={props.activeSetting === 0 ? 'Id active' : 'Id'}
            >
                <span>Abonnements</span>
            </button>
            <button
                onClick={() => props.onClick(6)}
                className={
                    props.activeSetting === 6 ? 'History active' : 'History'
                }
            >
                <span>Historique</span>
            </button>
            <button
                onClick={() => props.onClick(4)}
                className={
                    props.activeSetting === 4
                        ? 'Facturation active'
                        : 'Facturation'
                }
            >
                <span>Infos de Facturation</span>
            </button>
            <button
                onClick={() => props.onClick(1)}
                className={
                    props.activeSetting === 1
                        ? 'Facturation active'
                        : 'Facturation'
                }
            >
                <span>Factures</span>
            </button>
            <button
                className="customer-delete"
                onClick={() => props.handleUserDelete()}
            >
                Supprimer le client
            </button>
        </div>
    );
    /*
    <button
                onClick={() => props.onClick(5)}
                className={props.activeSetting === 5 ? 'Lock active' : 'Lock'}
            >
                <span>Mot de passe</span>
            </button>
    */
};

class CustomerSettings extends React.Component {
    constructor() {
        super();

        this.state = {
            activeSetting: 2,
            user: null
        };

        this.getUserData = this.getUserData.bind(this);
        this.handleUserDelete = this.handleUserDelete.bind(this);
    }

    componentDidMount() {
        this.getUserData();
    }

    getUserData() {
        axios.post(`${endpoint}/api/v1/users/clients/`, {client_id: this.props.match.params.id}, {
                    headers: {
                        'Authorization': `Token ${localStorage.getItem('apikey')}`
                    }
                })
        .then(user =>{
            user = user.data
            this.setState({ user })
        }
        );
    }

    handleUserDelete() {
        axios.delete(`${endpoint}/api/v1/users/delete/${this.state.user.id}/`, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
        .then(() => {
                addNotification("L'utilisateur a bien été supprimé");
                this.props.history.replace('/');
        })
        .catch(err => {
                if (err.status === 409) {
                    addNotification(
                        "L'utilisateur possède un/des abonnements actifs",
                        'danger'
                    );
                }
        });
    }

    render() {
        if (this.state.user === null) {
            return <SpinnerLoading />;
        }

        return (
            <div className="AccountSettings">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="Header">
                            {this.state.user.organization.id} - {this.state.user.organization.name}
                        </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <CustomerSideMenu
                            onClick={index => {
                                this.setState({ activeSetting: index });
                            }}
                            handleUserDelete={() => {
                                setTimeout(() =>
                                    show(
                                        'Voulez vous vraiment supprimer ce compte Les Plaisirs Fruites ?',
                                        'info',
                                        () => {
                                            this.handleUserDelete();
                                        },
                                        () => {
                                            console.log('close');
                                        }
                                    )
                                );
                            }}
                            activeSetting={this.state.activeSetting}
                        />
                    </div>
                    <div className="col-md-8">
                        {(() => {
                            if (this.state.activeSetting === 0) {
                                return (
                                    <Orders
                                        organization={
                                            this.state.user.organization.id
                                        }
                                    />
                                );
                            }
                            if (this.state.activeSetting === 1) {
                                return (
                                    <Invoices
                                        id={this.state.user.organization.id}
                                        small={true}
                                    />
                                );
                            }
                            if (this.state.activeSetting === 2) {
                                return (
                                    <General
                                        id={this.props.match.params.id}
                                        admin={true}
                                    />
                                );
                            }
                            if (this.state.activeSetting === 3) {
                                return (
                                    <Delivery
                                        id={this.state.user.organization.id}
                                    />
                                );
                            }
                            if (this.state.activeSetting === 4) {
                                return (
                                    <Billing
                                        userOrga={this.state.user.organization}
                                        id={this.state.user.organization.id}
                                        refreshUserData={this.getUserData}
                                    />
                                );
                            }
                            if (this.state.activeSetting === 5) {
                                return <Password user={this.state.user} />;
                            }
                            if (this.state.activeSetting === 6) {
                                return (
                                    <DeliveryHistory
                                        id={this.state.user.organization.id}
                                        editedAllowed={true}
                                    />
                                );
                            }
                        })()}
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomerSettings;
