import React, { Component } from 'react';

/* COMPONENTS IMPORT */

import Button from '../../Button';
import ServiceBox from '../../ServiceBox';
import { show } from '../../Modals';

/* CSS IMPORT */

import '../../../../Assets/css/Ui/Tunnel/Service/ServiceSelection.css';

export default class ServiceSelection extends Component {
    getPrice() {
        let price = 0;
        for (let index = 0; index < this.props.packages.length; index++) {
            price +=
                this.props.packages[index].package.price *
                this.props.packages[index].quantity;
        }
        return price;
    }

    render() {
        let headerTitle = "Qu'est ce qui vous ferait plaisir ?";

        if (this.props.serviceName === 'Recyclage ♻️') {
            headerTitle = 'Que souhaitez vous trier ?';
        }

        return (
            <div className="ServiceSelection">
                <p className="catchSentence">C'est parti !</p>
                <h2>{headerTitle}</h2>

                <div className="row">
                    {(() => {
                        return this.props.servicePackages.map(
                            (element, index) => {
                                return (
                                    <div
                                        className="col-md-2 service-container"
                                        key={index}
                                    >
                                        <ServiceBox
                                            packages={this.props.packages}
                                            servicePackage={element}
                                            handleQuantityChange={
                                                this.props.handleQuantityChange
                                            }
                                            getQuantityLocationById={
                                                this.props
                                                    .getQuantityLocationById
                                            }
                                        />
                                    </div>
                                );
                            }
                        );
                    })()}
                </div>

                <div className="row" style={{ marginTop: '40px' }}>
                    <div className="col-md-3">
                        <Button
                            onClick={this.props.handleNextPage}
                            disabled={this.props.packages.length === 0}
                        >
                            Etape suivante !
                        </Button>
                    </div>
                    <div className="col-md-9">
                        <p className="price">
                            Total - {20  + this.getPrice()} € HT / mois
                        </p>
                        <span className="sub-price">
                            <button
                                onClick={() =>
                                    show(
                                        'Ce tarif est composé de deux éléments: un forfait entretiens à 20€ HT / mois qui comprends toutes les prestations d\'entretien et de relève relative à ce service auquel s\'ajoute le prix mensuel des produits que vous avez sélectionné',
                                        'info',
                                        () => {
                                            console.log('close');
                                        },
                                        () => {
                                            console.log('close');
                                        }
                                    )
                                }
                            >
                                Pourquoi ?
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}
