import React, { Component } from 'react';

import { fetchApi } from '../../../../Services/Network';

import Input from '../../../Ui/Input';
import TextArea from '../../../Ui/TextArea';
import Button from '../../../Ui/Button';
import TimeInput from '../../../Ui/TimeInput';
import StandaloneMapsSearchBox from '../../../Ui/StandaloneMapsSearchBox';
import axios from "axios";
import { endpoint } from '../../../../Constants';
export default class DeliveryInformations extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      name: '',
      address: '',
      zip_code: '',
      city: '',
      delivery_instructions: '',
      first_opening_hours: '00:30',
      first_closing_hours: '23:30',
      second_opening_hours: '',
      second_closing_hours: '',
      workforce: '',
      latitude: 0,
      longitude: 0,
      first_name: '',
      last_name: '',
      telephone: '',
      emails: [],
      delivery_id: null
    };

    this.errors = {};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ loading: true });

    this.errors = {};

    const {
      name,
      address,
      zip_code,
      city,
      delivery_instructions,
      first_opening_hours,
      first_closing_hours,
      second_opening_hours,
      second_closing_hours,
      workforce,
      latitude,
      longitude,
      first_name,
      last_name,
      telephone,
      emails
    } = this.state;

    let org = this.props.user.organization
    const body = {
      name,
      organisation: org.id,
      address,
      zip_code,
      city,
      delivery_instructions,
      workforce,
      first_opening_hours,
      first_closing_hours,
      second_opening_hours,
      second_closing_hours,
      latitude,
      longitude,
      first_name,
      last_name,
      telephone,
      emails
    };

    this.setState({ loading: true });
    axios.post(`${endpoint}/api/delivery/edit_location/${localStorage.getItem('token')}`, body, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
      .then(res => {
        const newUser = {
          ...this.props.user
        };
        newUser.delivery_locations.push(res);

        if (this.props.adminMode) {
          this.props.setNewUser(newUser);
        } else {
          this.props.feedUserMeta(newUser);
        }

        this.setState({ loading: false });

        this.props.handleNextPage();
      })
      .catch(err => {
        if (err.code === 400) {
          Object.keys(err.body).forEach(k => {
            this.errors[k] = err.body[k];
          });
        }

        this.setState({ loading: false });
      });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  componentWillMount() {
    /**
     * If DeliveryInformations already exists then next step
     */
    /* if (this.props.user.organization.delivery_locations.length > 0) {
            this.props.handleNextPage();
        }*/
    let emails = this.props.user.email.split('/')
    if (this.props.user.delivery_locations.length > 0) {
      const {
        name,
        address,
        zip_code,
        city,
        delivery_instructions,
        first_opening_hours,
        first_closing_hours,
        second_opening_hours,
        second_closing_hours,
        workforce,
        latitude,
        longitude,
        position,
        first_name,
        last_name,
        telephone,
        emails,
        id
      } = this.props.user.delivery_locations[0];

      this.setState({
        name,
        address,
        zip_code,
        city,
        delivery_instructions,
        first_opening_hours,
        first_closing_hours,
        second_opening_hours,
        second_closing_hours,
        first_name,
        last_name,
        telephone,
        latitude,
        longitude,
        workforce,
        emails,
        ...position,
        delivery_id: id
      });
    }else {
        this.setState({
        first_name: this.props.user.first_name,
        last_name: this.props.user.last_name,
        telephone: this.props.user.phone,
        emails: emails
      });
    }
  }

  render() {
    return (
      <div className="accountTunnel">
        <p className="catchSentence">Parfait !</p>
        <h2>Où est-ce que nous vous livrons ?</h2>
        <p>
          Renseigner l’adresse principale de votre société.
          <br /> Vous pourrez par la suite ajouter plusieurs sites de livraison.
        </p>
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-md-12">
              <Input
                type="text"
                name="name"
                value={this.state.name}
                placeholder="Nom du site"
                label="NOM DU SITE DE LIVRAISON"
                errors={this.errors}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12" style={{ marginBottom: '32px' }}>
              <label htmlFor="address">Adresse du site</label>
              <StandaloneMapsSearchBox
                initialValue={this.state.address}
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD7nj37JnfxUh66raYi2EHtqXYFNliLyH4&v=3.exp&libraries=geometry,drawing,places&region=FR"
                loadingElement={
                  <div
                    style={{
                      height: '100%'
                    }}
                  />
                }
                autoComplete="off"
                onPlacesChanged={({ address, city, zip_code, latitude, longitude }) => {
                  this.setState({
                    address,
                    city,
                    zip_code,
                    latitude,
                    longitude
                  });
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <Input
                type="text"
                name="zip_code"
                value={this.state.zip_code}
                placeholder="Code Postal"
                label="Code Postal"
                errors={this.errors}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-8">
              <Input
                type="text"
                name="city"
                value={this.state.city}
                placeholder="Ville"
                label="Ville"
                errors={this.errors}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <Input
                type="text"
                name="first_name"
                value={this.state.first_name}
                placeholder="Prénom"
                label="Prénom"
                errors={this.errors}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-8">
              <Input
                type="text"
                name="last_name"
                value={this.state.last_name}
                placeholder="Nom"
                label="Nom"
                errors={this.errors}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Input
                type="text"
                name="telephone"
                value={this.state.telephone}
                placeholder="Téléphone"
                label="Téléphone"
                errors={this.errors}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Input
                type="text"
                name="emails"
                value={this.state.emails}
                placeholder="Email"
                label="Email"
                errors={this.errors}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <TextArea
                type="text"
                name="delivery_instructions"
                value={this.state.delivery_instructions}
                placeholder="Etage, digicode, bâtiment, ..."
                label="INSTRUCTIONS"
                errors={this.errors}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <TimeInput
            label="HORAIRES D'OUVERTURE"
            label2="AUTRES (OPTIONNELLES)"
            errors={this.errors}
            onChange={this.handleChange}
            name1="first_opening_hours"
            value1={this.state.first_opening_hours}
            name2="first_closing_hours"
            value2={this.state.first_closing_hours}
            name3="second_opening_hours"
            value3={this.state.second_opening_hours}
            name4="second_closing_hours"
            value4={this.state.second_closing_hours}
          />
          <div className="row">
            <div className="col-md-12">
              <Input
                type="text"
                name="workforce"
                value={this.state.workforce.toString()}
                placeholder="Nombre de personnes"
                label="EFFECTIF DU SITE"
                errors={this.errors}
                onChange={this.handleChange}
                infoLabel="Utilisé pour personnaliser les commandes"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Button
                style={{ display: 'inline-block' }}
                loading={this.state.loading}
              >
                Suivant
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
