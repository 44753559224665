import React from 'react';

import '../../../Assets/css/Pages/helpPage.css';

import General from './General';
import Services from './Services';
import Facturation from './Facturation';
import Contact from './Contact';

const HelpSideMenu = props => {
    return (
        <div className="sidemenu">
            <button
                onClick={() => props.onClick(0)}
                className={props.activeHelper === 0 ? 'Ring active' : 'Ring'}
            >
                <span>Question générales</span>
                <span className="tag-line">Qui ? Quoi ? Quand ?</span>
            </button>
            <button
                onClick={() => props.onClick(1)}
                className={props.activeHelper === 1 ? 'Service active' : 'Service'}
            >
                <span>Services</span>
                <span className="tag-line">Pourquoi ils sont si bien ...</span>
            </button>
            <button
                onClick={() => props.onClick(2)}
                className={
                    props.activeHelper === 2
                        ? 'Facturation active'
                        : 'Facturation'
                }
            >
                <span>Facturation</span>
                <span className="tag-line">Les sous c'est important</span>
            </button>
            <button
                onClick={() => props.onClick(3)}
                className={props.activeHelper === 3 ? 'Message active' : 'Message'}
            >
                <span>Une autre question ?</span>
                <span className="tag-line">Contactez-nous !</span>
            </button>
        </div>
    );
};

export default class Help extends React.Component {
    constructor() {
        super();

        this.state = {
            activeHelper: 0
        };
    }

    render() {
        return (
            <div className="helpPage">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="Header">Aide &amp; Contact</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <HelpSideMenu
                            onClick={index => {
                                this.setState({ activeHelper: index });
                            }}
                            activeHelper={this.state.activeHelper}
                        />
                    </div>
                    <div className="col-md-8">
                        {(() => {
                            if (this.state.activeHelper === 0) {
                                return <General />;
                            }
                            if (this.state.activeHelper === 1) {
                                return <Services />;
                            }
                            if (this.state.activeHelper === 2) {
                                return <Facturation />;
                            }
                            if (this.state.activeHelper === 3) {
                                return <Contact />;
                            }
                        })()}
                    </div>
                </div>
            </div>
        );
    }
}
