import React, { Component } from 'react';

/* COMPONENTS IMPORT */

import Button from '../../Button';
import { show } from '../../Modals';
import ServiceBox from '../../ServiceBox';
import DeliveryLocation from '../../DeliveryLocation';
import { FreeServiceBox } from '../../FreeServiceBox';

/* SERVICES IMPORT */

import { fetchApi } from '../../../../Services/Network';
import { getFreePackages } from '../../../../Constants';

/* CSS IMPORT */

import '../../../../Assets/css/Ui/Tunnel/Service/ServiceOrderReviewPage.css';
import axios from "axios"
import { endpoint } from '../../../../Constants';

export default class ServiceOrderReviewPage extends Component {
    constructor() {
        super();
        this.state = {
            location: null
        };
    }

    getPrice() {
        let price = 0;
        for (let index = 0; index < this.props.packages.length; index++) {
            price +=
                this.props.packages[index].package.price *
                this.props.packages[index].quantity;
        }
        return price;
    }
    componentDidMount() {
        axios.get(`${endpoint}/api/delivery/locations_org/${this.props.organization_id}/${localStorage.getItem('token')}`, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}})
            .then(res => {
                this.setState({ location: res.data[0] });
            })
            .catch(err => console.log(err));
    }



    render() {
        return (
            <div className="ServiceOrderReviewPage">
                <p className="catchSentence">Parfait !</p>
                <h2>C'est tout bon ?</h2>

                <div className="row" style={{ marginTop: '30px' }}>
                    <div className="col-md-8">
                        {!this.state.location || (
                            <DeliveryLocation
                                selected={this.props.delivery_location.id}
                                editable={false}
                                {...this.state.location}
                            />
                        )}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 servicebox-slider">
                        {(() => {
                            return this.props.packages.map((element, index) => {
                                return (
                                    <ServiceBox
                                        key={index}
                                        packages={this.props.packages}
                                        servicePackage={element.package}
                                        handleQuantityChange={
                                            this.props.handleQuantityChange
                                        }
                                        getQuantityLocationById={
                                            this.props.getQuantityLocationById
                                        }
                                    />
                                );
                            });
                        })()}
                        {(() => {
                            return getFreePackages(
                                this.props.free_packages
                            ).map((element, index) => (
                                <FreeServiceBox
                                    key={index}
                                    freeService={element}
                                    active={true}
                                />
                            ));
                        })()}
                    </div>
                </div>

                <div className="row" style={{ marginTop: '30px' }}>
                    <div
                        className="col-md-6"
                        style={{ paddingLeft: '8px', paddingRight: '8px' }}
                    >
                        <Button
                            onClick={this.props.handleSubmit}
                            disabled={this.props.packages.length === 0}
                            style={{ display: 'inline-block' }}
                        >
                            Valider ma commande
                        </Button>
                        {this.props.estimateLink ? (
                            <a
                                href={this.props.estimateLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                download="devis.pdf"
                            >
                                <Button onClick={() => {}} className="bordered">
                                    Télécharger le devis
                                </Button>
                            </a>
                        ) : (
                            <Button
                                onClick={this.props.handleCreateEstimate}
                                disabled={this.props.packages.length === 0}
                                loading={this.props.loading}
                                className="bordered"
                            >
                                Recevoir mon devis par mail
                            </Button>
                        )}
                    </div>
                    <div
                        className="col-md-6"
                        style={{ paddingLeft: '8px', paddingRight: '8px' }}
                    >
                        <p className="price">
                            Total - {20 + this.getPrice()} € HT / mois
                        </p>
                        <span className="sub-price">
                            <button
                                onClick={() =>
                                    show(
                                        'Ce tarif est composé de deux éléments: un forfait entretiens à 20€ HT / mois qui comprends toutes les prestations d\'entretien et de relève relative à ce service auquel s\'ajoute le prix mensuel des produits que vous avez sélectionné',
                                        'info',
                                        () => {
                                            console.log('close');
                                        },
                                        () => {
                                            console.log('close');
                                        }
                                    )
                                }
                            >
                                Pourquoi ?
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}
