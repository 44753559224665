import React from 'react';

import '../../Assets/css/Ui/Button.css';

const Button = props => {
    let content = props.children;
    let style = '';

    if (props.loading) {
        content = <div className="loader" />;
        style += ' loading';
    }

    if (props.white) {
        style += ' white';
    }

    return (
        <div
            className={`Button ${style} ${
                props.className ? props.className : ''
            }`}
            style={props.style}
        >
            <button disabled={props.disabled} className={props.buttonType} onClick={props.onClick}>
                {content}
            </button>
        </div>
    );
};

export default Button;
