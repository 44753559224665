import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../../Services/Network';

import { feedUserMeta } from '../../../Redux/Actions';

import Switch from '../../Ui/Switch';
import Button from '../../Ui/Button';
import addNotification from '../../Ui/Notifications';
import { show } from '../../Ui/Modals';
import axios from "axios";
import { endpoint } from '../../../Constants';
class Settings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            allowNewsletter: null,
            allowNotification: null,
            edited: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleUserDelete = this.handleUserDelete.bind(this);
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value, edited: true });
    }

    handleUserDelete() {
        fetchApi(`/accounts/${this.props.user.id}`, {
            method: 'DELETE'
        })
            .then(() => {
                addNotification('Votre compte a bien été supprimé');
                this.props.history.replace('/logout');
            })
            .catch(err => {
                if (err.code === 409) {
                    addNotification(
                        'Vous possèdez un/des abonnements actifs',
                        'danger'
                    );
                }
            });
    }

    handleSubmit() {
        const { allowNewsletter, allowNotification } = this.state;
        axios.put(`${endpoint}/api/v1/users/edit/${localStorage.getItem('token')}`, {
                allowNewsletter,
                allowNotification
            }, {headers:{'Authorization': `Token ${localStorage.getItem('apikey')}`}}).then(() => {
            const newUser = {
                ...this.props.user,
                allowNewsletter,
                allowNotification
            };

            this.props.feedUserMeta(newUser);

            addNotification('Vos informations ont bien été modifiées ! 👍');
        });
    }

    componentDidMount() {
        const { allowNewsletter, allowNotification } = this.props.user;

        this.setState({
            allowNewsletter,
            allowNotification
        });
    }

    render() {
        return (
            <div>
                <div className="sidecontent" style={{ marginBottom: '16px' }}>
                    <div className="sideLayoutInput">
                        <div className="row">
                            <div className="col-md-12">
                                <p className="title-settings">
                                    PREFERENCES DE MAILING
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className="mail-settings">
                                    Recevoir les newsletter Les Plaisirs Fruites{' '}
                                    <span className="switch-settings">
                                        <Switch
                                            handleChange={this.handleChange}
                                            name="allowNewsletter"
                                            value={this.state.allowNewsletter}
                                        />
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className="mail-settings">
                                    Recevoir les notifications par mail{' '}
                                    <span className="switch-settings">
                                        <Switch
                                            handleChange={this.handleChange}
                                            name="allowNotification"
                                            value={this.state.allowNotification}
                                        />
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="row" style={{ paddingTop: '38px' }}>
                            <div className="col-md-12">
                                <Button
                                    disabled={!this.state.edited}
                                    onClick={() => this.handleSubmit()}
                                >
                                    Enregistrer
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sidecontent" style={{ marginBottom: '16px' }}>
                    <div className="sideLayoutInput">
                        <div className="row">
                            <div className="col-md-12">
                                <p className="title-settings">
                                    MENTIONS &amp; CONDITIONS LEGALES
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <button className="settings-link">
                                    Mentions Legales{' '}
                                    <img
                                        className="logo"
                                        src="/assets/img/dropdown.svg"
                                        alt="Peppr Dropdown"
                                    />
                                </button>
                                <button className="settings-link">
                                    Conditions d'Utilisations{' '}
                                    <img
                                        className="logo"
                                        src="/assets/img/dropdown.svg"
                                        alt="Peppr Dropdown"
                                    />
                                </button>
                                <button className="settings-link">
                                    Conditions Générales de Vente{' '}
                                    <img
                                        className="logo"
                                        src="/assets/img/dropdown.svg"
                                        alt="Peppr Dropdown"
                                    />
                                </button>
                                <button className="settings-link">
                                    Politique de confidentialité{' '}
                                    <img
                                        className="logo"
                                        src="/assets/img/dropdown.svg"
                                        alt="Peppr Dropdown"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sidecontent">
                    <button
                        className="settings-deleteAccount"
                        onClick={() => {
                            setTimeout(() =>
                                show(
                                    'Voulez vous vraiment supprimer votre compte Les Plaisirs Fruites ?',
                                    'info',
                                    () => {
                                        this.handleUserDelete();
                                    },
                                    () => {
                                        console.log('close');
                                    }
                                )
                            );
                        }}
                    >
                        Supprimer mon compte Les Plaisirs Fruites
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user
});

const mapDispatchToProps = dispatch => ({
    feedUserMeta: user => dispatch(feedUserMeta(user))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Settings));
